import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import * as angular from 'angular';
import { DataCardSettingsComponent } from './data-card/data-card-settings/data-card-settings.component';
import { WeatherTableComponent } from './weather-table/weather-table.component';
import { DateService } from './weather-table/date.service';

export const Ng1ModuleDefinition = angular
  .module('ng1module.module-definition', [])
  .directive('appDataCardSettings', downgradeComponent({ component: DataCardSettingsComponent }) as angular.IDirectiveFactory)
  .directive('appWeatherTable', downgradeComponent({ component: WeatherTableComponent }) as angular.IDirectiveFactory)
  .factory('dateService', downgradeInjectable(DateService) as any)
  .name;
