import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { DataListCommandFilterType } from './../components/data-list/data-list-command';

@Pipe({
  name: 'dataListFilterPipe',
  standalone: true,
})
export class DataListFilterPipe implements PipeTransform {
  /**
   * Tranfrorm filters input to display in summary
   *
   * @param {(string | string[])} value Can be:
   *  string (basic input | single selection | date input) or
   *  string[] (multiple select | range)
   * @param {DataListCommandFilterType} filter
   * @returns {string}
   */
  transform(value: string | string[], filter: DataListCommandFilterType): string {
    if (typeof value === 'string') {
      if (filter.type === 'date') return moment(value).format('ll');
      if (filter.values) return filter.values.find(f => f.key === value).value;
      return value;
    }
    if (typeof value === 'number') {
      return filter.values.find(f => f.key === value)?.value ?? value;
    } else if (filter.values) {
      return value.map(val => filter.values.find(f => f.key === val).value).join(', ');
    } else {
      return `${moment(value[0]).format('ll')} - ${moment(value[1]).format('ll')}`;
    }
  }
}
