import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { ChangeDetectorRef } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { BehaviorSubject, Observable, Subject, Subscription, catchError, filter, of, switchMap, takeUntil, tap } from "rxjs";
import { WeatherFieldModel } from "../../../models/module/fields/main";
import { NotificationService } from "../../../shared/services/notification.service";
import { DateService } from "./date.service";
import { WeatherTableModel } from "./models/weather-table.model";
import { WeatherTableService } from "./weather-table.service";

export class TableDataSource implements DataSource<WeatherFieldModel> {

  protected weatherTimeFields$ = new BehaviorSubject<WeatherFieldModel[]>([]);

  destroy$: Subject<boolean> = new Subject<boolean>();

  loadData$: Observable<WeatherFieldModel[]>

  fieldDataInitialization: Subscription;

  table: WeatherTableModel = new WeatherTableModel();

  constructor(
    private dateService: DateService,
    private weatherService: WeatherTableService,
    private fielddata: { _fieldProperty: number, entityInstanceId: number, actionInstanceId: number, _editMode: boolean, _value: any },
    private notification: NotificationService,
    private cdRef: ChangeDetectorRef) {

    this.dateService.dateChanged$
      .pipe(
        takeUntil(this.destroy$),
        tap(res => this.table.associatedDate = res.value),
        filter(res => this.table.associatedDateFieldId == res.fieldId),
        filter(_ => this.data.length > 0),
        switchMap(res => this.weatherService.getAllWeatherDataIndependent(
          this.table.associatedDate,
          this.data.map(x => x.time),
          res.contractId)
        ),
        tap(res => this.weatherTimeFields$.next(res)),
        tap(_ => this.cdRef.detectChanges()),
      ).subscribe();
  }

  loadData() {
    this.weatherService.getTimeFieldInstances(
      this.fielddata._fieldProperty,
      this.fielddata.entityInstanceId,
      this.fielddata.actionInstanceId,
      null)
      .pipe(
        filter(result => result.length > 0),
        catchError((err) => {
          this.notification.error(err);
          return of([]);
        })
      )
      .subscribe(res => {
        this.weatherTimeFields$.next(res)
      });

    this.fieldDataInitialization = this.weatherTimeFields$
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(_ => this.initializeFieldDataValue())

    if (this.fielddata._editMode) {
      this.weatherService.getWeatherTableAssociatedDateFieldId(this.fielddata._fieldProperty)
        .pipe(
          catchError((err) => {
            this.notification.error(err);
            return of();
          })
        )
        .subscribe(weatherDateFieldId => this.table.associatedDateFieldId = weatherDateFieldId)

      this.weatherService.getTableTimeFieldId(this.fielddata._fieldProperty, this.fielddata.entityInstanceId)
        .pipe(
          catchError((err) => {
            this.notification.error(err);
            return of();
          })
        )
        .subscribe(timeFieldId => this.table.timeFieldId = timeFieldId);
    }
    if (this.fielddata.entityInstanceId !== 0 && this.fielddata._editMode) {
      this.weatherService.getWeatherTableAssociatedDate(this.fielddata._fieldProperty, this.fielddata.entityInstanceId)
        .pipe(
          catchError((err) => {
            this.notification.error(err);
            return of();
          })
        )
        .subscribe(res => {
          this.table.associatedDate = res.date;
        })
    }
  }

  addRow(time: any, associatedDate: Date, contractId): Observable<WeatherFieldModel> {
    return this.weatherService.getContractWeatherDataByDateAndTime(associatedDate, time, contractId)
      .pipe(
        tap(res => {
          const updatedData = this.data.concat({ ...res, fieldId: this.table.timeFieldId })
          this.weatherTimeFields$.next(updatedData);
        })
      )
  }

  //we are using this method because the old fielddata object requires everytime to contain the exact rows we have in our table
  initializeFieldDataValue() {
    this.fielddata._value = this.data.map(x => {
      const obj = {};
      const [hours, minutes, seconds] = x.time.split(":").map(Number);

      let newDate;
      if (this.associatedDate !== null && this.associatedDate !== undefined) {
        newDate = new Date(this.associatedDate);
      } else {
        newDate = new Date();
      }

      let date = new Date(
        newDate.getFullYear(),
        newDate.getMonth(),
        newDate.getDate(),
        hours,
        minutes
      );
      const localeDate = date.toLocaleString();
      obj[this.table.timeFieldId] = localeDate;
      return obj;
    })
  }

  sortData(sort: Sort) {
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      return;
    }

    const sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'time':
          return this.compare(a.time.toLocaleString(), b.time.toLocaleString(), isAsc);
        case 'temperature':
          return this.compare(a.temperature, b.temperature, isAsc);
        case 'windSpeed':
          return this.compare(a.windSpeed, b.windSpeed, isAsc);
        case 'phrase':
          return this.compare(a.phrase, b.phrase, isAsc);
        case 'humidity':
          return this.compare(a.humidity, b.humidity, isAsc);
        default:
          return 0;
      }
    });

    this.weatherTimeFields$.next(sortedData);
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  get data() {
    return this.weatherTimeFields$.getValue();
  }

  get associatedDate() {
    return this.table.associatedDate;
  }

  removeRow(value: WeatherFieldModel) {
    const updatedData = this.weatherTimeFields$.getValue().filter(x => x !== value);
    this.weatherTimeFields$.next(updatedData);
  }

  destroy() {
    this.weatherTimeFields$.complete();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  connect(collectionViewer: CollectionViewer): Observable<Array<WeatherFieldModel>> {
    return this.weatherTimeFields$;
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.weatherTimeFields$.complete();
  }
}