import { downgradeComponent, downgradeInjectable } from "@angular/upgrade/static";
import { MsalRedirectComponent } from "@azure/msal-angular";
import * as angular from "angular";
import { GridsterComponent, GridsterItemComponent } from "angular-gridster2";
import { ItvNumberPipe, ItvNumberWithFormattingsPipe } from "../shared/pipes/itv-number.pipe";
import { MsalAuthService } from "../shared/services/auth.service";
import { FooterComponent } from "./footer/footer.component";
import { LayoutComponent } from "./layout/layout.component";
import { ItvDatePipe } from "../shared/pipes/itv-date.pipe";

export const Ng1ModuleSystem = angular
  .module("ng1module.system", [])
  .directive("appLayout", downgradeComponent({ component: LayoutComponent }) as angular.IDirectiveFactory)
  .directive("appFooter", downgradeComponent({ component: FooterComponent }) as angular.IDirectiveFactory)
  .directive("gridster", downgradeComponent({ component: GridsterComponent }) as angular.IDirectiveFactory)
  .directive("gridsterItem", downgradeComponent({ component: GridsterItemComponent }) as angular.IDirectiveFactory)
  .directive("appRedirect", downgradeComponent({ component: MsalRedirectComponent }) as angular.IDirectiveFactory)
  .factory('msalAuthService', downgradeInjectable(MsalAuthService) as any)
  .factory('itvNumber', downgradeInjectable(ItvNumberPipe) as any)
  .factory('itvNumberWithFormattings', downgradeInjectable(ItvNumberWithFormattingsPipe) as any)
  .factory('itvDate', downgradeInjectable(ItvDatePipe) as any)
  .name;
