import { cipo } from 'cipo';

cipo.controller('modulesController',
    function ($scope, $state, rememberManagerService, $http, ACTIONS, URI, Message, ModuleN, Manager, Menu, userService, FileSaver, Blob, $mdDialog) {

        $scope.moduleName = userService.system.mNames[$state.current.code];
        $scope.module = userService.system.modules[$state.current.code];
        // get live operations
        Object.defineProperty($scope, 'operations', {
            get: function () { return userService.getOperationsFor($state.current.code); }
        });

        $scope.selectedIndex = 0;
        $scope.showDisabled = true;
        $scope.sendList = function (data) {
            var listToSend = [];
            if (Object.prototype.toString.call(data) === '[object Array]') {
                if (data.length) {
                    for (var i = 0; i < data.length; i++) {
                        if (typeof data[i].id !== 'undefined') {
                            listToSend.push(data[i].id);
                        } else if (typeof data[i].moduleId != 'undefined') {
                            listToSend.push(data[i].moduleId);
                        }
                    }
                }
            } else {
                if (typeof data.id != 'undefined') {
                    listToSend.push(data.id);
                } else if (typeof data.moduleId !== 'undefined') {
                    listToSend.push(data.moduleId);
                }
            }
            return listToSend;
        };

        $scope.loadManagerData = function () {
            //console.error('param for manager', $scope.moduleType === 0 ? true : false);
            $scope.manager = new Manager({
                objectsPerPage: 20,
                dataURL: URI.MODULES.SEARCH,
                /* urlParams: {
                    isSystem: $scope.moduleType === 0 ? true : false
                },*/
                dataWrapper: "data",
                options: {
                    multiselect: $scope.operations.Update ? true : false
                },
                otherParams: {
                    ShowDisabled: $scope.showDisabled,
                    filterUsed: false
                },
                rowOnClick: function (row) {
                    $scope.editModule(row);
                },

                statusFilter: {
                    model: $scope.showDisabled,
                    name: "Filter",
                    managerParam: "ShowDisabled",
                    dataArr: [
                        {
                            Key: true,
                            Val: "Show All"
                        },
                        {
                            Key: false,
                            Val: "Show Active"
                        }
                    ],
                    type: "select"
                },
                filters: [
                ],
                leftActions: [
                    {
                        setProperties: ACTIONS.CREATE,
                        isGroup: true,
                        conditionOnTop: function () {
                            if ($scope.operations.Create) return true;
                            else return false;
                        },
                        actions: [
                            {
                                setProperties: ACTIONS.CREATE_IN_DROPDOWN,
                                name: "Add Module",
                                click: function () {
                                    $scope.editModule(null, false);
                                }
                            },
                            {
                                setProperties: ACTIONS.CREATE_URL,
                                name: "Add URL",
                                click: function () {
                                    $scope.editModule(null, true);
                                }
                            },
                            

                        ]
                    }
                ],
                actions: [
                    {
                        setProperties: ACTIONS.REFRESH,
                        click: function () {
                            $scope.manager.refresh();
                        }
                    },

                    {
                        setProperties: ACTIONS.DETAILS,
                        click: function (row) {
                            $scope.editModule(row);
                        }
                    },
                    {
                        setProperties: ACTIONS.DUPLICATE,
                        displayOnRow: $scope.operations.Create ? true : false,
                        condition: function (row) {
                            if (row.isSystem || row.isUrl)
                                return false;
                            else return true;
                        },
                        click: function (row) {
                            $scope.duplicateModule(row);
                        }
                    },
                    {
                        setProperties: ACTIONS.ACTIVATE,
                        displayOnRow: $scope.operations.Update ? true : false,
                        condition: function (row) {
                            if (row.disabled)
                                return true;
                            else return false;
                        },
                        conditionOnTop: function () {
                            if ($scope.operations.Update) return true;
                            else return false;
                        },
                        click: function (rows) {
                            $scope.activateModule($scope.sendList(rows));
                        }
                    },
                    {
                        setProperties: ACTIONS.DEACTIVATE,
                        displayOnRow: $scope.operations.Update ? true : false,
                        condition: function (row) {
                            if (!row.disabled)
                                return true;
                            else return false;
                        },
                        conditionOnTop: function () {
                            if ($scope.operations.Update) return true;
                            else return false;
                        },
                        click: function (rows) {
                            $scope.deactivateModule($scope.sendList(rows));
                        }
                    },
                    {
                        setProperties: ACTIONS.EXPORT,
                        displayOnRow: $scope.operations.Update ? true : false,
                        click: function (rows) {
                            $scope.export(rows);
                        }
                    },
                    {
                        setProperties: ACTIONS.DELETE,

                        conditionOnTop: function () {
                            if ($scope.operations.Delete) return true;
                            else return false;
                        },
                        displayOnRow: $scope.operations.Delete ? true : false,
                        condition: function (row) {
                            if (!row.isUrl === false || !row.isSystem)
                                return true;
                            else return false;
                        },
                        click: function (rows) {
                            $scope.deleteModule($scope.sendList(rows));
                        }
                    },
                    {
                        setProperties: ACTIONS.MORE,
                        conditionOnTop: function () {
                            if ($scope.operations.Update) return true;
                            else return false;
                        },
                        actions: [
                            {
                                setProperties: ACTIONS.OPENUPLOADDIALOG,
                                name: 'Import',
                                accept: "application/json",
                                uploadUrl: URI.MODULES.IMPORT,
                                // urlParams: function() { return {}; },
                                click: function (rows) {
                                    $scope.import();
                                }
                            },
                            {
                                setProperties: ACTIONS.EXPORT,
                                click: function (rows) {
                                    $scope.export(rows);
                                }
                            },
                            {
                                setProperties: ACTIONS.ACTIVATE,
                                click: function (rows) {
                                    $scope.activateModule($scope.sendList(rows));
                                }
                            },
                            {
                                setProperties: ACTIONS.DEACTIVATE,
                                click: function (rows) {
                                    $scope.deactivateModule($scope.sendList(rows));
                                }
                            }

                        ]
                    }
                ],
                parseData: function (data, columns) {
                    var data = data || [], retval = [];
                    for (var i = 0; i < data.length; i++) {
                        var iconName = userService.system.icons.byKey.get(data[i].iconId || 0)?.name || userService.system.icons.byKey.get(0).name;
                        var color = data[i].color || 'black';
                        data[i]._name = '<mat-icon class="tableIcon mat-icon material-icons mat-ligature-font" style="color:'+color+'" fonticon="'+iconName+'"></mat-icon>' + data[i].name;
                        retval.push(data[i]);
                    }
                    return retval;
                },
            });
            var statusLookup = {
                false: { name: 'Active', class: 'basic' },
                true: { name: 'Deactivated', class: 'deactivated' }
            }
            var typeLookup = {
                false: { name: 'System Module' },
                true: { name: 'URL' }
            }

            var columns = [
                { name: '_name', label: 'Name', type: 'checkbox', replacementFor: 'name', onTablet: true, onPhone: true, width: 30, fieldTypeId: 1 },
                { name: 'abbreviation', label: 'Abbreviation', type: 'text', onTablet: true, onPhone: false, width: 20, fieldTypeId: 1 },
                { name: 'type', label: 'Type', type: 'text', onTablet: true, onPhone: false, width: 20, fieldTypeId: 1 },
                { name: 'disabled', label: 'Status', type: 'status', lookupArr: statusLookup, onTablet: true, onPhone: true, width: 20 }
            ];
            $scope.manager.set_Columns(columns);

            $scope.manager.loading = true;
            $scope.manager = rememberManagerService.restore($scope.manager, $scope.module.moduleId, 
                function() {
                    $scope.manager.loading = false;
                },
                function() {
                    $scope.manager.loadPage()
                    .finally(function () {
                        $scope.manager.loading = false;
                    });
                }
            );
  
        }

        $scope.loadManagerData();


        $scope.editModule = function (obj, isUrl, ev) {
            var obj = obj || null;
            var isUrl = obj ? obj.isUrl : isUrl;

            if ((obj && !obj.isUrl) || !isUrl) {
                $mdDialog.show({
                    locals: {
                        obj: obj, simplifiedVersion: false,
                        operations: $scope.operations, module: $scope.module
                    },
                    controller: 'editNModuleController',
                    templateUrl: '/ng/views/admin/modals/editNModule.html',
                    parent: angular.element(document.body),
                    targetEvent: ev,
                    fullscreen: true,
                    focusOnOpen: false,
                    escapeToClose: false,
                    multiple: true,
                    clickOutsideToClose: false,
                })
                    .then(function (result) {
                        //$scope.manager.page = 1;
                        if (result.properties) {
                            $scope.duplicateModule(result.properties);
                        }
                        $scope.manager.loadPage();
                        Menu.triggerRefresh();
                    }, function () {
                        Menu.triggerRefresh();
                        //$scope.manager.loadPage();
                    });
            }
            else {
                $mdDialog.show({
                    locals: { obj: obj, simplifiedVersion: false, operations: $scope.operations, module: $scope.module },
                    controller: 'editUrlController',
                    templateUrl: '/ng/views/admin/modals/editUrl.html',
                    parent: angular.element(document.body),
                    targetEvent: ev,
                    fullscreen: true,
                    escapeToClose: false,
                    clickOutsideToClose: false
                })
                    .then(function () {
                        $scope.manager.page = 1;
                        $scope.manager.loadPage();
                        Menu.triggerRefresh();
                    }, function () {
                        Menu.triggerRefresh();
                        $scope.manager.loadPage();
                    });
            }
        };

        var confirm = function (title, text, ev) {
            return $mdDialog.confirm()
                .title(title)
                .textContent(text)
                .ariaLabel('Confirm Dialogue')
                .targetEvent(ev)
                .ok('Proceed')
                .cancel('Cancel');
        };

        $scope.hide = function () {
            $mdDialog.hide();
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.duplicateModule = function (moduleToDuplicate, exportList) {
            $mdDialog.show({
                locals: {
                    module: $scope.module,
                    moduleToDuplicate: moduleToDuplicate,
                    exportList: exportList,
                },
                controller: 'duplicateModuleController',
                templateUrl: '/ng/views/admin/modals/duplicateModule.html',
                parent: angular.element(document.body),
                fullscreen: true,
                focusOnOpen: false,
                escapeToClose: false,
                multiple: true,
                clickOutsideToClose: false,
            })
            .then(function () {
                $scope.manager.loadPage();
            });
        };

        $scope.export = function (data) {
            var list = [];
            
            if (Object.prototype.toString.call(data) !== '[object Array]')
                list.push(data)
            else list = data;

            if (list.length) {
                for (var i = 0; i < list.length; i++) {
                    if (list[i].isSystem || list[i].isUrl) list[i].selected = false;
                    else list[i].selected = true;
                }
                $scope.duplicateModule(null, list);
            }
            else {
                Message.warning('Please select the item(s) to export');
            }
        }

        $scope.deactivateModule = function (list) {
            var url = URI.MODULES.DISABLE;

            list = list || [];

            if (list.length) {
                //var d = dialogs.confirm('Deactivate Module', 'Are you sure you want to deactivate the selected module(s)?');
                $mdDialog.show(confirm('Disable Modules', 'Are you sure you want to disable the selected Module(s)?'))
                    .then(function () {
                        $scope.manager.loading = true;
                        $scope.manager[url.method](url, { list: list })
                            .then(function () {
                                $scope.manager.loadPage();
                                Message.info('Module(s) disabled successfully');
                            })
                            .catch(function (e) {
                                $scope.manager.loading = false;
                                Message.dberror(e, $scope.manager.rows, 'id', 'name');
                            });
                    })
                    .catch(function (e) {
                        //cancel pressed
                    });


            } else {
                Message.warning('Please select the item(s) to disable');
            }
        };

        $scope.deleteModule = function (list) {
            list = list || [];

            if (list.length) {
                //var d = dialogs.confirm('Delete Module', 'Are you sure you want to delete the selected module(s)?');
                $mdDialog.show(confirm('Delete Modules', 'Are you sure you want to delete the selected Module(s)?'))
                    .then(function () {
                        $scope.manager.loading = true;
                        $scope.manager[URI.MODULES.DELETE.method](URI.MODULES.DELETE, { list: list })
                            .then(function () {
                                $scope.manager.loadPage();
                                Message.info('Module(s) deleted successfully');
                            })
                            .catch(function (e) {
                                $scope.manager.loading = false;
                                Message.dberror(e, $scope.manager.rows, 'id', 'name');
                            });
                    })
                    .catch(function () {
                        // cancel pressed
                    });
            } else {
                Message.warning('Please select the item(s) to delete');
            }
        };

        $scope.activateModule = function (list) {
            var url = URI.MODULES.ENABLE;
            list = list || [];

            if (list.length) {
                //var d = dialogs.confirm('Activate Module', 'Are you sure you want to activate the selected module(s)?');
                $mdDialog.show(confirm('Enable Modules', 'Are you sure you want to enable the selected Module(s)?'))
                    .then(function () {
                        $scope.manager.loading = true;
                        $scope.manager[url.method](url, { list: list })
                            .then(function () {
                                $scope.manager.loadPage();
                                Message.info('Module(s) activated successfully');
                            })
                            .catch(function (e) {
                                $scope.manager.loading = false;
                                Message.dberror(ee, $scope.manager.rows, 'id', 'name');
                            });
                    })
                    .catch(function () {
                        // cancel pressed
                    });



            } else {
                Message.warning('Please select the item(s) to activate');
            }
        };

    });
