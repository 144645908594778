import { Component, input, signal } from '@angular/core';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { CipoFormModule } from '../../../modules/forms.module';
import { CipoDateTimeConfig, CipoDateTimeControl } from '../common';
import moment from 'moment';
import { CipoTextViewComponent } from '../cipo-text-view/cipo-text-view.component';

@Component({
  selector: 'cipo-date-time',
  standalone: true,
  imports: [OwlDateTimeModule, CipoFormModule, FlexLayoutModule, CipoTextViewComponent],
  templateUrl: './cipo-date-time.component.html',
})
export class CipoDateTimeComponent {
  fieldData = signal<CipoDateTimeConfig>({});
  maxDate = signal<string>(null);

  formControl = input<CipoDateTimeControl, CipoDateTimeControl>(null, {
    transform: control => {
      this.onFieldDataChange(control);
      return control;
    },
    alias: 'control',
  });

  onFieldDataChange(control: CipoDateTimeControl) {
    const fieldData = control.fieldData ?? {};
    this.fieldData.set(fieldData);
    this.maxDate.set(fieldData.maxDate);
  }

  changed(date: Date) {
    if (
      this.fieldData().selectMode !== 'range' ||
      this.fieldData().pickerType === 'timer' ||
      !this.fieldData().maxRange
    ) {
      return;
    }
    const rangeEndDate = moment(date).add(this.fieldData().maxRange, 'day');
    const tempMaxDate = moment.min([rangeEndDate, moment(this.fieldData().maxDate)]);
    this.maxDate.set((this.fieldData().maxDate ? tempMaxDate : rangeEndDate).toISOString());
  }

  resetMaxDate() {
    this.maxDate.set(this.fieldData().maxDate);
  }
}
