import { cipo } from 'cipo';
import moment from 'moment';
import { ScreenTypeEnum } from 'src/app/models/module/screen';

const LAYOUT_UNDEFINED = -1;
const LAYOUT_TABLE = 1;
const LAYOUT_GRID = 2;
const LAYOUT_CALENDAR = 3;

cipo.factory("Manager", function (Model, $q, Functions, $window,
    $timeout, $http, FileSaver, userService, Permissions, Message, PdfHandler, URI, $mdDialog) {
    
    // constructor extending Model
    var Manager = Model.extend(function (obj) {
        var self = this;
        obj = obj || {};
        self.formulasValues = {};
        self.loadedPages = [1];
        self.searchObject = null;
        self.hasFields = true;
        /*
        Layout: 1: table, 2: grid

        Types of columns:
        text
        processed_text -- one field that uses separators and conditions properties, arrays passed to the filter convertManagerData
        status -- has a property statusLookup pointing to an obj statusLookup with the replace options
        joint -- several fields in the same column
        conditional_joint -- several fields in the same column that have separators and conditions properties passed to filterManagerData filter
        date -- to be formated
        tag -- uses the class label
        */
        self.printHeader = obj.printHeader || "";
        self.isOnModal = obj.isOnModal || false;
        self.ownProperties = false;
        self.isOnlyCurrent = true;
        self.menuClass = 'defaultVersion';
        self.page = 1; // default page is 1
        self.pages = 1; // number of pages
        self.objectsPerPage = 0; // default pagination is none
        self.objects = 0; // total number of objects
        self.loading = true;
        self.headers = obj.headers || {};
        self.columns = []; // columns description
        self.nestedColumns = [];
        self.rows = []; // data
        self.layout = LAYOUT_TABLE;
        self.screenType = ScreenTypeEnum.None;
        self.layoutOptions = [];
        self.dataURL = null;
        self.layoutUrlParams = obj.layoutUrlParams || function() {};
        self.dataParams = {};
        self.dataConfigParams = {};
        self.dataResponse = null;
        self.dataWrapper = null;
        self.recordsWrapper = 'records';
        self.filters = [];
        self.statusFilter = null;
        self.search = true;
        self.searchColumns = null;
        self.globalSearchColumns = null;
        self.regularCols = null;
        self.hideFilters = obj.hideFilters || false;
        self.hideVersions = obj.hideVersions || false;
        self.isUseLoadMoreSetting = obj.isUseLoadMoreSetting || false;

        self.maximumRows = 0;
        self.topRows = [];
        self.bottomRows = [];

        self.hasGlobalSearchToggle = obj.hasGlobalSearchToggle || false;
        self.globalSearchParam = obj.globalSearchParam || "";
        self.isGlobalSearch = obj.isGlobalSearch || "";

        self.userService = userService;

        // Show/hide grid/list layout button
        self.hideLayoutOptions = obj.hideLayoutOptions || false;
        // self.noFilters = true;
        // self.actionsTopGroups = obj.actionsTopGroups || [];
        self.rowStyle = (typeof obj.rowStyle != 'function') ?
            function () { return {}; } : obj.rowStyle;
        self.rowOnClick = (typeof obj.rowOnClick != 'function') ?
            function () { return {}; } : obj.rowOnClick;
        self.rowOnDblClick = (typeof obj.rowOnDblClick != 'function') ?
            function () { return {}; } : obj.rowOnDblClick;
        self.rowClass = (typeof obj.rowClass != 'function') ?
            function () { return {}; } : obj.rowClass;
        self.parseData = (typeof obj.parseData != 'function') ?
            function (data) { return data; } : obj.parseData;
        self.actions = [];
        self.leftActions = [];
        self.otherParams = {};
        self.urlParams = {};
        self.hasTableHeader = true;
        self.hasActionBar = obj.hasActionBar || true;
        self.currencySymbol = userService.getCurrency();
        self.options = {
            multiselect: false,
            sortable: false,
            sort: [],
            hasTableHeader: true
        };

        self.hasFilters = false;

        self.isShowPresets = false;

        

        // PDF downloading/sending
        self.moduleId = obj.moduleId;
        self.moduleName = obj.moduleName || null;
        self.moduleCode = obj.moduleCode || null;
        var isPerContract = userService.system.modules[self.moduleCode]?.perContract;
        self.contractId = obj.contractId || (isPerContract ? userService.system.userdata.contractId : null) || null;       
        self.contractNo = obj.contractNo || "";
        self.entityInstanceId = obj.entityInstanceId || null;
        self.correspondenceOperations = obj.correspondenceOperations || null;
        self.pdfHandler = self.hasRequiredPdfHandlerProperties()
            ? new PdfHandler({
                isLoading: function (isLoading) { self.loading = isLoading },
                moduleId: self.moduleId,
                moduleName: self.moduleName,
                moduleCode: self.moduleCode,
                contractId: self.contractId,
                entityInstanceId: self.entityInstanceId,
                contractNo: self.contractNo,
                clearSelectedRows: function () { self.clearSelectedRows() },
                correspondenceOperations: self.correspondenceOperations
            })
            : null;
        // END

        if (typeof obj != 'undefined') {
            self.ownProperties = true;
            self.setObjectDefaults(obj);
        }

        self.statusSelectChange = function (option, param) {
            //console.log("option // param ", option, param);
            self.otherParams['ShowDisabled'] = option;
            self.loadPage(option, param);
            self.statusFilter.model = option;
        };
    });

    Manager.prototype.getScreenTypeFromLayout = function (layout) {
        return layout == LAYOUT_CALENDAR ? ScreenTypeEnum.Calendar : (layout == LAYOUT_GRID ? ScreenTypeEnum.Card : ScreenTypeEnum.Entity);
    }

    Manager.prototype.multiplyShit = function () {
        var self = this;
        // self.rows = self.rows.concat(angular.copy(self.rows));
        var x = self.rows.length;
        for (var i = 0; i < x; i++) {
            self.rows.push(angular.copy(self.rows[i]));
        }
    }

    Manager.prototype.setOtherParams = function (otherParams, dataParams) {
        if (otherParams) {
            // console.log('Manager, get data, other params', otherParams);
            for (var key in otherParams) {
                if (otherParams.hasOwnProperty(key)) {
                    dataParams[key] = typeof otherParams[key] === 'function' ? otherParams[key]() : otherParams[key];
                }
            }
        }
    }

    Manager.prototype.loadMore = function () {
        var self = this;
        var method = (typeof self.dataURL.method == 'undefined') ? 'get' : self.dataURL.method;

        self.page++;
        var params = self.create_Params(method);

        self[method](self.dataURL.toString(), params, self.dataConfigParams)
            .then(function (r) {
                if (((r || {}).data || []).length) {
                    for (var i = 0; i < r.data.length; i++) {
                        self.rows.push(r.data[i]);
                    }
                }
            })
            .catch(function () { })
    }

    Manager.prototype.init_reports = function (module, ev) {
        var self = this;
        $mdDialog.show({
            locals: { module: module },
            controller: 'manageReportsController',
            templateUrl: '/ng/views/admin/modals/manageReports.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            fullscreen: true,
            focusOnOpen: false,
            multiple: false,
            escapeToClose: false,
            clickOutsideToClose: false
        })
            .then(function () {
                // self.getFolderContent(self.selectedFolder);
            }, function () {
                // cancel pressed
            });
    }

    Manager.prototype.getFilterInformation = function (filter) {
        var self = this;
        filter.loading = true;
        var dataURL = URI.MODULE_GRID.GET_FILTER;
        self.globalFilterInfo = null;
        self.currentFilters = null;

        self[dataURL.method](dataURL,
            { url: { id: filter.key, contractId: userService.system.userdata.contractId }, urltype: 'obj' },
            { headers: { moduleId: self.moduleId } })
            .then(function (r) {

                // If filter contains bad data, display a warning message
                if (r.containsBadData)
                    Message.warning('This filter contains invalid data.');

                var filtersForTheCall = angular.copy(r);

            for (var i = 0; i < self.globalFilters.length; i++) {
                self.globalFilters[i].isActive = false;
            }

            filter.isActive = true;

                self.globalFilterInfo = { overallLogic: 0, filters: {} };

                self.globalFilterInfo.overallLogic = r.logic;

                for (var i = 0; i < r.filters.length; i++) {
                    for (var j = 0; j < r.filters[i].filters.length; j++) {
                        if (r.filters[i].filters[j].field) {
                            self.globalFilterInfo.filters[r.filters[i].filters[j].field] = {
                                operator: r.filters[i].filters[j].operator,
                                value: r.filters[i].filters[j].value,
                                logic: r.filters[i].filters[j].logic,
                                dataSource: r.filters[i].filters[j].dataSource,
                            }
                        } else {
                            // multiselects
                            for (var k = 0; k < r.filters[i].filters[j].filters.length; k++) {
                                // replace datasource id with the datasource value
                                if (r.filters[i].filters[j].filters[k].dataSource && r.filters[i].filters[j].filters[k].dataSource.length && r.filters[i].filters[j].filters[k].dataSource[0].value) {
                                    filtersForTheCall.filters[i].filters[j].filters[k].value = r.filters[i].filters[j].filters[k].dataSource[0].value;

                                    if (!self.globalFilterInfo.filters[r.filters[i].filters[j].filters[k].field]) {
                                        self.globalFilterInfo.filters[r.filters[i].filters[j].filters[k].field] = {
                                            operator: r.filters[i].filters[j].filters[k].operator,
                                            value: [r.filters[i].filters[j].filters[k].value],
                                            logic: r.filters[i].filters[j].filters[k].logic,
                                            dataSource: r.filters[i].filters[j].filters[k].dataSource,
                                        }
                                    } else {
                                        self.globalFilterInfo.filters[r.filters[i].filters[j].filters[k].field].value.push(r.filters[i].filters[j].filters[k].value);
                                        self.globalFilterInfo.filters[r.filters[i].filters[j].filters[k].field].dataSource.push(r.filters[i].filters[j].filters[k].dataSource[0]);
                                    }
                                }
                            }
                        }
                    }
                }

            self.dataParams.filter = filtersForTheCall;
            self.hasFilters = true;

            self.loadPage(null, null, true);
        })
        .catch(function (e) { console.error(e); Message.dberror(e); })
        .finally(function () { filter.loading = false; })
    }

    Manager.prototype.clearFilters = function () {
        var self = this;
        if (self.dataParams.filter) delete self.dataParams.filter;
        // clear custom filters
        if (self.customFilters) {
            for (var i = 0; i < self.customFilters.length; i++) {
                self.customFilters[i].value = false;
                if (self.dataParams && self.dataParams.hasOwnProperty(self.customFilters[i].key)) {
                    self.dataParams[self.customFilters[i].key] = false;
                }
                if (self.urlParams && self.urlParams.hasOwnProperty(self.customFilters[i].key)) {
                    self.urlParams[self.customFilters[i].key] = false;
                }
            }
        }
        self.hasFilters = false;
        self.currentFilters = null;
        self.globalFilterInfo = null;
        self.loadPage(null, null, true);
    }

    Manager.prototype.reloadInformation = function () {
        var self = this;
        self.isLoadingInformation = true;
        var dataURL = URI.MODULE_GRID.MODULE_INFORMATION;
        self[dataURL.method](dataURL, { url: { contractId: userService.system.userdata.contractId }, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function (r) { self.moduleInfo = r; })
            .catch(function (e) { Message.dberror(e) })
            .finally(function () { self.isLoadingInformation = false; })
    }

    Manager.prototype.setGlobalSearchParam = function (isToggle) {
        var self = this;
        if (isToggle) {
            self.isGlobalSearch = !self.isGlobalSearch;
        }
        // self.urlParams[self.globalSearchParam] = self.isGlobalSearch;
        self.dataParams[self.globalSearchParam] = self.isGlobalSearch;

        if (self.criteria || self.hasFilters) self.loadPage();
    }


    Manager.prototype.toggleFilters = function () {
        var self = this;
        self.isShowFilters = true;
    }

    Manager.prototype.closeFilters = function () {
        var self = this;
        self.isShowFilters = false;
        // self.resetFilters();
    }

    Manager.prototype.notCloseFilters = function (e) {
        var self = this;
        e.stopPropagation();
    }

    // If these properties are provided then we can use the pdfHandler
    Manager.prototype.hasRequiredPdfHandlerProperties = function () {
        var self = this;
        return self.moduleId &&
            self.moduleName &&
            self.moduleCode &&
            (self.contractId || self.entityInstanceId) &&
            self.contractNo != null;
    }

    Manager.prototype.refreshPdfHandler = function () {
        var self = this;
        if (self.hasRequiredPdfHandlerProperties()) {
            var isPerContract = userService.system.modules[self.moduleCode].perContract;
            self.pdfHandler.contractId = userService.system.userdata.contractId;
            self.pdfHandler.contractNo = isPerContract
                ? userService.system.context.contract?.no
                : "";
        }
    }

    Manager.prototype.restoreSearch = function (isReload) {
        var self = this;
        self.urlParams = angular.copy(self.searchObject.urlParams);
        self.dataParams = angular.copy(self.searchObject.dataParams);
        self.searchObject.isSearchView = true;
        self.isGlobalSearch = self.dataParams[self.globalSearchParam];
        if (isReload) self.loadPage();
    }

    Manager.prototype.refresh = function () {
        var self = this;

        var p = $q.defer();
        var refreshEnd = function() { p.resolve(); };

        self.topRows = [];
        self.dataParams.filter = self.dataParams.filter ? {...self.dataParams.filter} : undefined;
        if (self.userService.system.userdata.loadMore) {
            if (!self.isShowPresets) self.goToPage(1).finally(refreshEnd);
            else self.loadPresetPage().finally(refreshEnd);
        } else {
            if (!self.isShowPresets) self.loadPage().finally(refreshEnd);
            else self.loadPresetPage().finally(refreshEnd);
        }
        return p.promise;
    }

    Manager.prototype.loadPage = function (option, param, isResetPages) {
        var self = this;
        self.pageIsLoading = true;
        self.refreshPdfHandler();

        var p = $q.defer();
        
        if (self.layout == LAYOUT_CALENDAR) {
            // data is loaded externally
            setTimeout(() => {
                self.pageIsLoading = false;
                self.loading = false;
                p.resolve();
            }, 0);
            return p.promise;
        }

        var option = typeof option != "undefined" ? option : null;
        var param = typeof param != "undefined" ? param : null;

        if (isResetPages) {
            self.goToPage(1)
                .then(function (r) { p.resolve(); })
                .catch(function (e) {
                    p.reject(); console.error(e);
                    Message.dberror(e);
                })
                .finally(function () {
                    self.pageIsLoading = false;
                })
        }
        else {

            if (self.criteria || self.hasFilters) {
                if (self.searchColumns && !self.isGlobalSearch) {
                    if (!self.regularCols) self.regularCols = self.layoutCols[1];
                    self.layoutCols[1] = self.searchColumns;
                }

                if (self.globalSearchColumns && self.isGlobalSearch) {
                    if (!self.regularCols) self.regularCols = self.layoutCols[1];
                    self.layoutCols[1] = self.globalSearchColumns;
                }

            } else if ((self.searchColumns || self.globalSearchColumns) && self.regularCols) {
                self.layoutCols[1] = self.regularCols;
                self.regularCols = null;
            }

            self.get_Data(option, param).then(function () {
                // console.log("self rows ", self.rows);


                if (self.rows && self.rows.length) {
                    for (var i = 0; i < self.rows.length; i++) {
                        self.rows[i].selected = false;
                        self.rows[i].operations = {};
                        if ((self.rows[i].permissions || []).length) {
                            for (var j = 0; j < self.rows[i].permissions.length; j++) {
                                Object.assign(self.rows[i].operations, Permissions[self.rows[i].permissions[j]]);
                            }
                        }
                    }
                }

                self.calculatePages(isResetPages);
                self.isShowPresets = false;

                p.resolve();
            }).catch(function (e) {
                p.reject(e);
                console.error(e);
                Message.dberror(e);
            })
            .finally(function () {
                self.pageIsLoading = false;
            });
        }

        return p.promise;
    }

    Manager.prototype.loadPageInfinite = function () {
        var self = this;
        self.refreshPdfHandler();
        var p = $q.defer();
        self.loadingInfinite = true;

        if (self.criteria || self.hasFilters) {
            if (self.searchColumns && !self.isGlobalSearch) {
                if (!self.regularCols) self.regularCols = self.layoutCols[1];
                self.layoutCols[1] = self.searchColumns;
            }

            if (self.globalSearchColumns && self.isGlobalSearch) {
                if (!self.regularCols) self.regularCols = self.layoutCols[1];
                self.layoutCols[1] = self.globalSearchColumns;
            }

        } else if ((self.searchColumns || self.globalSearchColumns) && self.regularCols) {
            self.layoutCols[1] = self.regularCols;
            self.regularCols = null;

        }

        self.get_DataInfinite().then(function () {
            // console.log("self rows ", self.rows);


            if (self.rows && self.rows.length) {
                for (var i = 0; i < self.rows.length; i++) {
                    self.rows[i].selected = false;
                    self.rows[i].operations = {};
                    if ((self.rows[i].permissions || []).length) {
                        for (var j = 0; j < self.rows[i].permissions.length; j++) {
                            Object.assign(self.rows[i].operations, Permissions[self.rows[i].permissions[j]]);
                        }
                    }
                }
            }

            self.calculatePages();
            self.isShowPresets = false;

            p.resolve();
        }).catch(function (e) {
            p.reject(e);
            console.error(e);
            Message.dberror(e);
        }).finally(function () {
            self.loadingInfinite = false;
        });


        return p.promise;
    }

    Manager.prototype.loadPresetPage = function (option, param) {
        var self = this;
        self.refreshPdfHandler();
        var p = $q.defer();
        var option = typeof option != "undefined" ? option : null;
        var param = typeof param != "undefined" ? param : null;
        self.get_PresetsData(option, param).then(function () {
            if (self.rows && self.rows.length) {
                for (var i = 0; i < self.rows.length; i++) {
                    self.rows[i].selected = false;
                    self.rows[i].operations = {};
                    if ((self.rows[i].permissions || []).length) {
                        for (var j = 0; j < self.rows[i].permissions.length; j++) {
                            Object.assign(self.rows[i].operations, Permissions[self.rows[i].permissions[j]]);
                        }
                    }
                }
            }

            self.calculatePages();
            self.isShowPresets = true;

            p.resolve();
        }).catch(function (e) {
            p.reject(e);
            console.error(e);
            Message.dberror(e);
        });


        return p.promise;
    }

    Manager.prototype.calculatePages = function (isResetPages) {
        var self = this;
        self.pages = Math.ceil(self.records / self.objectsPerPage);
        if (self.page) {
            if (isResetPages) {
                self.page = 1;
            }

            self.itemsStart = self.page * self.objectsPerPage - self.objectsPerPage + 1;
            self.itemsEnd = (self.page * self.objectsPerPage) < self.records ? self.page * self.objectsPerPage : self.records;
        }
    }

    Manager.prototype.set_Screens = function (screens) {
        var self = this;
        self.mainScreens = screens;
    }

    Manager.prototype.set_Columns = function (list) {
        var self = this;
        self.layoutCols = {};
        self.allColumns = [];
        self.layoutOptions = [];

        if (list && (Object.prototype.toString.call(list) == "[object Object]")) {
            // multiple layouts
            for (var key in list) {
                if (list.hasOwnProperty(key)) {
                    self.allColumns = self.allColumns.concat(list[key]);
                    self.set_Columns_Values(list[key]);
                    self.layoutCols[key] = self.columns;
                    self.layoutOptions.push(key);
                }
            }
        } else {
            self.set_Columns_Values(list);
            self.layoutCols[1] = self.columns;
            self.allColumns = self.columns;
        }
        var realColumns = self.allColumns.filter(function (column) {
            return !column.isFilter;
        });

        self.hasFields = realColumns.length > 0;
        self.maximumRows = Math.ceil(1000 / realColumns.length);

        if (!self.layoutCols[self.layout]) {
            // layout configuration no longer exists
            self.setLayout(LAYOUT_TABLE);
        }
    }

    Manager.prototype.set_Columns_Values = function (list) {
        var self = this;
        var columns = [];
        var nestedColumns = [];
        // console.log("list ", list);
        if (list.length) {

            for (var i = 0; i < list.length; i++) {
                if ((list[i].column || list[i].name) && list[i].type) {

                    if (typeof list[i].name == "string" || typeof list[i].name == "number") {
                        list[i].name = list[i].name ? list[i].name : list[i].column;
                        var column = {
                            width: list[i].width,
                            name: list[i].name.toString(),
                            replacementFor: list[i].replacementFor ? list[i].replacementFor.toString() : list[i].name.toString(),
                            type: list[i].type.toString(),
                            label: list[i].label ? list[i].label.toString() : list[i].name.toString()
                        };
                        // console.log("column", column);
                        for (var key in list[i]) {
                            if (list[i].hasOwnProperty(key) && key != 'name' && key != 'type'
                                && key != 'label' && key != 'column') {

                                column[key] = list[i][key];

                            }
                        }
                    } else {
                        if (list[i].type == "conditional_joint") {
                            if (list[i].conditions.length) {

                                var column = {
                                    width: list[i].width,
                                    name: list[i].name,
                                    type: list[i].type.toString(),
                                    separators: list[i].separators || [],
                                    conditionList: list[i].conditions,
                                    label: list[i].label ? list[i].label.toString() : list[i].name.toString()
                                };
                            }
                        } else {
                            var column = {
                                width: list[i].width,
                                name: list[i].name,
                                type: list[i].type.toString(),
                                separators: list[i].separators || [],
                                label: list[i].label ? list[i].label.toString() : list[i].name.toString()
                            };
                        }

                    }

                    if (column.name.lastIndexOf('.') != -1) nestedColumns.push(column);

                    // add default sort if column is primary sort
                    if (typeof list[i].isPrimarySort != 'undefined' && list[i].isPrimarySort) {
                        self.options.sort = [{
                            field: column.replacementFor,
                            order: column.isDescending ? 1 : 0
                        }]
                    }


                    columns.push(column);
                }
            }
        }

        if (nestedColumns.length) self.nestedColumns = nestedColumns;
        if (columns.length) self.columns = columns;
        return;
    }

    // populate filters
    Manager.prototype.get_selectData = function (dataURL, type) {
        var p = $q.defer();
        var self = this;
        self.get(dataURL)
            .then(function (result) {
                if (type == 'stringsArray' || type == 'UIselect') {
                    if (Object.prototype.toString.call(result) == "[object Array]") p.resolve(result);
                    else {
                        p.resolve(result.data);
                    }
                } else if (type == 'dict') {
                    if (Object.prototype.toString.call(result) == "[object Object]") p.resolve(result);
                    else {
                        p.resolve([]);
                    }
                }
            })
            .catch(function (reject) {
                console.error(reject.data);

                p.resolve([]);
            });

        return p.promise;
    }

    Manager.prototype.printLog = function () {
        var self = this;
        var urlParams = angular.copy(self.urlParams);
        var bodyParams = angular.copy(self.dataParams);
        if (bodyParams.pagesize) delete bodyParams.pagesize;
        var params = { url: urlParams, urltype: 'obj', body: bodyParams };
        self.disablePrintBtn = true;

        //console.error('print', params);

        var processPrint = function (dataListRow, column) {
            var data = dataListRow[column.name];
            if (!data) {
                return '-';
            }
            if (column.typeId == 5) {
                return dataListRow[column.name + '_thumb'];
            }
            return data;
        }

        self[self.dataURL.method](self.dataURL.toString(), params, self.dataConfigParams)
            .then(function (r) {
                // var toPrint = r.data;
                var toPrint = self.parseData(r.data, self.allColumns || []);
                if ($("#section-to-print").length != 0) {
                    $("#section-to-print").remove();

                }
                var div = $('<div />').appendTo('body');
                div.attr('id', 'section-to-print');
                //$('<table />').appendTo(div);

                var tableHtml = self.printHeader + "<table class='table'><thead><tr>";
                for (var i = 0; i < self.columns.length; i++) {
                    if (!self.columns[i].isFilter && self.columns[i].printable) tableHtml += "<th>" + self.columns[i].label + "</th>";
                }
                tableHtml += "</tr></thead>";
                tableHtml += "<tbody>";

                for (var i = 0; i < toPrint.length; i++) {
                    tableHtml += "<tr>";
                    for (var j = 0; j < self.columns.length; j++) {
                        if (!self.columns[j].isFilter && self.columns[j].printable)
                            tableHtml += "<td>" + (processPrint(toPrint[i], self.columns[j])) + "</td>";
                    }
                    tableHtml += "</tr>";
                }
                tableHtml += "</tbody>";
                tableHtml += "</table>";
                $timeout(function () {
                    $("#section-to-print").html(tableHtml);
                    $window.print();
                }, 1)
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
            })
            .finally(function () {
                self.disablePrintBtn = false;
            })
    }

    Manager.prototype.download = function (rows) {
        var self = this;
        self.pdfHandler.download(rows);
    }

    Manager.prototype.downloadByUrl = function (url, fileName) {
        var self = this;
        self.pdfHandler.downloadByUrl(url, fileName);
    }

    Manager.prototype.email = function (scope, rows) {
        var self = this;
        self.pdfHandler.email(scope, rows);
    }

    Manager.prototype.emailPdf = function (scope, file) {
        var self = this;
        self.pdfHandler.emailPdf(scope, file);
    }

    Manager.prototype.printDocuments = function (rows) {
        var self = this;
        self.pdfHandler.printDocuments(rows);
    }

    Manager.prototype.printPdf = function (pdf) {
        var self = this;
        self.pdfHandler.printPdf(pdf);
    }

    Manager.prototype.exportAsExcel = function () {
        var self = this;
        var urlParams = angular.copy(self.urlParams);
        var bodyParams = angular.copy(self.dataParams);
        if (bodyParams.pagesize) delete bodyParams.pagesize;
        var params = { url: urlParams, urltype: 'obj', body: bodyParams };
        self.loading = true;

        self[URI.MODULE.GET_AS_EXCEL.method](URI.MODULE.GET_AS_EXCEL, params, self.dataConfigParams)
            .then(function (result) {
                if (result.length <= 0) {
                    Message.error('An error occurred when generating the Excel document.');
                    return;
                }
                var fileName = [self.moduleName, self.contractNo, moment(new Date()).format('YYYY_MM_DD')]
                    .filter(x => x)
                    .join('-') + '.xlsx';
                var fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                var fileOptions = {
                    type: fileType,
                    mimeType: fileType,
                    fileClass: fileType.replace(/\//g, '-').replace(/\./g, '-')
                };

                    var newFile = new File([Functions.base64ToArrayBuffer(result)], fileName, fileOptions);
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(newFile);
                    link.download = fileName;
                    link.click();
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
            })
            .finally(function () { self.loading = false; });
    }

    Manager.prototype.downloadTemplate = function () {
        var self = this;
        var defaultFileName = 'Template.xlsx';
        var par = {
            headers: { moduleId: self.moduleId },
            responseType: "arraybuffer"
        }

        self.loading = true;
        $http[URI.MODULE.EXPORT_TEMPLATE.method](URI.MODULE.EXPORT_TEMPLATE.toString(), par)
            .then(function (r) {
                var type = r.headers('Content-Type');
                var disposition = r.headers('Content-Disposition');
                if (disposition) {
                    var match = disposition.match(/.*filename=\"?([^;\"]+)\"?.*/);
                    if (match[1])
                        defaultFileName = match[1];
                }
                defaultFileName = defaultFileName.replace(/[<>:"\/\\|?*]+/g, '_');
                var blob = new Blob([r.data], { type: type });
                FileSaver.saveAs(blob, defaultFileName);
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
            })
            .finally(function () {
                self.loading = false;
            });
    }

    Manager.prototype.getFormulasValues = function (option, param) {
        var self = this;

        if (!((self.bottomFormulas || []).length || (self.topFormulas || []).length))
        {
            return;
        }

        var option = typeof option != "undefined" ? option : null;
        var param = typeof param != "undefined" ? param : null;
        var method = (typeof self.dataURL.method == 'undefined') ? 'get' : self.dataURL.method;
        var params = self.create_Params(method, option, param);
        var bodyParams = (params || {}).body

        var urlData, urlParams;
        if (self.urlParams.relationId) {
            urlData = URI.MODULE_GRID.GET_CHILDREN_FORMULAS;
            urlParams = {
                contractId: userService.system.userdata.contractId,
                relationId: self.urlParams.relationId,
                parentEntityInstanceId: self.urlParams.parentEntityInstanceId,
                // current: self.isOnlyCurrent
            };
        } else {
            urlData = URI.MODULE_GRID.GET_FORMULAS;
            urlParams = {
                contractId: userService.system.userdata.contractId,
                current: self.isOnlyCurrent
            }
        }
        var p = $q.defer();
        self[urlData.method](urlData,
            {
                url: urlParams, urltype: 'obj', body: bodyParams || {},
            },
            { headers: { moduleId: self.moduleId } })
            .then(function (r) {
                self.formulasValues.bottomFormulas = r.bottomFormulas;
                self.formulasValues.topFormulas = r.topFormulas;
                p.resolve(r);
            })
            .catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            })
        return p.promise;
    }

    Manager.prototype.parseEntityInstance = function (entityInstanceId, entityTemplateId) {
        var self = this;
        self.parsingEntityInstance = true;

        self.get_DataEntityInstance(entityInstanceId, entityTemplateId)
            .finally(function () { 
                self.parsingEntityInstance = false;
            });

        return;
    }

    Manager.prototype.get_DataEntityInstance = function (entityInstanceId, entityTemplateId) {
        var p = $q.defer();
        var self = this;

        if (self.dataURL) {
            self.loading = true;

            self.req_DataEntityInstance(entityInstanceId)
            .then(function (dataEntityInstance) {
                self.handle_DataEntityInstance(entityInstanceId, entityTemplateId, dataEntityInstance);
                p.resolve();
            }).catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            }).finally(function () {
                self.loading = false;
            });
        }
        else {
            Message.error("The search URL is not valid");
            p.reject();
        }

        return p.promise;
    }

    Manager.prototype.req_DataEntityInstance = function (entityInstanceId) {
        var self = this;
        var p = $q.defer();

        var option = typeof option != "undefined" ? option : null;
        var param = typeof param != "undefined" ? param : null;
        var myCallParams = angular.copy(self.dataParams);
        var method = (typeof self.dataURL.method == 'undefined') ? 'get' : self.dataURL.method;

        self.setOtherParams(self.otherParams, myCallParams);

        if (typeof option != "undefined" && typeof param != "undefined" && option != null) {
            myCallParams[param] = option;
        }

        myCallParams.criteria = "";
        myCallParams.page = 1;
        myCallParams.pagesize = 10;

        // add entityInstance filter
        myCallParams.filter = {
            "logic": 0,
            "filters": [
                {
                    "logic": 0,
                    "filters": [
                        {
                            "field": "-1",
                            "operator": 0,
                            "options": "",
                            "value": entityInstanceId,
                            "fieldTypeId": 2
                        }]
                }]
        };

        var urlParams = self.get_urlParams();
        var params = { url: urlParams, urltype: 'obj', body: myCallParams }
        params = self.handle_ParamsSorting(params);

        (params.body || {}).isCurrent = self.isOnlyCurrent;
        if (self.moduleId) {
            self.dataConfigParams = {
                headers: { moduleId: self.moduleId }
            }
        }

        self[method](self.dataURL.toString(), params, self.dataConfigParams).then(function (result) {
            p.resolve(result);
        }).catch(function (e) {
            p.reject(e);
        });

        return p.promise;
    }

    Manager.prototype.handle_DataEntityInstance = function (entityInstanceId, entityTemplateId, dataEntityInstance) {
        var self = this;
        var myCallRow = [];

        if (self.dataWrapper == null) {
            myCallRow = dataEntityInstance;
        } else if (typeof self.dataWrapper == 'string') {
            myCallRow = dataEntityInstance[self.dataWrapper];
        } else {
            for (var i = 0; i < self.dataWrapper.length; i++) {
                if (dataEntityInstance[self.dataWrapper[i]] && dataEntityInstance[self.dataWrapper[i]].length)
                    myCallRow = myCallRow.concat(dataEntityInstance[self.dataWrapper[i]]);
            }
        }

        // hack for decode
        if (myCallRow && myCallRow.length) {
            var myCallRowParsedArray = self.decodeRows(myCallRow);

            if (myCallRowParsedArray.length == myCallRow.length) {
                var eqi = function (a, b) {
                    return a == null && b == null || a != null && b != null && a == b;
                };

                var myCallRowByEntityInstanceId = myCallRowParsedArray.find((i) => eqi(i.entity_instance_id, entityInstanceId));
                var rowIndexByEntityInstanceId = entityInstanceId ? self.rows.findIndex((i) => eqi(i.entity_instance_id, entityInstanceId)) : -1;

                var myCallRowByEntityTemplateId = myCallRowParsedArray.find((i) => eqi(i.entity_template_id, entityTemplateId));
                var rowIndexByEntityTemplateId = entityTemplateId ? self.rows.findIndex((i) => eqi(i.entity_template_id, entityTemplateId)) : -1;

                // there is a row identified by entity instance id or entity template id
                if (rowIndexByEntityInstanceId != -1 || rowIndexByEntityTemplateId != -1) {
                    if (rowIndexByEntityInstanceId != -1 && myCallRowByEntityInstanceId) {
                        // row identified by entity instance id
                        self.rows[rowIndexByEntityInstanceId] = myCallRowByEntityInstanceId;
                    }
                    else if (rowIndexByEntityTemplateId != -1) {
                        // row identified by entity template id / preset id
                        self.rows[rowIndexByEntityTemplateId] = myCallRowByEntityInstanceId || myCallRowByEntityTemplateId;
                    }
                } else {
                    // no row was identified, will be added
                    self.rows.unshift(myCallRowByEntityInstanceId || myCallRowByEntityTemplateId)
                    self.records++;
                }

                self.calculatePages();
            };
        }
    }
    
    Manager.prototype.get_urlParams = function () {
        return {
            ...this.urlParams,
            ...(this.layoutUrlParams(this.layout) || {})
        };
    }

    Manager.prototype.setLayout = function (option) {
        var self = this;
        var p = $q.defer();
        self.layout = option;
        self.screenType = self.getScreenTypeFromLayout(option);
        self.refresh().finally(function() { p.resolve(); });
        return p.promise;
    }

    Manager.prototype.get_Data = function (option, param) {
        var p = $q.defer();
        var self = this;

        var option = typeof option != "undefined" ? option : null;
        var param = typeof param != "undefined" ? param : null;

        if (self.dataURL) {
            self.rows = [];
            self.loading = true;

            var method = (typeof self.dataURL.method == 'undefined') ? 'get' : self.dataURL.method;
            var params = self.create_Params(method, option, param);

            self[method](self.dataURL.toString(), params, self.dataConfigParams).then(function (r) {
                self.getFormulasValues(option, param);

                if (self.dataWrapper == null) {
                    self.rows = r
                } else if (typeof self.dataWrapper == 'string') {
                    self.rows = r[self.dataWrapper];
                } else {
                    for (var i = 0; i < self.dataWrapper.length; i++) {
                        if (r[self.dataWrapper[i]] && r[self.dataWrapper[i]].length) self.rows = self.rows.concat(r[self.dataWrapper[i]]);
                    }
                }
                // creating nested columns
                if (self.nestedColumns.length) {
                    for (var i = 0; i < self.rows.length; i++) {
                        for (var j = 0; j < self.nestedColumns.length; j++) {
                            self.rows[i][self.nestedColumns[j].name] = self.getNestedValue(self.nestedColumns[j].name, self.rows[i]);
                        }
                    }
                }
                self.records = r[self.recordsWrapper] || self.rows.length;

                // hack for decode
                if (self.rows.length) {
                    self.rows = self.decodeRows(self.rows);
                }

                p.resolve();
            }).catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            }).finally(function () {
                self.loading = false;
            });
        }

        else { Message.error("The search URL is not valid"); p.reject(); }

        return p.promise;
    }

    Manager.prototype.decodeRows = function (rows) {
        var self = this;
        rows = rows || [];
        for (var i = 0; i < rows.length; i++) {
            for (var key in rows[i]) {
                if (Object.prototype.toString.call(rows[i][key]) == "[object String]" && rows[i].hasOwnProperty(key)) {
                    rows[i][key] = rows[i][key]
                        .replace(new RegExp('&amp;', 'g'), '&')
                        .replace(new RegExp('&gt;', 'g'), '>')
                        .replace(new RegExp('&lt;', 'g'), '<');
                }
            }
        }
        rows = self.parseData(rows, self.allColumns || []);
        return rows;
    }

    Manager.prototype.get_DataInfinite = function () {
        var p = $q.defer();
        var self = this;

        self.page = self.page + 1;
        self.loadedPages.push(self.page);

        var params = self.create_Params(self.dataURL.method);

        self[self.dataURL.method](self.dataURL.toString(), params, self.dataConfigParams).then(function (r) {
            var dataresponse = r ? (self.dataWrapper ? r[self.dataWrapper] : r) : r;

            // hack for decode
            if (dataresponse.length) {
                dataresponse = self.decodeRows(dataresponse);
            }

            self.getFormulasValues();

            for (var i = 0; i < dataresponse.length; i++) {
                self.rows.push(dataresponse[i]);
            }

            if (self.dataWrapper == null) {
                //skip
            } else if (typeof self.dataWrapper == 'string') {
                //skip
            } else {
                Message.error("Multiple wrappers case untreated");
            }

            // creating nested columns
            if (self.nestedColumns.length) {
                for (var i = 0; i < self.rows.length; i++) {
                    for (var j = 0; j < self.nestedColumns.length; j++) {
                        self.rows[i][self.nestedColumns[j].name] = self.getNestedValue(self.nestedColumns[j].name, self.rows[i]);
                    }
                }
            }
            self.records = r[self.recordsWrapper] || self.rows.length;

            p.resolve();
        }).catch(function (e) {
            console.error(e);
            Message.dberror(e);
            p.reject(e);
        }).finally(function () {
            self.loading = false;
        });

        return p.promise;
    }

    Manager.prototype.handle_ParamsSorting = function (params) {
        var self = this;
        if (self.layout == LAYOUT_GRID || self.layout == LAYOUT_CALENDAR) {
            var existsFullDocNum = self.allColumns.some(c => c.name == 'full_doc_num');
            if (existsFullDocNum) {
                params.body.sort = [{ field: 'full_doc_num', order: 0 }];
            } else {
                delete params.body.sort;
            }
        }
        else if (self.options.sort.length != 0 && self.layout == LAYOUT_TABLE) {
            params.body.sort = self.options.sort;
        } else if (params.body.sort) {
            delete params.body.sort;
        }
        return params;
    }

    Manager.prototype.create_Params = function (method, option, param) {
        var params, self = this;

        self.setOtherParams(self.otherParams, self.dataParams);

        if (typeof option != "undefined" && typeof param != "undefined" && option != null) {
            self.dataParams[param] = option;
        }

        if (self.objectsPerPage) {
            self.dataParams.criteria = self.criteria;
            self.dataParams.page = self.page;
            self.dataParams.pagesize = self.objectsPerPage;
        }            
        
        var urlParams = self.get_urlParams();
        
        if (method == 'get' && urlParams) {
            for (var key in urlParams) {
                if (urlParams.hasOwnProperty(key)) {
                    self.dataParams[key] = urlParams[key];
                }
            }
            params = self.dataParams;
        } else {
            params = { url: urlParams, urltype: 'obj', body: self.dataParams }

            // sorting
            params = self.handle_ParamsSorting(params);
        }

        (params.body || {}).isCurrent = self.isOnlyCurrent;

        if (self.moduleId) {
            self.dataConfigParams = {
                headers: { moduleId: self.moduleId }
            }
        }

        // add global search as cusom filter for files
        if (self.hasFilters && (self.customFilters || []).length) {
            for (var i = 0; i < self.customFilters.length; i++) {
                if (typeof self.customFilters[i].value != 'undefined') params.url[self.customFilters[i].key] = self.customFilters[i].value;
                if (typeof self.customFilters[i].value != 'undefined') params.body[self.customFilters[i].key] = self.customFilters[i].value;
            }
        }

        return params;
    }

    Manager.prototype.get_PresetsData = function (option, param) {
        var p = $q.defer();
        var self = this;

        var option = typeof option != "undefined" ? option : null;
        var param = typeof param != "undefined" ? param : null;

        if (self.presetsDataURL) {
            self.rows = [];
            self.loading = true;

            var method = (typeof self.presetsDataURL.method == 'undefined') ? 'get' : self.presetsDataURL.method;
            var params = self.create_Params(method, option, param);

            self[method](self.presetsDataURL.toString(), params, self.dataConfigParams).then(function (r) {

                self.getFormulasValues(option, param);

                if (self.dataWrapper == null) {
                    self.rows = r
                } else if (typeof self.dataWrapper == 'string') {
                    self.rows = r[self.dataWrapper];
                } else {
                    for (var i = 0; i < self.dataWrapper.length; i++) {
                        if (r[self.dataWrapper[i]] && r[self.dataWrapper[i]].length) self.rows = self.rows.concat(r[self.dataWrapper[i]]);
                    }
                }
                // creating nested columns
                if (self.nestedColumns.length) {
                    for (var i = 0; i < self.rows.length; i++) {
                        for (var j = 0; j < self.nestedColumns.length; j++) {
                            self.rows[i][self.nestedColumns[j].name] = self.getNestedValue(self.nestedColumns[j].name, self.rows[i]);
                        }
                    }
                }
                self.records = r[self.recordsWrapper] || self.rows.length;

                // hack for decode
                if (self.rows.length) {
                    self.rows = self.decodeRows(self.rows);
                }

                p.resolve();
            }).catch(function (e) {
                console.error(e);
                Message.dberror(e);
                p.reject(e);
            }).finally(function () {
                self.loading = false;
            });
        }

        else { Message.error("The search URL is not valid"); p.reject(); }

        return p.promise;
    }

    Manager.prototype.set_Sort = function (column) {
        if (column.noSort) {
            return true;
        }
        
        var self = this, column = column || {};
        var colToSortBy = column.replacementFor;
        // 0 asc 1 desc
        if (self.options.sort.length && self.options.sort[0].field == colToSortBy) {
            self.options.sort[0].order = self.options.sort[0].order == 0 ? 1 : 0;

        } else {
            self.options.sort = [{ field: colToSortBy, order: 1 }];
        }
        self.loadPage();

        return;
    }

    Manager.prototype.getNestedValue = function (str, obj) {
        var self = this;
        var str = str || '';
        try {
            return new Function('_', 'return _.' + str)(obj);
        } catch (e) {
            return null;
        }
    }

    Manager.prototype.selectedRows = function () {
        var rows = [];
        var self = this;

        if (self.rows && self.rows.length) {
            try {
                for (var i = 0; i < self.rows.length; i++) {
                    if (self.rows[i].selected) {
                        rows.push(self.rows[i]);
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }

        return rows;
    }

    Manager.prototype.clearSelectedRows = function () {
        var self = this;
        if (self.rows && self.rows.length) {
            try {
                for (var i = 0; i < self.rows.length; i++) {
                    if (self.rows[i].selected) {
                        self.rows[i].selected = !self.rows[i].selected;
                    }
                }
            } catch (e) {
                console.error(e);
            }
        }
    }

    Manager.prototype.openImportEntitiesDialog = function (ev) {
        var self = this;

        $mdDialog.show({
            locals: { moduleId: self.moduleId, moduleCode: self.moduleCode, importOptions: null },
            controller: 'importEntitiesController',
            templateUrl: '/ng/controllers/files/importEntities.html',
            parent: angular.element(document.body),
            targetEvent: ev,
            fullscreen: true,
            focusOnOpen: false,
            multiple: false,
            escapeToClose: false,
            clickOutsideToClose: false
        })
            .then(function () {
                self.goToPage(1);
            }, function () {
                // cancel pressed
            });

    }

    Manager.prototype.setObjectDefaults = function (obj) {
        var self = this;

        // adding properties if any
        if (self.ownProperties) {
            for (var key in obj) {
                if (key != 'options' && key != 'actions' && key != 'leftActions') {
                    self[key] = obj[key];
                }
            }
        }
        
        self.screenType = self.getScreenTypeFromLayout(self.layout);

        // adding options if any
        if (obj.options) {
            try {
                for (var key in obj.options) {
                    if (obj.options.hasOwnProperty(key)) {
                        self.options[key] = obj.options[key];
                    }
                }
            } catch (e) {
                console.warning(e);
            }
        }

        // adding actions if any
        self.set_Actions(obj.actions);

        if (obj.leftActions && obj.leftActions.length) {
            self.leftActions = [];
            for (var i = 0; i < obj.leftActions.length; i++) {
                self.addAction(obj.leftActions[i], self.leftActions);
                if (self.leftActions[i].isGroup == true) {
                    for (var j = 0; j < self.leftActions[i].actions.length; j++) {
                        self.leftActions[i].actions[j].conditionOnTop = (typeof self.leftActions[i].actions[j].conditionOnTop != 'function') ?
                            function () { return true; } : self.leftActions[i].actions[j].conditionOnTop;
                        self.setActionProperties(self.leftActions[i].actions[j]);
                    }
                }
            }
        }
        // console.log("manager actions info", self.actions)
        return;
    }

    Manager.prototype.set_Actions = function (actions) {
        var self = this;
        if (actions && actions.length) {
            self.actions = [];
            for (var i = 0; i < actions.length; i++) {
                self.addAction(actions[i], self.actions);
                if (self.actions[i].isGroup == true) {
                    for (var j = 0; j < self.actions[i].actions.length; j++) {
                        self.actions[i].actions[j].conditionOnTop = (typeof self.actions[i].actions[j].conditionOnTop != 'function') ?
                            function () { return true; } : self.actions[i].actions[j].conditionOnTop;
                        self.setActionProperties(self.actions[i].actions[j]);
                    }
                }
            }
        }
    }

    Manager.prototype.setActionProperties = function (a) {
        if (typeof a.setProperties != "undefined") {
            for (var key in a.setProperties) {
                if (a.setProperties.hasOwnProperty(key)) {
                    if (typeof a[key] == "undefined") a[key] = a.setProperties[key];
                }
            }
        }
    }

    Manager.prototype.addAction = function (a, actionsArray) {
        var a = a || {};
        var self = this;

        self.setActionProperties(a);
        a.isFileType = a.isFileType || false;

        if (typeof a.displayOnRow == "undefined" || a.displayOnRow) {
            a.displayOnRow = true;
        }

        if (typeof a.properties != "undefined") {
            for (var key in a.properties) {
                if (a.properties.hasOwnProperty(key)) {
                    a[key] = a.properties[key];
                }
            }
        }

        if (!a.noLabel || a.noLabel !== true) {
            a.noLabel = false;
        }

        if (a.hasRowLabel !== false) {
            a.hasRowLabel = true;
        }

        if (!a.name) {
            // adding a name to the action
            a.name = 'New Action';
        }

        if (!a.condition) {
            a.condition = function () {
                return true;
            }
        }
        if (!a.conditionOnTop) {
            a.conditionOnTop = function () {
                return true;
            }
        }
        if (!a.setActionName) {
            a.setActionName = function (action, row) {
                return action.name;
            }
        }

        if (a.icon) {
            a.iconset = [];
            if (typeof a.icon == 'string') {
                a.iconset.push(a.icon);
            } else {
                a.iconset = a.icon;
            }
        }

        if (!a.type) {
            a.type = '';
        } else {
            a.type = 'md-raised md-' + a.type;
        }

        if (a.visible !== false) {
            a.visible = true;
        }

        if (a.alwaysOnTop !== true) {
            a.alwaysOnTop = false;
        }

        if (a.alwaysOnRow !== true) {
            a.alwaysOnRow = false;
        }

        if (a.isGroup === true) {
            a.isGroup = a.isGroup;
        }
        else a.isGroup = false;

        if (!a.click || typeof a.click !== "function") {
            a.click = function () {
                return;
            }
        }
        actionsArray.push(a);
        return;
    }

    Manager.prototype.hasRowActions = function () {
        var self = this;
        var rowActionsCount = 0;
        for (var i = 0; i < self.actions.length; i++) {
            if (self.actions[i].alwaysOnRow) {
                rowActionsCount++;
                break;
            }
        }
        return (rowActionsCount > 0);
    }

    Manager.prototype.goToPage = function (page) {
        var self = this;
        var page = parseInt(page) || 1;
        var p = $q.defer();

        if (parseInt(self.objectsPerPage) > 0 && parseInt(self.pages) > 1) {
            if (page < 1) self.page = 1;
            else if (page > self.pages) self.page = self.pages;
            else self.page = page;
        } else {
            self.page = 1;
        }

        self.loadPage().then(function () {
            p.resolve();
        }).catch(function (e) {
            console.error(e);
            Message.dberror(e);
            p.reject(e);
        });

        return p.promise;
    }

    Manager.prototype.nextPage = function () {
        return this.goToPage(this.page + 1);
    }

    Manager.prototype.previousPage = function () {
        return this.goToPage(this.page - 1);
    }

    Manager.prototype.firstPage = function () {
        return this.goToPage(1);
    }

    Manager.prototype.lastPage = function () {
        return this.goToPage(this.pages);
    }

    return Manager;
});
