import { cipo } from 'cipo';
import moment from 'moment';

cipo.factory("Contract", function ($q, Model, Form, Functions, AssignURC,  URI, UserRoleContract, Dictionaries, userService, Message) {

    var Contract = Model.extend(function (id) {
        var self = this;

        self.messages = {
            cantAddAssignments: "This contract is closed. You can't assign/unassign roles",
            addBtn: 'Assign role/user(s)',
            noPivotItems: 'All available roles are already assigned',
            noSecondaryItems: 'All available users are already assigned',
            noItems: 'All available roles and users are already assigned',
            title: 'Assigned Roles',
            pivotLabel: 'Select Role',
            secondaryLabel: 'Select User(s)'
        };
        self.therecanbeonlyone = true;
        this.type = 3;
        this.id = id || null;
        this.projects = [];
        this.companyTypes = [];
        this.statuses = [];
        this.contractors = [];
        this.countryCodes = [];
        this.shiftSchedules = [];
        this.properties = {
            id: this.id,
            projectId: null,
            workOrder: null,
            costCenter: null,
            duration: null,
            liquidatedDamages: null,
            startDate: null,
            formatId: null,
            number: null,
            name: null,
            description: null,
            orgId: null,
            cost: null,
            // start: null,
            endDate: null,
            address: null,
            city: null,
            state: null,
            zip: null,
            countryCode: null,
            statusId: null,
            deleted: false,
            scheduleId: null,
            financialScheduleId: null,
            agencyRespDays: 30,
            entities: [],
            isWorkDay: false
        };

        this.overviewForm = new Form(this.properties);
        this.form = new Form(this.properties);
        this.overviewForm.initializing = true;
        this.form.initializing = true;
        var projects = [];
        var countryCodes = [];
        var organizations = [];
        var shiftSchedules = [];
        var financialSchedules = [];

        var overview = {
            name: { label: 'Name', type: 'text', validation: { required: true, minChars: 1, maxChars: 250 } },
            number: { label: 'Contract No', type: 'text', validation: { required: true, maxChars: 16 } },
            projectId: { label: 'Project', type: 'select', options: projects, validation: { required: true } },
            costCenter: { label: 'Cost Center', type: 'text', validation: { maxChars: 80 } },
            workOrder: { label: 'Work Order', type: 'text', validation: { maxChars: 80 } },
            liquidatedDamages: { label: 'Liquidated Damages', type: 'text' },
            description: { label: 'Description', type: 'textarea', validation: { maxChars: 512 } },
            address: { label: 'Address', type: 'text', validation: { maxChars: 128 } }, // does maxChars differ in organization?!
            city: { label: 'City', type: 'text', validation: { maxChars: 64 } },
            state: { label: 'State', type: 'text', validation: { maxChars: 32 } },
            zip: { label: 'Zip Code', type: 'text', validation: { maxChars: 16, number: true } }, // does maxChars differ in organization?!
            countryCode: { label: 'Country', type: 'select', options: countryCodes, validation: { required: true } },
        }
        var details = {
            orgId: { label: 'Contractor', type: 'select', options: organizations },
            cost: { label: 'Cost', type: 'text', validation: { required: true, number: true } },
            duration: { label: 'Duration', type: 'integer', validation: { isInteger: true } },
            startDate: { label: 'Start Date', type: 'datepicker', options: { format: userService.formats.date, useCurrent: false }, validation: { required: true } },
            endDate: {
                label: 'End Date', type: 'datepicker', options: { format: userService.formats.date, useCurrent: false }, validation: { dateAfter: 'startDate', required: true }
            },
            isWorkDay: { label: 'Only working days', type: 'checkbox' },
            scheduleId: {
                label: 'Shift Schedule', type: 'select', options: shiftSchedules, validation: { required: true }
            },
            financialScheduleId: { label: 'Financial Schedule', type: 'select', options: financialSchedules, validation: { required: true } }
        }
        self.overviewForm.set_Description(overview);
        self.form.set_Description(details);
        self.overviewForm.setTemplate('grid', [
            { number: 20, name: 40, projectId: 40 },
            { workOrder: 33, costCenter: 33, liquidatedDamages: 33 },
            { description: 100 },
            { address: 50, city: 50 },
            { countryCode: 33, state: 33, zip: 33 }
        ]);

        self.form.setTemplate('grid', [
            { orgId: 50, cost: 50 },
            { scheduleId: 50, financialScheduleId: 50 },
            { startDate: 25, duration: 25, endDate: 25, isWorkDay: 25 }
        ]);
        self.overviewForm.initializing = false;
        self.form.initializing = false;

        if (self.id) {
            self.get_Data();
            self.loadingAssignments = true;
        }
            

        var all = $q.all({
            projects: Dictionaries.Projects(),
            countryCodes: Dictionaries.CountryCodes(),
            organizations: Dictionaries.Organizations(),
            shiftSchedules: Dictionaries.Schedules(),
            financialSchedules: Dictionaries.Financials()
        });

        all
            .then(function (r) {
                projects = r.projects;
                countryCodes = r.countryCodes;
                organizations = r.organizations;
                shiftSchedules = r.shiftSchedules;
                financialSchedules = r.financialSchedules;
                overview = {
                    projectId: { label: 'Project', type: 'select', options: projects, validation: { required: true } },
                    countryCode: { label: 'Country', type: 'select', options: countryCodes, validation: { required: true } },
                }
                details = {
                    orgId: { label: 'Contractor', type: 'select', options: organizations },
                    scheduleId: {
                        label: 'Shift Schedule', type: 'select', options: shiftSchedules, validation: { required: true }
                    },
                    financialScheduleId: { label: 'Financial Schedule', type: 'select', options: financialSchedules, validation: { required: true } }
                }
                self.overviewForm.set_Description(overview, true);
                self.form.set_Description(details, true);
                /*self.form.set_Description({
                    agencyRespDays: { label: 'Response Days', type: 'text', validation: { number: true } },
                });*/

                
/*
                self.form.setTemplate('grid', [
                    { number: 20, name: 40, projectId: 40 },
                    { description: 100 },
                    { orgId: 40, address: 60 },
                    { city: 30, countryCode: 30, state: 20, zip: 20 },
                    { cost: 50, agencyRespDays: 50 },
                    { scheduleId: 50, financialScheduleId: 50 },
                    { workOrder: 33, costCenter: 33, liquidatedDamages: 33 },
                    { startDate: 25, duration: 25, endDate: 25, isWorkDay: 25 },
                ]);*/
            })
            .catch(function (e) {
                console.error(e);
            })
            .finally(function () {
                

                    
                self.loadingDynRoles = true;
                self.loadingUsers = true;
                self.loadingAssignments = true;
                //self.availableUsers = true;
                //self.availableDynRoles = true;
                    
                    // self.get_dynRoles();
            });

    });

    Contract.prototype.get_Data = function () {
        var self = this;
        var p = $q.defer();
        self.form.loading = true;
        self.overviewForm.loading = true;

        if (self.properties.id) {
            self[URI.CONTRACT.DETAIL.method](URI.CONTRACT.DETAIL, { contractId: self.properties.id })
                .then(function (result) {
                    if (result) {
                        for (var key in result) {
                            if (result.hasOwnProperty(key)) {
                                if (key == 'startDate' || key == 'endDate') self.properties[key] = self.process_incomingDate(result[key]);
                                else self.properties[key] = result[key];
                            }
                        }
                        self.set_assignments();
                        if (self.properties.deleted) self.form.editMode = false;
                        if (self.properties.deleted) self.overviewForm.editMode = false;
                        self.overviewForm.set_Data(self.properties);
                        self.overviewForm.store_Data();
                        self.form.set_Data(self.properties);
                        self.form.store_Data();
                    }
                }).catch(function (e) {
                    Message.dberror(e);
                    console.error(e);
                }).finally(function () {
                    self.form.loading = false;
                    self.overviewForm.loading = false;
                    p.resolve();
                });
        } else {
            self.form.loading = false;
            self.overviewForm.loading = false;
            p.resolve();
        }

        return p.promise;
    }

    Contract.prototype.process_incomingDate = function (date) {
        if (date) {
            var momentDate = moment(date).utc();
            //momentDate.set({ h: 12, m: 00 });
            return momentDate;
        }
        else return null;
    }

    Contract.prototype.calculateTime = function (field, isDuration) {
        var self = this;

        var diffDates = self.properties.startDate.isBefore(self.properties.endDate, 'day');
        //self.form.clearErrors();
        if (isDuration && self.properties.duration && ((parseInt(self.properties.duration) != self.properties.duration) || self.properties.duration < 0)) {
            self.form.setFieldInvalid('duration', 'The duration must be a positive number');
            return;
        }
        
        if (self.properties.endDate && !diffDates) {
            self.form.setFieldInvalid('endDate', 'End Date must be after Start Date');
            return;
        }
        if (!self.form.fieldsList.endDate._isValid)
            self.form.fieldsList.endDate._clearErrors();
        
        if (self.properties.isWorkDay && !self.properties.scheduleId) {
            self.form.setFieldInvalid('scheduleId', 'This field is required');
            return;
        } 

        if (!self.form.fieldsList.duration._isValid) self.form.fieldsList.duration._clearErrors();

        

        if (field) field.isFocused = false;
        self.form.fieldsList.startDate.isFocused = false;

        if (!self.properties.isWorkDay) {
            if (!self.form.fieldsList.scheduleId._isValid) self.form.fieldsList.scheduleId._clearErrors();
            if (isDuration) self.properties.endDate = angular.copy(self.properties.startDate).add(self.properties.duration, 'days');
            else {
                self.properties.duration = moment.duration(self.properties.endDate.utc().startOf('day').diff(self.properties.startDate.utc().startOf('day'))).asDays();
            }
        }
        else {
            var data = {
                schedule_id: self.properties.scheduleId,
                isWorkDay: true,
                startDate: self.properties.startDate.format('YYYY-MM-DD'),
            }
            if (isDuration && self.properties.duration) data.duration = self.properties.duration;
            else data.endDate = self.properties.endDate.format('YYYY-MM-DD');
            
            self.calculateWorkdays(data)
                .then(function (r) {
                    if (isDuration && self.properties.duration) self.properties.endDate = self.process_incomingDate(r);
                    else self.properties.duration = r;
                })
                .catch(function () { })
                .finally(function () { })
        }

    }

    Contract.prototype.calculateWorkdays = function (data) {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.SCHEDULE.CALCULATE_DAYS_OF_SCHEDULE;
        self[dataURL.method](dataURL, { url: data, urltype: 'obj' })
            .then(function (r) {p.resolve(r)})
            .catch(function (e) {
                p.reject(e);
                Message.dberror(e);
                
            })
        return p.promise;
    }

    Contract.prototype.save = function () {
        var self = this;
        var p = $q.defer();
        self.form.loading = true;
        self.overviewForm.loading = true;
        var dataUrl = self.properties.id ? URI.CONTRACT.SAVE : URI.CONTRACT.ADD;
        self.form.validate(true);
        self.overviewForm.validate();
        if (self.form.isValid && self.overviewForm.isValid) {
            var properties = angular.copy(self.properties);
            properties.startDate = properties.startDate.format('YYYY-MM-DD');
            properties.endDate = properties.endDate ? properties.endDate.format('YYYY-MM-DD') : null;
            self[dataUrl.method](dataUrl, properties)
                .then(function (r) {
                    if (!self.properties.id) {
                        self.properties.id = r;
                        self.set_assignments();
                    }
                    p.resolve(r);
                    self.overviewForm.set_Data(self.properties);
                    self.overviewForm.store_Data();
                    self.form.set_Data(self.properties);
                    self.form.store_Data();
                })
                .catch(function (e) {
                    self.form.catch(e);
                    self.overviewForm.catch(e);
                    p.reject(e);
                })
                .finally(function () {
                    self.form.loading = false;
                    self.overviewForm.loading = false;
                });
        }
        else {
            self.form.loading = false;
            self.overviewForm.loading = false;
            p.reject();
        }
        

        return p.promise;
    }

    // role assign

    Contract.prototype.set_assignments = function () {
        var self = this;
        var p = $q.defer();
        var all = $q.all([self.get_pivot_Dict(), self.get_secondary_Dict(), self.get_assignedList()]);
        self.assignedItemsList = [];
        all
            .then(function (r) {
                
                p.resolve()
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e)
            })
            .finally(function () {
                self.loadingAssignments = false;
            })
        return p.promise;
    }
    Contract.prototype.get_pivot_Dict = function () {
        var self = this;
        var p = $q.defer();
        self.loadingPivot = true;
        self.hasPivotItems = false;
        Dictionaries.Roles(true, { contractId: self.properties.id })
            .then(function (r) {
                self.pivotList = r;
                if (r && r.length) {
                    for (var i = 0; i < r.length; i++) {
                        if (!r[i].isUsed) {
                            self.hasPivotItems = true;
                            break;
                        }
                    }
                }
            })
            .catch(function (e) {
                console.error(e);
            })
            .finally(function () {
                p.resolve();
                self.loadingPivot = false;
            });

        return p.promise;
    }
    Contract.prototype.get_secondary_Dict = function () {
        var self = this;
        var p = $q.defer();
        self.loadingSecondary = true;
        //self.loadingDynRoles = true;
        self.hasSecondaryItems = false;

        Dictionaries.Users({ contractId: self.properties.id })
            .then(function (r) {
                self.secondaryList = r;
                self.loadingSecondary = false;

                if (r && r.length) {
                    for (var i = 0; i < r.length; i++) {
                        if (!r[i].isUsed) {
                            self.hasSecondaryItems = true;
                            break;
                        }
                    }
                }
                p.resolve();

            })
            .catch(function (e) {
                p.reject(e);
            })

        return p.promise;
    }


    Contract.prototype.get_assignedList = function () {
        var self = this;
        var p = $q.defer();
        self.loadingAssignments = true;
        self.assignedItems = [];

        self[URI.CONTRACT.GET_ROLE_USERS.method](URI.CONTRACT.GET_ROLE_USERS, { contractId: self.properties.id }).then(function (result) {
            self.assignedItems = result;
        }).catch(function (e) {
            console.error(e);
        }).finally(function () {
            p.resolve();

        });

        return p.promise;
    }

    return Contract;

});
