import { Pipe, PipeTransform } from '@angular/core';
import { MeasurementSystem } from 'src/app/models/module/fields/enums/measurementSystem';

@Pipe({
  name: 'speed'
})
export class SpeedPipe implements PipeTransform {

  transform(value: number, ...args: MeasurementSystem[]): string {
    
    if(value === undefined || value === null) {
      return '';
    }

    switch(args[0]){
      case MeasurementSystem.Imperial:
        return value + ' mph';
      case MeasurementSystem.Metric:
        return value + ' km/h';
      default:
        break;
    }
  }
}
