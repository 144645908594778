import { cipo } from 'cipo';

cipo.directive("formField", function ($compile, $http, $templateCache, userService, dateService) {
    var base_template = "/ng/views/directives/system/form/fields/";
    var map_template = {
        text: 'text.html',
        number: 'number.html',
        formula: 'number.html',
        integer: 'integer.html',
        money: 'money.html',
        password: 'password.html',
        chips: 'chips.html',
        contact: 'contact.html',
        autocomplete: 'autocomplete.html',
        select: 'select.html',
        attachment: 'attachment.html',
        textarea: 'textarea.html',
        editor: 'editor.html',
        annotation: 'annotation.html',
        datepicker: 'datetime.html',
        timepicker: 'datetime.html',
        datetimepicker: 'datetime.html',
        checkbox: 'checkbox.html',
        radio: 'radio.html',
        colorpicker: 'colorpicker.html',
        submodule: 'submodule.html',
        table: "table.html",
        icons: "icons.html",
        weather: "weather.html"
    };
    var set_Template = function (template) {
        return $http.get(base_template + map_template[template], { cache: $templateCache });
    }

    return {
        restrict: "E",
        replace: true,
        scope: {
            "fielddata": "="
        },
        link: function ($scope, $el, $attrs) {
            if($scope.fielddata.type === 'text' || $scope.fielddata.type === 'textarea') {
                let textFieldLengthRestriction;
                let restriction;
                
                if($scope.fielddata.restrictions !== undefined && $scope.fielddata.restrictions !== null) {
                    restriction = $scope.fielddata.restrictions.find(x => x.restrictionValue !== undefined && x.key == 8)
                }
                
                if(restriction !== undefined && restriction !== null) {
                    textFieldLengthRestriction = restriction.restrictionValue;
                }
                $scope.fielddata.textFieldLengthRestriction = textFieldLengthRestriction;
            }
            $scope.fielddata.contractId = userService.system.userdata.contractId;
            var el = $el;
            var childScope = $scope.$new();

                if ($scope.fielddata.type && $scope.fielddata.type == 'colorpicker') {
                    $scope.colorpickerOptions = {
                        label: $scope.fielddata.label,
                        //icon: "brush",
                        default: "#808080",
                        genericPalette: false,
                        history: false,
                        hasBackdrop: false,
                        clearButton: false
                    }
                }
                $scope.currencySymbol = userService.getCurrency();
                $scope.showmanagerFn = function (fielddata) {
                    fielddata.showmanager = true;
                    fielddata._clearErrors();
                }
                
            // froala stuff
            if ($scope.fielddata.type && $scope.fielddata.type == 'editor') {
                let textFieldLengthRestriction;
                let restriction;

                let tributeOptions = {};
                let froalaOptions = {};
                
                if($scope.fielddata.restrictions !== undefined && $scope.fielddata.restrictions !== null) {
                    restriction = $scope.fielddata.restrictions.find(x => x.restrictionValue !== undefined && x.key == 8)
                }
                
                if(restriction !== undefined && restriction !== null) {
                    textFieldLengthRestriction = restriction.restrictionValue;
                }

                if (Object.prototype.toString.call($scope.fielddata.options) == '[object Object]') {
                    for (var key in $scope.fielddata.options) {
                        if ($scope.fielddata.options.hasOwnProperty(key)) {
                            froalaOptions[key] = $scope.fielddata.options[key];
                        }
                    }
                }

                if (textFieldLengthRestriction !== null && textFieldLengthRestriction !== undefined) {
                    froalaOptions.charCounterMax = textFieldLengthRestriction;
                }

                const hintsType = Object.prototype.toString.call($scope.fielddata.hints);

                if(hintsType == '[object Object]') {
                    tributeOptions.variables = $scope.fielddata.hints;
                } else if(hintsType == '[object Array]') {
                    const variables = {};
                    $scope.fielddata.hints.forEach(option => {
                        variables[option.key] = option.value;
                    });
                    tributeOptions.variables = variables;
                }

                const tagsType = Object.prototype.toString.call($scope.fielddata.hintsTagg);

                if(tagsType == '[object Object]') {
                    tributeOptions.mentions = $scope.fielddata.hintsTagg;
                } else if(tagsType == '[object Array]') {
                    const mentions = {};
                    $scope.fielddata.hintsTagg.forEach(option => {
                        mentions[option.key] = option.value;
                    });
                    tributeOptions.mentions = mentions;
                }

                if (tributeOptions != {}) {
                    $scope.tributeOptions = tributeOptions;
                }

                $scope.froalaOptions = froalaOptions;
            }

            $scope.$watch(function () {
                return $scope.fielddata.type;
            }, function (n, o) {
                if (n && map_template[n]) {
                    set_Template(n)
                        .then(function (r) {
                            if (childScope) {
                                childScope.$destroy();
                                childScope = null;
                            }

                            childScope = $scope.$new();

                            $(el).html($compile(r.data)(childScope));
                        })
                        .catch(function (e) {
                            console.error(e);
                        });
                }
            });

            $scope.weatherHasValues = function() {
                if (!$scope.fielddata._value || Array.isArray($scope.fielddata._value) && !$scope.fielddata._value) {
                    return false;
                }
                const {temperature, wind, precipitation, humidity, pressure} = $scope.fielddata._value;
                return (temperature !== undefined && temperature !== null) ||
                    (wind !== undefined && wind !== null) ||
                    (precipitation !== undefined && precipitation !== null) ||
                    (humidity !== undefined && humidity !== null) ||
                    (pressure !== undefined && pressure !== null);
            }
            if($scope.fielddata.type == 'weather') {
                $scope.weatherHasValuesProperty = $scope.weatherHasValues()
            }

            $scope.onWeatherChange = function(fielddata) {
                if (fielddata.usedOnWeather) {
                    dateService.dateUpdated({
                        value: new Date(fielddata._value),
                        fieldId: $scope.fielddata.fieldId,
                        contractId: userService.system.userdata.contractId
                    });
                }
            }

            if ($scope.fielddata.type == 'contact') {
                $scope.$watch(function () { return $scope.fielddata._value }, function (n, o) {
                    if (n != o) {
                        $scope.fielddata.searchValue = null;

                        if ($scope.fielddata.onChange) {
                            $scope.fielddata.onChange($scope.fielddata);
                        }
                    }
                }, true)
            }

            if ($scope.fielddata.type == 'icons') {
                $scope.fielddata.icons = userService.system.icons.list;
            }
        }
    }
});
