import { cipo } from 'cipo';

cipo.factory("AdjustmentH", function ($q, Model, URI, Message, Form, userService, ADJUSTMENTS) {
    var AdjustmentH = Model.extend(function (obj, accrualPeriodsDict, categoriesDict) {

        var self = this;
        self.properties = {
            id: null,                
            contractId: null,
            name: "",
            categoryId: null,
            description: null,
            comment: "",
            amount: null,
            percentage: null,
            type: obj.type,
            typeName: obj.typeName,
            class: null, // term

            end: null,
            start: null,

            willBeRetained: false,
            frequency: null,

            valueType: 1
        }

        

        self.accrualPeriodsDict = accrualPeriodsDict;
        self.categoriesDict = categoriesDict;
        self.adjustmentTypeDict = [{ key: 1, value: "Amount" }, { key: 2, value: "Percentage" }] 
        if (!obj.id) {
            if (obj.type == 1) self.properties.class = 1;
            self.createForm();
        } else {
            for (var key in obj)
                self.properties[key] = obj[key];
        }
        
    });

    AdjustmentH.prototype.createForm = function () {
        var self = this;

        self.form = new Form(self.properties);
        self.form.initializing = true;

        var form = {
            comment: { label: 'Comment', type: 'editor', validation: { required: true } },
            amount: { label: 'Adjustment Value', type: 'number', validation: { required: true, isNumber: true } },
            valueType: { label: '', type: 'radio', options: [{ key: 1, value: "Amount" }, { key: 2, value: "Percentage" },], validation: { required: true } },
            categoryId: { label: 'Adjustment Category', type: 'select', options: self.categoriesDict, validation: { required: true } },
            start: { label: 'Valid From', type: 'datepicker', options: { format: userService.formats.date }, validation: { required: true } },
            end: { label: 'Valid Through', type: 'datepicker', options: { format: userService.formats.date } },
            name: { label: 'Name', type: 'text', validation: { required: true, minChars: 1, maxChars: 255 } },
            class: { label: '', type: 'radio', options: ADJUSTMENTS.term },
            // recurrence: { label: '', type: 'radio', options: [{ key: 1, value: "One Time" }, { key: 2, value: "Recurring" }, { key: 3, value: "Accrual" },] },
            frequency: { label: 'Accrual Period', type: 'select', options: self.accrualPeriodsDict },
            description: { label: 'Description', type: 'text' },
            willBeRetained: { label: 'Retained', type: 'checkbox' },
        }

        
        // accrual deduction radio --> adj type
        // recurring / 1 time --> recurrence

        // name, value, value type, adjust type, accrualFrequencyId, recurrence,
        // 
        // recurring // class 1 !!!
        if (self.properties.type == 1) {
            self.form.set_Description(form);
            self.form.setTemplate('grid', [
                { name: 33, categoryId: 33, description: 33 },
                { amount: 25, valueType: 25, start: 25, end: 25 },
                { comment: 100 },
            ]);
        }
        // one time
        if (self.properties.type == 2) {
            form.amount.label = "Adjustment amount";
            form.amount.type = "money";
            self.form.set_Description(form);
            self.form.setTemplate('grid', [
                { name: 33, categoryId: 33, description: 33 },
                { amount: 50, class: 25, willBeRetained: 25, },
                { comment: 100 },
            ]);
        }
        // accrual
        if (self.properties.type == 3) {
            self.form.set_Description(form);
            self.form.setTemplate('grid', [
                { name: 33, categoryId: 33, description: 33 },
                { amount: 50, valueType: 25, class: 25, },
                { start: 25, end: 25, frequency: 50 },
                { comment: 100 },
            ]);
        }

        
        self.form.initializing = false;
        self.form.store_Data();
    }




    return AdjustmentH;
});
