<table
    matSort
    (matSortChange)="sortData($event)"
    mat-table
    [dataSource]="myDataSource"
    [trackBy]="trackBy"
    class="cipo-table">
    @for (column of displayedColumns; track column) {
        <ng-container [matColumnDef]="column">
            <!-- Table header;
                TODO: Rename column names / translations to correspond
                TODO: Add 'Actions' as the name of the last column
            -->
            @if (!["windSpeed", "delete"].includes(column)) {
                <th mat-header-cell *matHeaderCellDef>
                    {{ "MODULE_DEF_WEATHER.WEATHER_TABLE." + (column | uppercase) | translate }}
                </th>
            } @else {
                @if (column === "windSpeed") {
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "MODULE_DEF_WEATHER.WEATHER_TABLE.WIND_SPEED" | translate }}
                    </th>
                }
                @if (column === "delete") {
                    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                }
            }

            @if (column === "time") {
                <td mat-cell *matCellDef="let element" class="time-cell">
                    <input
                        type="time"
                        matInput
                        class="time-input"
                        [class.disable-default-styles]="!this.fielddata._editMode"
                        (change)="getWeatherDataByTime($event, element)"
                        [ngModel]="element.time"
                        [disabled]="!this.fielddata._editMode"
                        [value]="element.time" />
                </td>
            } @else if (column === "precipitation") {
                <td mat-cell *matCellDef="let element">{{ element.precipitation | number: "1.1-1" | inch }}</td>
            } @else if (column === "temperature") {
                <td mat-cell *matCellDef="let element">{{ element.temperature | degrees: 1 }}</td>
            } @else if (column === "windSpeed") {
                <td mat-cell *matCellDef="let element">{{ element.windSpeed | speed: 1 }}</td>
            } @else if (column === "delete") {
                <td mat-cell *matCellDef="let element" fxLayoutAlign="end">
                    <button
                        mat-icon-button
                        *ngIf="this.fielddata._editMode"
                        (click)="deleteRow(element)"
                        class="m-r-xl">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            } @else {
                <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
            }
        </ng-container>
    }

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<div fxLayout fxLayoutAlign="end" class="m-t-md">
    <button (click)="createTimeFieldInstance()" color="primary" mat-button *ngIf="this.fielddata._editMode">
        <mat-icon>add</mat-icon>
        {{ "MODULE_DEF_WEATHER.WEATHER_TABLE.ADD_ROW" | translate }}
    </button>
</div>
