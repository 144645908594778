import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { GenericObjectType } from '../../../models/helpers';
import { BaseRestrictions } from '../../../models/module/fields/enums';
import { SearchViewModel } from '../../../models/module/grid';
import { Permission } from '../../../models/permission';
import { DisplayFormats, TableColumn } from '../../../shared/components/data-list/table.interface';
import { HttpService } from '../../../shared/services';
import {
  ScheduledEmail,
  ScheduledEmailAction,
  ScheduledEmailActionsEnum,
  ScheduledEmailAdditionalRoles,
  ScheduledEmailModule,
  ScheduledEmailRow,
  ScheduledEmailsResponse,
  ScheduleFrequencyEnum,
} from '../scheduled-emails/interfaces';
import { DataListCommandFilterType } from './../../../shared/components/data-list/data-list-command/data-list-command.interface';

@Injectable()
export class ScheduledEmailService {
  constructor(
    private http: HttpService,
    private translate: TranslateService,
  ) {}

  getScheduledEmails(search: SearchViewModel) {
    return this.http.post<ScheduledEmailsResponse>('jobs/scheduled/all', search, {
      baseUrl: 'jobsApiUrl',
      useLoader: false,
    });
  }

  getModulesDropdownItems(minAgencyDays = 0) {
    const params = this.http.buildHttpParams({ minAgencyDays });
    return this.http.get<ScheduledEmailModule[]>(`_api/ModuleDefinition/GetNonContractModulesDict`, {
      httpOptions: { params },
      useLoader: false,
    });
  }

  getAdditionalRoles() {
    const params = this.http.buildHttpParams({ includeSystemRoles: false });
    return this.http.get<ScheduledEmailAdditionalRoles[]>('_api/role/dict', {
      useLoader: false,
      httpOptions: { params },
    });
  }

  createScheduledEmail(data: ScheduledEmail) {
    return this.http.post<ScheduledEmail>('jobs/scheduled', data, { baseUrl: 'jobsApiUrl', useLoader: false });
  }

  getPlaceholders() {
    return this.http.get<GenericObjectType>('placeholders/email/all', { baseUrl: 'jobsApiUrl', useLoader: false });
  }

  updateScheduledEmail(data: ScheduledEmail) {
    return this.http.put<ScheduledEmail>(`jobs/scheduled/${data.id}`, data, {
      baseUrl: 'jobsApiUrl',
      useLoader: false,
    });
  }

  toggleScheduled(jobId: number, state: boolean) {
    return this.http.put<ScheduledEmail>(`jobs/scheduled/${jobId}/status `, state, {
      baseUrl: 'jobsApiUrl',
      useLoader: false,
    });
  }

  deleteScheduledEmail(jobId: number) {
    return this.http.delete(`jobs/scheduled/${jobId}`, { useLoader: false, baseUrl: 'jobsApiUrl' });
  }

  getTableColumns(): TableColumn[] {
    return [
      {
        id: 0,
        name: 'jobName',
        displayName: this.translate.instant('SCHEDULED_EMAILS.dataList.columns.name'),
        width: 2,
        priority: 3,
      },
      {
        id: 1,
        name: 'description',
        displayName: this.translate.instant('SCHEDULED_EMAILS.dataList.columns.description'),
        width: 3,
        priority: 0,
      },
      {
        id: 2,
        name: 'frequency',
        displayName: this.translate.instant('SCHEDULED_EMAILS.formField.frequency.label'),
        width: 1,
        priority: 2,
      },
      {
        id: 3,
        name: 'createdOn',
        displayName: this.translate.instant('SCHEDULED_EMAILS.dataList.columns.createdOn'),
        displayFormatId: DisplayFormats.Date,
        restrictions: BaseRestrictions.DateTime,
        width: 2,
        priority: 0,
      },
      {
        id: 4,
        name: 'isEnabled',
        displayName: this.translate.instant('SCHEDULED_EMAILS.dataList.columns.active'),
        displayFormatId: DisplayFormats.YesNo,
        width: 1,
        priority: 3,
      },
    ];
  }

  getTableActions(row: ScheduledEmailRow, permissions: Permission[]): ScheduledEmailAction[] {
    return [
      permissions.includes(Permission.Update) && {
        id: ScheduledEmailActionsEnum.SEND_NOW,
        displayName: 'SCHEDULED_EMAILS.actions.sendNow',
        leftIcon: 'edit',
        condition: row => row.scheduleFrequency === ScheduleFrequencyEnum.MANUAL,
      },
      {
        id: ScheduledEmailActionsEnum.EDIT,
        displayName: permissions.includes(Permission.Update)
          ? 'SCHEDULED_EMAILS.actions.edit'
          : 'SCHEDULED_EMAILS.actions.view',
        leftIcon: permissions.includes(Permission.Update) ? 'edit' : 'description',
      },
      permissions.includes(Permission.Update) && {
        id: ScheduledEmailActionsEnum.TOGGLE,
        displayName:
          row.isEnabled === true ? 'SCHEDULED_EMAILS.actions.deactivate' : 'SCHEDULED_EMAILS.actions.activate',
        leftIcon: 'toggle_on',
      },
      permissions.includes(Permission.Delete) && {
        id: ScheduledEmailActionsEnum.DELETE,
        displayName: 'SCHEDULED_EMAILS.actions.delete',
        leftIcon: 'delete',
      },
    ].filter(a => !!a);
  }

  getFilters(): DataListCommandFilterType[] {
    return [
      {
        fieldId: 'jobName',
        name: this.translate.instant('SCHEDULED_EMAILS.dataList.columns.name'),
        operators: ['Contains', 'DoesNotContain', 'EndsWith', 'StartsWith'],
        type: 'text',
      },
      {
        fieldId: 'description',
        name: this.translate.instant('SCHEDULED_EMAILS.dataList.columns.description'),
        operators: ['Contains', 'DoesNotContain', 'EndsWith', 'StartsWith'],
        type: 'text',
      },
      {
        fieldId: 'scheduleFrequency',
        name: this.translate.instant('SCHEDULED_EMAILS.formField.frequency.label'),
        operators: ['Equal', 'NotEqual'],
        selectedOperator: 'Equal',
        values: [
          { key: '1', value: this.translate.instant('SCHEDULED_EMAILS.formField.frequency.DAILY') },
          { key: '2', value: this.translate.instant('SCHEDULED_EMAILS.formField.frequency.WEEKLY') },
          { key: '3', value: this.translate.instant('SCHEDULED_EMAILS.formField.frequency.MANUAL') },
        ],
      },
      {
        fieldId: 'createdOn',
        name: this.translate.instant('SCHEDULED_EMAILS.dataList.columns.createdOn'),
        type: 'date',
      },
      {
        fieldId: 'isEnabled',
        name: this.translate.instant('SCHEDULED_EMAILS.dataList.columns.active'),
        operators: ['Equal', 'NotEqual'],
        selectedOperator: 'Equal',
        values: [
          { key: 'true', value: this.translate.instant('general.yes') },
          { key: 'false', value: this.translate.instant('general.no') },
        ],
      },
    ];
  }
}
