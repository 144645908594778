import { DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { DegreesPipe } from '../pipes/degrees-pipe/degrees.pipe';
import { InchPipe } from '../pipes/inch-pipe/inch.pipe';
import {
  ItvDatePipe,
  ItvDateTimeCustomFormatPipe,
  ItvDateTimePipe,
  ItvDateTimeWithRestrictionsPipe,
  ItvTimePipe,
} from '../pipes/itv-date.pipe';
import { ItvNumberPipe, ItvNumberWithFormattingsPipe } from '../pipes/itv-number.pipe';
import { SpeedPipe } from '../pipes/speed-pipe/speed.pipe';
import { KeyValuePipe } from 'src/app/shared/pipes/key-value.pipe';
import { ImageSourcePipe } from '../pipes/string-pipes/image-source.pipe';

@NgModule({
  imports: [ImageSourcePipe],
  providers: [
    DecimalPipe,
    ItvNumberPipe,
    ItvNumberWithFormattingsPipe,
    ItvDateTimeWithRestrictionsPipe,
    ItvDateTimeCustomFormatPipe,
    SpeedPipe,
    DegreesPipe,
    InchPipe,
    ItvDatePipe,
    ItvTimePipe,
    ItvDateTimePipe,
    KeyValuePipe,
  ],
  declarations: [
    ItvNumberPipe,
    ItvNumberWithFormattingsPipe,
    ItvDateTimeWithRestrictionsPipe,
    ItvDateTimeCustomFormatPipe,
    SpeedPipe,
    DegreesPipe,
    InchPipe,
    ItvDatePipe,
    ItvTimePipe,
    ItvDateTimePipe,
    KeyValuePipe,
  ],
  exports: [
    ItvNumberPipe,
    ItvNumberWithFormattingsPipe,
    ItvDateTimeWithRestrictionsPipe,
    ItvDateTimeCustomFormatPipe,
    SpeedPipe,
    DegreesPipe,
    InchPipe,
    ItvDatePipe,
    ItvTimePipe,
    ItvDateTimePipe,
    KeyValuePipe,
    ImageSourcePipe,
  ],
})
export class PipesModule {}
