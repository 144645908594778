import { cipo } from 'cipo';
import moment from 'moment';
import { FROALA_TOOLBAR_SMALL } from 'src/app/shared/components/froala/common';

cipo.directive("formTableCell", function (Model, $compile, $http, $templateCache, SET_FIELDS_GRIDSTER, URI, MAX_NR_VISIBLE_SELECT_ITEMS,
    userService, $timeout) {
    var set_Template = function (template) {
        var base = "/ng/views/directives/system/form/cells/";
        var map = {
            text: 'text.html',
            number: 'number.html',
            integer: 'number.html',
            select: 'select.html',
            money: 'money.html',
            datetimepicker: 'datetime.html',
            checkbox: 'checkbox.html',
            attachment: 'attachment.html',
            radio: 'radio.html',
            editor: 'editor.html',
            textarea: 'textarea.html',
        };

        return $http.get(base + map[template], { cache: $templateCache });
    };

    return {
        restrict: "E",
        replace: true,
        scope: {
            "celldescriptiondefaults": "=",
            "row": "=",
            "celldata": "=",
            "fielddata": "=",
            "editmode": "=",
        },
        link: function ($scope, $el) {
            var el = $el;
            var childScope = $scope.$new();
    
            $scope.celldescription = angular.copy($scope.celldescriptiondefaults);
            $scope.onlyText = $scope.row === -1;
            $scope.celldescription.row = $scope.row;
            SET_FIELDS_GRIDSTER($scope.celldescription);
            
            $scope.input = {
                multiple: false,
                optionsPage: 1,
                optionsShowLoadMore: true,
            };

            var addZ = function (date) {
                var ret = date || null;
                if (date && date.indexOf && date.indexOf("Z") == -1)
                    ret = date + "Z";
                return ret;
            }

            $scope.celldescription.formattingsLookup = {};
            $scope.celldescription.restrictionsLookup = {};

            $scope.updateValue = function (v) {
                $scope.input._value = v;
                $scope.fielddata._value[$scope.row][$scope.celldescription.id] = v;
            }

            Object.defineProperty($scope.celldescription, '_decimals', {
                get: function () {
                    return $scope.celldescription.restrictionsLookup[4] == 2 ? 0 : $scope.celldescription.formattingsLookup[2];
                }
            });

            // formatting mapping
            if (($scope.celldescription.formattings || []).length) {
                for (var i = 0; i < $scope.celldescription.formattings.length; i++) {
                    // For formatting 2 that is decimals, even for 0 decimals was displaying true, so for 0 we need false
                    $scope.celldescription.formattingsLookup[$scope.celldescription.formattings[i].key] = $scope.celldescription.formattings[i].key == 2 && $scope.celldescription.formattings[i].value == 0 ? false : $scope.celldescription.formattings[i].value || true;
                }
            }

            // restrictions mapping
            if (($scope.celldescription.restrictions || []).length) {
                for (var i = 0; i < $scope.celldescription.restrictions.length; i++) {
                    $scope.celldescription.restrictionsLookup[$scope.celldescription.restrictions[i].key] = $scope.celldescription.restrictions[i].value || true;
                }
            }

            if ($scope.celldescription.typeId == 4) {
                
                if ($scope.celldescription.restrictionsLookup[5] == 3) $scope.celldata = $scope.celldata ? moment.utc((addZ($scope.celldata))) : null;
                else $scope.celldata = $scope.celldata ? moment(addZ($scope.celldata)) : null;
            }
            if ($scope.celldescription.typeId == 5 && !$scope.celldescription.hasMultipleValues) {
                $scope.celldata = $scope.celldata ? [$scope.celldata] : [];
            }

            $scope.currencySymbol = userService.getCurrency();
            // attachment field
            $scope.showmanagerFn = function (fielddata) {
                fielddata.showmanager = true;
                fielddata._clearErrors();
            }

            Object.defineProperty($scope.input, '_value', {
                get: function () { return $scope.celldata; },
                set: function (v) { $scope.celldata = v; }
            });

            if ($scope.celldescription.hasMultipleValues) {
                if ($scope.celldescription.type === "select") {
                    $scope.celldata = $scope.celldata || [];
                } else {
                    $scope.celldata = $scope.celldata || [null];
                }
            }

            // froala stuff
            if ($scope.celldescription.displayTypeId == 9) {
                var froalaOptions = {
                    placeholderText: $scope.celldescription.label,
                    ...FROALA_TOOLBAR_SMALL
                };

                if (Object.prototype.toString.call($scope.celldescription.options) == '[object Object]') {
                    for (var key in $scope.celldescription.options) {
                        if ($scope.celldescription.options.hasOwnProperty(key)) {
                            froalaOptions[key] = $scope.celldescription.options[key];
                        }
                    }
                }

                $scope.froalaOptions = froalaOptions;
            }

            $scope.input.lookupValues = {};
            if ($scope.celldescription.dataSourceValues)
                for (var i = 0; i < $scope.celldescription.dataSourceValues.length; i++)
                    $scope.input.lookupValues[$scope.celldescription.dataSourceValues[i].key] = $scope.celldescription.dataSourceValues[i].value;

            Object.defineProperty($scope.input, '_valueToString', {
                get: function () {
                    if (moment.isMoment($scope.input._value) && $scope.input.options.format != 'undefined') {
                        return $scope.input._value.format($scope.input.options.format);
                    } else if (Object.prototype.toString.call($scope.input._value) == '[object Array]') {
                        var retval = null;
                        if ($scope.input._value.length && $scope.input._value[0]) {
                            retval = [];
                            for (var i = 0; i < this._value.length; i++) {
                                retval.push($scope.input.lookupValues[$scope.input._value[i]] ? $scope.input.lookupValues[$scope.input._value[i]] : $scope.input._value[i]);
                            }
                        }
                        return retval ? retval.join(', ') : '-';
                    } else if ($scope.input._value && $scope.input.options && $scope.input.options.length && !$scope.editmode) {
                        var option = $scope.input.options.find(o => o.key == $scope.input._value);
                        return option ? option.value : '-';
                    } else if ($scope.celldescription.typeId == 1 && $scope.celldescription.displayTypeId == 2) {
                        return ($scope.input._value || '').replaceAll('\n', '<br/>');
                    } else {
                        return $scope.input.lookupValues[$scope.input._value] ? $scope.input.lookupValues[$scope.input._value] : $scope.input._value;
                    }
                }
            });

            if ($scope.celldescription.type && $scope.celldescription.type === 'money') {
                $scope.currencySymbol = userService.getCurrency();
            }

            if ($scope.celldescription.type && $scope.celldescription.type === 'colorpicker') {
                $scope.colorpickerOptions = {
                    label: $scope.celldescription.label || "",
                    //icon: "brush",
                    default: "#808080",
                    genericPalette: false,
                    history: false,
                    hasBackdrop: false,
                    clearButton: false
                };
            }

            $scope.$watch(function () {
                return $scope.celldescription.type;
            }, function (n, o) {
                if (typeof n !== 'undefined') {
                    $scope.template = n;
                    set_Template($scope.onlyText ? "text" : n)
                        .then(function (r) {
                            if (childScope) {
                                childScope.$destroy();
                                childScope = null;
                            }

                            childScope = $scope.$new();

                            $(el).html($compile(r.data)(childScope));
                        })
                        .catch(function (e) {
                            console.error(e);
                        });
                }
            });

            $scope.celldescription.errorMsg = "";
            $scope.celldescription._isValid = true;

            // ?
            $scope.isError = function (isInputOnly = false, blur = null) {
                var isFieldError = isInputOnly
                    ? true
                    : !$scope.fielddata._isValid;

                var isInputError = !$scope.input._value
                    || !$scope.input._valueToString;

                return isFieldError && isInputError;
            }

            $scope.emptyDate = false;
            $scope.emptyMomentCheck = function(value) {
                $scope.emptyDate = typeof (value) == 'string' && !value.trim().length;
            }

            // new stuff

            // datetime parsing
            if ($scope.celldescription.type == "datetimepicker") {

                if ($scope.celldescription.restrictionsLookup[5] == 3)
                    $scope.input.options = { format: userService.formats.date };
                else if ($scope.celldescription.restrictionsLookup[5] == 5)
                    $scope.input.options = { format: userService.formats.time };
                else $scope.input.options = { format: userService.formats.datetime };
            }

            $scope.input._clearValue = function () {
                $timeout(function () {
                    // console.error('clear errors');
                    if ($scope.celldescription.type != 'select') {
                        $scope.input._value = null;
                        if ($scope.celldescription.type == 'datetimepicker') {
                            set_Template("datetimepicker")
                                .then(function (r) {
                                    if (childScope) {
                                        childScope.$destroy();
                                        childScope = null;
                                    }

                                    childScope = $scope.$new();

                                    $(el).html($compile(r.data)(childScope));
                                })
                                .catch(function (e) {
                                    console.error(e);
                                });
                        }
                    }
                    else $scope.input._value = [];

                    if ($scope.celldescription.dataSourceId) {
                        $scope.input._getDataSources();
                    }
                }, 0);
                

            };

            $scope.showmanagerFn = function (fielddata) {
                fielddata.showmanager = true;
            }

            // multiple
            $scope.celldescription._multipleFieldErrors = [];
            $scope.celldescription._multipleFieldFocus = [];

            // multiple checkbox
            $scope.input.toggle = function (itemId) {
                var idx = $scope.input._value.indexOf(itemId);
                if (idx > -1) {
                    $scope.input._value.splice(idx, 1);
                }
                else {
                    $scope.input._value.push(itemId);
                }
            };

            $scope.input.exists = function (itemId) {
                return $scope.input._value.indexOf(itemId) > -1;
            };

            // multiple field functions                

            $scope.input._multipleFieldOnKeyUp = function ($event, $index) {
                // console.error("code key up", $event.keyCode);
                // if tab
                if ($event.keyCode == 9) {
                    $event.preventDefault();

                    if ($index == $scope.input._value.length - 1) $scope.input._multipleField_setValue();
                    else {
                        $scope.celldescription._multipleFieldFocus[$index] = false;
                        $scope.celldescription._multipleFieldFocus[$index + 1] = true;
                    }

                }
            }
            $scope.input._multipleFieldOnKeyDown = function ($event) {
                // if tab
                if ($event.keyCode == 9) {
                    $event.preventDefault();
                }
                // console.error("code key down", $event.keyCode);
            }

            $scope.input._multipleFieldInit = function ($index) {
                // set the errors and focus arrays if not set yet
                if ($scope.celldescription.hasMultipleValues && $scope.input._value.length) {
                    if ($scope.input._value.length != $scope.celldescription._multipleFieldErrors.length) {
                        $scope.celldescription._multipleFieldErrors = [];
                        for (var i = 0; i < $scope.input._value.length; i++) {
                            $scope.celldescription._multipleFieldErrors.push("");
                        }
                    }
                    if ($scope.input._value.length != $scope.celldescription._multipleFieldFocus.length) {
                        $scope.celldescription._multipleFieldFocus = [];
                        for (var i = 0; i < $scope.input._value.length; i++) {
                            $scope.celldescription._multipleFieldFocus.push(false);
                        }
                    }

                }
                $scope.celldescription._multipleFieldFocus[$index] = true;
                $scope.celldescription._multipleFieldErrors[$index] = '';
            }


            $scope.input._multipleField_setValue = function ($event, index) {
                
                var isAdd = typeof (index) != 'undefined' ? false : true;

                var currentIndex = !isAdd ? index : $scope.input._value.length - 1;

                if (currentIndex === -1 || $scope.input._value[currentIndex] != null && typeof $scope.input._value[currentIndex] != 'undefined') {
                    // if ($event) $event.target.blur();
                    if (isAdd) {
                        $scope.celldescription._multipleFieldFocus[currentIndex] = false;
                        $scope.input._value.push(null);
                        $scope.celldescription._multipleFieldErrors.push("");
                        $scope.celldescription._multipleFieldFocus.push(true);

                    } else $scope.celldescription._multipleFieldErrors[currentIndex] = "";
                } else {
                    if (isAdd) {
                        $scope.celldescription._multipleFieldErrors[currentIndex] = "Please add a value";
                        $scope.celldescription._multipleFieldFocus[currentIndex] = false;
                        $timeout(function () {
                            $scope.celldescription._multipleFieldErrors[currentIndex] = "";
                            $scope.celldescription._multipleFieldFocus[currentIndex] = true;
                        }, 1000);
                        if ($event) $event.target.focus();
                    }
                    else {
                        $scope.celldescription._multipleFieldErrors[currentIndex] = null;
                        if (index !== 0 || $scope.input._value.length > 1) $scope.input._multipleField_remove(index);
                        else if ($event) $event.target.blur();
                    }
                }
            }

            $scope.input._multipleField_remove = function (index) {
                if (index == 0 && $scope.input._value.length == 1) {
                    $scope.input._value[0] = null;
                } else {
                    $scope.input._value.splice(index, 1);
                    $scope.celldescription._multipleFieldErrors.splice(index, 1);
                }

            };

            // end multiple

            // dynamic datasources

            $scope.input.changeOptionsPage = function (page, searchTerm) {
                $scope.input.optionsPage = page;
                $scope.input._dataSourceSearch(undefined, searchTerm);
            }

            $scope.input._dataSourceSearch = function (event, searchTerm) {
                var field = $scope.input;
                event && event.stopPropagation();
                if (event && event.keyCode != 13) {
                    if (field.timeout) $timeout.cancel(field.timeout);
                    field.timeout = $timeout(function () {
                        field._getDataSources(searchTerm);
                        $timeout.cancel(field.timeout);
                    }, 1000);
                } else {
                    if (field.timeout) $timeout.cancel(field.timeout);
                    field._getDataSources(searchTerm);
                }
            }

            $scope.input._getDataSources = function (searchTerm) {
                var field = $scope.input;
                field.loading = true;
                field.currentValue = (field._value || "").toString();

                var value = field._value && Object.prototype.toString.call(field._value) != '[object Array]' ? [field._value] : field._value;

                var urlData = $scope.celldescription.relationId ? URI.FIELD_DEFINITION.DATASOURCE_RELATION : URI.FIELD_DEFINITION.DATASOURCE_LIST;
                var bodyParams = {
                    selectedIds: value || [],
                    search: {
                        criteria: searchTerm || "",
                        isCurrent: false, 
                        page: field.optionsPage, 
                        pagesize: MAX_NR_VISIBLE_SELECT_ITEMS
                    }
                }

                if ($scope.celldescription.relationId) {
                    bodyParams.relationId = field.relationId;
                    bodyParams.contractId = userService.system.userdata.contractId;
                    bodyParams.fieldId = field.fieldId;
                } else {
                    bodyParams.id = $scope.celldescription.dataSourceId;
                    bodyParams.contractId = userService.system.userdata.contractId;
                }

                Model[urlData.method](urlData, { body: bodyParams },
                    { headers: { moduleId: userService.system.selectedModuleId } })
                    .then(function (r) {
                        //console.error("select", field, r);
                        field.isLoaded = true;
                        $timeout(function () {
                            // field.options = [];
                            field.focusSearch = true;
                            var ret = parseDatasource(((r || {}).data || []));
                            if (field.optionsPage > 1) {
                                field.options = [...field.options, ...ret.options];
                                field._lookupOptions = {...field._lookupOptions, ...ret.lookup};
                            }
                            else {
                                field.options = ret.options;
                                field._lookupOptions = ret.lookup;
                            }
                            field.optionsShowLoadMore = (r || {}).records > 0 && ((r || {}).records || 0) > (field.options || []).length;
                        }, 0);

                }).catch(function (e) {
                    Message.dberror(e);
                }).finally(function () {
                    $timeout(function () {
                        field.loading = false;

                        $timeout(function () {
                            field.focusSearch = false;

                        }, 300);
                    }, 1);
                })
            }

            var addCurrentValueIfNotExists = function () {
                if (!$scope.fielddata._value || $scope.fielddata._value.length == 0 || !$scope.fielddata._value[$scope.row])
                    return;

                var currentValue = $scope.fielddata._value[$scope.row][$scope.celldescription.id + "_dataSource"];
                if (!currentValue || !currentValue.length) {
                    // exit if no current values
                    return;
                }
                
                var currentValueItem = currentValue[0];
                var currentValueOption = $scope.input.options.find(o => o.key == currentValueItem.key);
                if (currentValueOption) {
                    // current value exists in the options
                    //console.log('For value', $scope.input.currentValue, 'I found', currentValueItem.value, 'No action', $scope.input.options);
                    return;
                }
                
                //console.log('For value', $scope.input.currentValue, 'I found', currentValueItem.value, 'Will be added', $scope.input.options);
                // add the current value to the options
                var valueToInsert = {
                    key: currentValueItem.key,
                    order: 0,
                    value: currentValueItem.value
                };
                $scope.input.options = [valueToInsert, ...$scope.input.options];
            }

            if ($scope.celldescription.type == "radio" || $scope.celldescription.type == "select") {
                $scope.input.options = angular.copy($scope.celldescription.columnDatasource);
                $scope.input.currentValue = ($scope.input._value||"").toString();
                addCurrentValueIfNotExists();
            }

            $scope.input._selectOpen = function () {
                var field = $scope.input;
                if (($scope.celldescription.dataSourceId || $scope.celldescription.relationId)) {
                    field._getDataSources();
                } else {
                    $timeout(function () {
                        field.focusSearch = true;
                        $timeout(function () {
                            field.focusSearch = false;
                        }, 300);
                    }, 300);
                }
            }

            var parseDatasource = function (list) {
                var ret = {
                    options: [], lookup: {}
                }
                for (var i = 0; i < list.length; i++) {
                    ret.options.push(list[i]);
                    ret.lookup[list[i].key] = list[i];
                }
                return ret;
            }
        }
    }
});
