import { cipo } from 'cipo';

cipo.factory("PPItem", function (Model, Form, $q, URI, userService, WorkflowScreen) {
    var PPItem = Model.extend(function (obj, parentId, isCO, pp) {
        var self = this;
        self.contractId = userService.system.userdata.contractId;
        self.parentId = parentId || null;
        self.properties = {};
        self.isCO = isCO;
        self.pp = pp;
        if (obj) {
            for (var key in obj) {
                if (obj.hasOwnProperty(key)) {
                    self.properties[key] = obj[key];
                    self["_" + key] = obj[key];
                }
            }
        }
        var percentageLeft = self.properties.total_previous_payment_percentage ? 100 - parseInt(self.properties.total_previous_payment_percentage) : 100;
        self.form = new Form(self.properties);
        self.amountForm = new Form(self.properties);
        var form = {};
        var amountForm = {};
        self.currencySymbol = userService.getCurrency();
        
        // calls params
        self.params = {
            progressPaymentEntityInstanceId: self.parentId,
            contractId: self.contractId
        };
        if (!self.isCO) self.params.sovItemEntityInstanceId = self.properties.sov_item_entity_instance_id;
        else self.params.changeOrderEntityInstanceId = self.properties.co_entity_instance_id;

        if (!isCO) {
            self._sov_item_amount = self.formatMoney(self._sov_item_amount);
            self._sov_item_total = self.formatMoney(self._sov_item_total);
            self._ppi_payment_amount = self.formatMoney(self._ppi_payment_amount);
            self._total_payment_percentage = (self._total_payment_percentage.toFixed(2) || '0.00') + '%';
            self._total_previous_payment_amount = self.formatMoney(self._total_previous_payment_amount);
            self._total_previous_payment_percentage = (self._total_previous_payment_percentage.toFixed(2) || '0.00') + '%';
            self._total_payment_amount = self.formatMoney(self._total_payment_amount);

            form.ppi_payment_percentage = {
                label: "", type: 'number', validation: { isNumber: true, greaterThan: 0 }
            };

            amountForm.ppi_payment_amount = {
                label: "", type: 'money', validation: { isNumber: true, greaterThan: 0 }
            };
            if (percentageLeft <= 0) {
                form.ppi_payment_percentage.editMode = false;
            }


            self.form.set_Description(form);
            self.amountForm.set_Description(amountForm);

            self.form.fieldsList.ppi_payment_percentage.onChange = function (field) {
                self.save();
            }
            self.amountForm.fieldsList.ppi_payment_amount.onChange = function (field) {
                self.save(true);
            } 
        } else {
            self._co_cost = self.formatMoney(self._co_cost);
            self._cop_payment_amount = self.formatMoney(self._cop_payment_amount);
            self._total_payment_percentage = (self._total_payment_percentage.toFixed(2) || '0.00') + "%";
            self._total_payment_amount = self.formatMoney(self._total_payment_amount);
            self._total_previous_payment_percentage = (self._total_previous_payment_percentage.toFixed(2) || '0.00') + "%";
            self._total_previous_payment_amount = self.formatMoney(self._total_previous_payment_amount);
            //self._total_payment_amount = "$" + (self._total_payment_amount || '0.00');
            form.cop_payment_percentage = {
                label: "", type: 'number', validation: { isNumber: true, greaterThan: 0 }
            };
            amountForm.cop_payment_amount = {
                label: "", type: 'money', validation: { isNumber: true, greaterThan: 0 }
            };
            if (percentageLeft <= 0) {
                form.cop_payment_percentage.editMode = false;
            }

            self.form.set_Description(form);
            self.amountForm.set_Description(amountForm);

            self.form.fieldsList.cop_payment_percentage.onChange = function (field) {
                self.save();
            }
            self.amountForm.fieldsList.cop_payment_amount.onChange = function (field) {
                self.save(true);
            } 
        }
    });
    PPItem.prototype.formatMoney = function (amount) {
        var self = this;
        if (amount) {
            var sign = amount > 0 ? self.currencySymbol : "-" + self.currencySymbol;
            var noString = Math.abs(amount).toFixed(2).toString();
            var decimal = noString.slice(-3);
            noString = noString.slice(0, -3);
            noString = noString.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            return sign + noString + decimal;

        }
        else return self.currencySymbol + "0.00";
    }
    PPItem.prototype.get_Data = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = !self.isCO ? URI.PROGRESS_PAYMENT.PPI_GET : URI.PROGRESS_PAYMENT.COP_GET;
        self[dataURL.method](dataURL, { url: self.params, urltype: 'obj' })
            .then(function (r) {
                for (var key in r) {
                    if (r.hasOwnProperty(key)) {
                        self.properties[key] = r[key];
                        self["_" + key] = r[key];
                    }
                }
                if (!self.isCO) {
                    self._sov_item_amount = self.formatMoney(self._sov_item_amount);
                    self._sov_item_total = self.formatMoney(self._sov_item_total);
                    self._ppi_payment_amount = self.formatMoney(self._ppi_payment_amount);
                    self._total_payment_percentage = (self._total_payment_percentage.toFixed(2) || '0.00') + '%';
                    self._total_previous_payment_amount = self.formatMoney(self._total_previous_payment_amount);
                    self._total_previous_payment_percentage = (self._total_previous_payment_percentage.toFixed(2) || '0.00') + '%';
                    self._total_payment_amount = self.formatMoney(self._total_payment_amount);
                } else {
                    self._co_cost = self.formatMoney(self._co_cost.toFixed(2));
                    self._cop_payment_amount = self.formatMoney(self._cop_payment_amount);
                    self._total_payment_amount = self.formatMoney(self._total_payment_amount);
                }
                self.pp.calculateTotal(self.properties.sov_item_category_id || 0);
                p.resolve();
            })
            .catch(function (e) {
                console.error(e);
                p.reject(e)
            })
            .finally(function () {
                self.form.loading = false;
                self.amountForm.loading = false;
            })


        return p.promise;
    }

    PPItem.prototype.save = function (isAmount) {
        var self = this;
        var p = $q.defer();
        var valid;
        if (isAmount) {
            self.amountForm.validate();
            valid = self.amountForm.isValid;
        } else {
            self.form.validate();
            valid = self.form.isValid;
        }
        
        if (valid) {
            var dataURL = !self.isCO ? URI.PROGRESS_PAYMENT.PPI_SAVE : URI.PROGRESS_PAYMENT.COP_SAVE;
            if (isAmount) {
                if (self.isCO) self.properties.cop_payment_percentage = null;
                else self.properties.ppi_payment_percentage = null;
                self.amountForm.loading = true;
            } else {
                if (self.isCO) self.properties.cop_payment_amount = null;
                else self.properties.ppi_payment_amount = null;
                self.form.loading = true;
            }
            
            self[dataURL.method](dataURL, { url: self.params, urltype: 'obj', body: self.properties })
                .then(function (r) {
                    self.get_Data();
                    p.resolve();
                })
                .catch(function (e) {
                    console.error(e);
                    if (isAmount) {
                        self.amountForm.loading = false;
                        self.amountForm.catch(e);
                    } else {
                        self.form.loading = false;
                        self.form.catch(e);
                    }
                    
                    p.reject(e)
                })
                .finally(function () {
                    
                })
        }
        else {
            p.reject();
        }


        return p.promise;
    }



    return PPItem;
});
