<div>
    <mat-tab-group mat-stretch-tabs="false">
        <mat-tab label="Client">
            <mat-checkbox (change)="permissionsChanged($event)">Grant preview permission</mat-checkbox>
            <p class="m-x-lg">
                The pagination & sorting are built in the table component.
                <br />
                Contains Selectable and Actions columns. noDataPlaceholder: '~'
            </p>
            <app-data-list [tablecolumns]="tableColumns"
                           [tabledata]="tableData"
                           [tableoptions]="options2"
                           (selectionchanged)="changeSelected($event)"
                           (actionselected)="actionSelected($event)"
                           (rowclicked)="clicked($event)"></app-data-list>
        </mat-tab>
        <mat-tab label="Server">
            <p class="m-x-lg">
                The pagination & sorting are implemented in the parent component.
                <br />
                noDataPlaceholder: 'x'
            </p>
            <app-data-list [tablecolumns]="tableColumns"
                           [tabledata]="tableRows"
                           [tableoptions]="options"
                           (sortchanged)="sortChanged($event)"
                           (paginationupdated)="paginationChanged($event)"></app-data-list>
        </mat-tab>
        <mat-tab label="Types">
            <app-data-list-command [filters]="filters"
                                   sidenavclass="table-sidenav"
                                   (applyfilters)="applyFilters($event)">
                <!-- example of content in the left side of the command component -->
                <div fxLayout
                     fxLayoutAlign="start center">
                    <button mat-raised-button
                            color="primary">Click</button>
                    <button mat-icon-button
                            color="warn">
                        <mat-icon>menu</mat-icon>
                    </button>
                </div>
                <app-data-list [tablecolumns]="typesTableColumns"
                               class="data-list"
                               [tabledata]="typesTableData"></app-data-list>
            </app-data-list-command>
        </mat-tab>
        <mat-tab label="Info">
            This component has the following input and output attributes:
            <ul>
                <li>
                    <i>Input()</i>
                    <b>tableColumns</b>
                    - mapped fields to correnspond to TableColumn[] interface. This includes:
                    <b>id, name, displayName, align, displayTypeId, width, priority, sortable</b>
                    <br />
                    <i class="cipo-text--warn-500">
                        Note: Column sorting must be done inside your component. The data inside table will be displayed
                        according to column array items
                    </i>
                </li>
                <li>
                    <i>Input()</i>
                    <b>tableData</b>
                    - mapped table elements, of type TableRow. This type contains some default properties (optional),
                    and custom:
                    <br />
                    <b>state_name, backgroundColor & hoverColor</b>
                    and [key: string]: unknown, for other properties
                </li>
                <li>
                    <i>Input()</i>
                    <b>tableOptions</b>
                    <br />
                    Contains some of the following options:
                    <b>selectable, actions, pagination, sort, serverPaginationSort, noDataPlaceholder</b>
                </li>
                <li>
                    <i>Output()</i>
                    <b>selectionChanged</b>
                    - Event triggered when selection has changed. Should be used along with tableOptions.selectable:
                    true
                </li>
                <li>
                    <i>Output()</i>
                    <b>actionSelected</b>
                    <br />
                    Event triggered when an action was selected. Should be used when tableOptions.actions receives an
                    array of actions.
                    <br />
                    Each action must contain at least an id and displayName. There also can be sent leftIcon and/or
                    rightIcon.
                    <br />
                    For proper alignment, if at least one action has icons (left/right/both) all actions should receive
                    those icons.
                    <br />
                    If some action don't need icons, there should be provided invalid iconNames, this way no icon will
                    be displayed (ex: leftIcon: 'call', rightIcon: 'x')
                </li>
                <li>
                    <i>Output()</i>
                    <b>sortChanged</b>
                    <br />
                    Event triggered when the sort was changed, if tableOptions.serverPaginationSort: true.
                    <br />
                    On this event, a new request that will fetch ordered data should be sent to BE.
                </li>
                <li>
                    <i>Output()</i>
                    <b>paginationUpdated</b>
                    - Same as sortChanged
                    <br />
                    If
                </li>
            </ul>
        </mat-tab>
    </mat-tab-group>
</div>