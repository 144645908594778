import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { BREAKPOINTS_MEDIA_QUERY } from '../../../../../app-breakpoints.module';
import { ScreenFieldModel } from '../../../../models/module/fields/main';
import { DataCard } from '../../../../models/module/main';
import { DataListViewAction } from '../../../../models/module/main/dataListViewAction';
import { DataCardsViewService } from '../../data-card/data-cards-view/data-cards-view.service';
import { CalendarTileDoc } from '../calendar.model';

export const CalendarDialogDocDetailPrefix: string = 'calendar-diaog-doc-detail';
export const CalendarDialogDocDetailInnerWidth: number = 600;
export const CalendarDialogDocDetailOuterWidth: number = 648;
export const CalendarDialogDocDetailInnerHeight: number = 250;
export const CalendarDialogDocDetailOuterHeight: number = 335;

@Component({
  selector: 'app-calendar-dialog-doc-detail',
  templateUrl: './calendar-dialog-doc-detail.component.html',
  styleUrls: ['./calendar-dialog-doc-detail.component.scss'],
})
export class CalendarDialogDocDetailComponent implements OnInit {
  card: DataCard;
  actionsFiltered: DataListViewAction[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      doc: CalendarTileDoc;
      screenFields: ScreenFieldModel[];
      actions: DataListViewAction[];
    },
    private dataCardsViewService: DataCardsViewService,
    private dialog: MatDialog,
    breakpointObserver: BreakpointObserver,
  ) {
    breakpointObserver.observe([BREAKPOINTS_MEDIA_QUERY.lt_md]).subscribe(_ => {
      this.minWidth = 'inherit';
      this.maxWidth = 'inherit';
    });
  }

  ngOnInit(): void {
    this.card = this.dataCardsViewService.getDataCard(this.data.screenFields, this.data.doc.ref);

    if ((this.data.actions || []).length && this.card !== undefined) {
      this.actionsFiltered = (this.data.actions || []).filter(
        a => a.alwaysOnRow === true && a.condition !== undefined && a.condition(this.card),
      );
    } else {
      this.actionsFiltered = [];
    }
  }

  @HostBinding('style.min-width') minWidth = `${CalendarDialogDocDetailInnerWidth}px`;
  @HostBinding('style.max-width') maxWidth = `${CalendarDialogDocDetailInnerWidth}px`;

  get detailContentMaxHeight(): number {
    return CalendarDialogDocDetailInnerHeight;
  }

  get datetime(): string {
    const dt = moment(this.data.doc.ref.date);
    const hasTime = dt.format('HH:mm') == '00:00';
    return dt.format(hasTime ? 'LLL' : 'LL');
  }

  actionsMenuClick($event: Event) {
    $event.stopPropagation();
  }

  actionClick(action: DataListViewAction) {
    if (action.click) {
      this.dialog.closeAll();
      action.click(this.data.doc.ref);
    }
  }

  cardViewClick() {
    const action = this.actionsFiltered.find(a => a.actionType == 'edit' || a.actionType == 'details');
    if (action) {
      this.actionClick(action);
    }
  }
}
