import { Injectable } from "@angular/core";
import { IndividualConfig, ToastrService } from "ngx-toastr";
import { CipoErrorModel, CipoErrorWithPropModel, CipoHttpErrorResponse } from "../../models/error/cipo-error.model";
import { HttpErrorResponse } from "@angular/common/http";

class CipoErrorsAndWarnings {
    errors: string[];
    warnings: string[];

    constructor() {
        this.errors = [];
        this.warnings = [];
    }
}

const NO_PROPERTY: string = '_NoProperty_';
const DEFAULT_ERROR_MESSAGE: string = 'An unidentified error has occured';

@Injectable({
    providedIn: 'root',
  })
export class NotificationService {
    config: Partial<IndividualConfig> = { timeOut: 5000, progressBar: true, enableHtml: true };
    
    constructor(private toastr: ToastrService) {
    }

    error(message: string | HttpErrorResponse) {
        if (typeof message === 'string') {
            this.toastr.error(this.formatErrorMessage(message), '', this.config);
            return;
        }

        if (message instanceof HttpErrorResponse) {
            this.handleHttpErrorResponse(message);
        }
    }

    success(message: string) {
        this.toastr.success(message, '', this.config)
    }

    private handleHttpErrorResponse(err: CipoHttpErrorResponse): void {
        let errorsAndWarnings: CipoErrorsAndWarnings = new CipoErrorsAndWarnings();
        const error = err.error.messages;

        if (Array.isArray(error) && this.instanceOfCipoErrorModel(error[0])) {
            errorsAndWarnings = this.formatCipoError(error);
        }
        else if (this.instanceOfCipoErrorModel(error)) {
            errorsAndWarnings = this.formatCipoError([error]);
        }
        else if (!Array.isArray(error) && !this.instanceOfCipoErrorModel(error) && !error.message) {
            errorsAndWarnings = this.formatCipoErrorWithProp(error);
        }
        else {
            errorsAndWarnings.errors.push(this.formatErrorMessage(err.message));
        }

        if (!errorsAndWarnings) {
            this.toastr.error(DEFAULT_ERROR_MESSAGE);
            return;
        }
        if (errorsAndWarnings.warnings.length) {
            this.toastr.warning(errorsAndWarnings.warnings.map(w => this.formatErrorMessage(w)).join('<br/>'), '', this.config);
        }
        else if (errorsAndWarnings.errors.length) {
            this.toastr.error(errorsAndWarnings.errors.map(e => this.formatErrorMessage(e)).join('<br/>'), '', this.config);
        }
    }

    private instanceOfCipoErrorModel(obj: any): obj is CipoErrorModel {
        return obj && 'code' in obj && 'type' in obj && 'message' in obj;
    }

    private formatCipoError(messages: CipoErrorModel[]): CipoErrorsAndWarnings {
        const err = new CipoErrorsAndWarnings();
        err.errors = messages.map(m => m.message || DEFAULT_ERROR_MESSAGE);
        return err;
    }

    private formatErrorMessage(msg: string) {
        return (msg || DEFAULT_ERROR_MESSAGE).replace('\n', '<br/>');
    }

    private formatCipoErrorWithProp(message: CipoErrorWithPropModel): CipoErrorsAndWarnings {
        const keys = (Object.keys(message) as Array<keyof typeof message>).reduce((accumulator, current) => {
            accumulator.push(current as string);
            return accumulator;
        }, [] as string[]);

        const messages = keys
            .map(key => 
                key == NO_PROPERTY 
                ? message[key] 
                : message[key].map(m => ({ ...m, message: `${key}: ${m.message}`}))
            )
            .reduce((a, b) => a.concat(b), []);

        const err = new CipoErrorsAndWarnings();
        err.errors = messages.filter(m => !m.type).map(m => m.message);
        err.warnings = messages.filter(m => m.type).map(m => m.message);
        return err;
    }
}