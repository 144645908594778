<div class="m-a-md"
     fxLayout="column"
     fxLayoutGap="12px">
    <app-data-list-command [filters]="filters"
                           (applyfilters)="search($event)">

        <app-data-list [tablecolumns]="tableColumns"
                       [tabledata]="tableData"
                       [loading]="isLoading"
                       class="data-list"
                       [tableoptions]="tableOptions"
                       (sortchanged)="handleSort($event)"
                       (paginationupdated)="handlePagination($event)"
                       (rowclicked)="openPopup($event)"></app-data-list>
    </app-data-list-command>
</div>