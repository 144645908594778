import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../shared/modules/material.module';
import { SharedComponentsModule } from '../../shared/modules/shared-components.module';
import { AccessLogTableComponent } from './access-log-table/access-log-table.component';
import { AccessLogTableService } from './access-log-table/access-log-table.service';
import { ActivityItemComponent } from './activity/activity-item/activity-item.component';
import { ActivityItemService } from './activity/activity-item/activity-item.service';
import { ActivityListComponent } from './activity/activity-list/activity-list.component';
import { ActivityListService } from './activity/activity-list/activity-list.service';
import { AddressAutocompleteComponent } from './address-autocomplete/address-autocomplete.component';
import { AddressService } from './address-autocomplete/services/address.service';
import { AzureMapComponent } from './azure-map/azure-map.component';
import { ModuleInstanceCalendarModule } from './calendar/calendar.module';
import { DataCardViewModule } from './data-card/data-card-view.module';
import { MultipleAddressAutocompleteComponent } from './multiple-address-autocomplete/multiple-address-autocomplete.component';
import { OpenDocumentsTableComponent } from './open-documents-table/open-documents-table.component';
import { OpenDocumentsTableService } from './open-documents-table/open-documents-table.service';
import { PdfMarkupTableComponent } from './pdf-markup-table/pdf-markup-table.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    SharedComponentsModule,
    DataCardViewModule,
    ModuleInstanceCalendarModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    TranslateModule,
    MatExpansionModule,
    MatIconModule
  ],
  declarations: [
    PdfMarkupTableComponent,
    AccessLogTableComponent,
    OpenDocumentsTableComponent,
    ActivityListComponent,
    ActivityItemComponent,
    AddressAutocompleteComponent,
    AzureMapComponent,
    MultipleAddressAutocompleteComponent,
  ],
  exports: [
    PdfMarkupTableComponent,
    AccessLogTableComponent,
    OpenDocumentsTableComponent,
    ActivityListComponent,
    ActivityItemComponent,
    AddressAutocompleteComponent,
    MultipleAddressAutocompleteComponent
  ],
  providers: [
    AccessLogTableService,
    OpenDocumentsTableService,
    ActivityListService,
    ActivityItemService,
    AddressService
  ]
})
export class ModuleInstanceModule {
  ngDoBootstrap() {
    // Required module bootstrap method
  }
}
