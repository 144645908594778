import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import { TranslateModule } from '@ngx-translate/core';
import { GridsterModule } from 'angular-gridster2';
import { SharedComponentsModule } from '../shared/modules/shared-components.module';
import { ItvNumberPipe, ItvNumberWithFormattingsPipe } from '../shared/pipes/itv-number.pipe';
import { MsalAuthService } from '../shared/services/auth.service';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from '../shared/services/msal-factory';
import { AccountMenuComponent } from './account/account-menu.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout/layout.component';
import { MenuComponent } from './menu/menu.component';
import { ItvDatePipe } from '../shared/pipes/itv-date.pipe';
import { ContextViewComponent } from './context/view/context-view.component';
import { ContextService } from './context/context.service';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    MsalModule,
    GridsterModule,
    SharedComponentsModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ContextViewComponent,
  ],
  declarations: [LayoutComponent, HeaderComponent, MenuComponent, FooterComponent, AccountMenuComponent],
  exports: [LayoutComponent, HeaderComponent, MenuComponent, FooterComponent, AccountMenuComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalAuthService,
    MsalService,
    MsalGuard,
    ItvNumberPipe,
    ItvNumberWithFormattingsPipe,
    ItvDatePipe,
    ContextService,
  ],
})
export class SystemModule {
  ngDoBootstrap() {
    // Required module bootstrap method
  }
}
