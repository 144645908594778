<div>
    <div
        fxLayout
        fxLayoutGap="12px"
        fxLayoutAlign="space-between center"
        [ngClass]="fieldData().readonly ? 'border-bottom--dashed' : 'border-bottom--solid'">
        @if (fieldData().readonly) {
            <div>
                <mat-checkbox [checked]="formControl().value" disabled></mat-checkbox>
                <label>{{ fieldData().label }}</label>
            </div>
        } @else {
            <mat-checkbox [formControl]="formControl()" class="cipo-checkbox" fxFlex>
                {{ fieldData().label }}
                @if (fieldData().required) {
                    <span [ngClass]="formControl().touched && formControl().invalid ? 'cipo-text--warn-900' : ''">
                        *
                    </span>
                }
            </mat-checkbox>
        }
        @if (fieldData().fieldDescription) {
            <mat-icon
                [title]="fieldData().fieldDescription"
                class="material-icons-outlined cursor--help"
                fxFlex="nogrow">
                info
            </mat-icon>
        }
    </div>
    @if (formControl().touched && formControl().invalid) {
        <p class="hint m-l cipo-text--warn-900">{{ "fieldErrors.required" | translate }}</p>
    } @else if (fieldData().hint && !fieldData().readonly) {
        <p class="hint m-l">{{ fieldData().hint }}</p>
    } @else {
        <div class="hint-placeholder"></div>
    }
</div>
