import { cipo } from 'cipo';

cipo.controller('EditContractController',
	function ($scope, $q, Contract, Message, $mdDialog, id, operations, module, UserRoleContract, MODAL) {
		
		$scope.contract = new Contract(id);
		// $scope.id = (typeof id != 'undefined') ? parseInt(id) : 0;
		$scope.modalInfo = MODAL;


		Object.defineProperty($scope, 'id', {
			get: function () { return $scope.contract.properties.id ? true : false }
		});
		Object.defineProperty($scope.contract, 'operations', {
			get: function () { return $scope.operations }
		});
		$scope.module = module;
		$scope.tabWarning = false;
		$scope.hightlight = false;
		$scope.message = "";

		$scope.operations = operations || {};

		if (!$scope.operations.Update && !($scope.operations.Create && $scope.id==0)) {
			$scope.contract.form.editMode = false;
		}
		$scope.editingDetails = false;
		$scope.edit = function () {
			$scope.contract.form.editMode = true;
			$scope.editingDetails = true;
		}

		$scope.save = function () {
			$scope.contract.save()
				.then(function () {
					$scope.isModified = true;
					Message.info('Contract saved successfully');
				})
				.catch(function (e) {
					if(e) console.error(e);
				})
		}

		$scope.cancel = function () {
			if ($scope.id) {
				$scope.contract.form.restore_Values();
				$scope.contract.form.editMode = false;
				$scope.editingDetails = false;
			} else {
				$scope.close(true);
			}
		}

		var onChange = function (field) {
			if (field._value && $scope.contract.properties.startDate) $scope.contract.calculateTime(null, true);
		}
		var onClose_schedule = function (field) {
			if ($scope.contract.properties.startDate && ($scope.contract.properties.endDate || $scope.contract.properties.duration) && $scope.contract.properties.isWorkDay)
				$scope.contract.calculateTime(null, true);
		}
		var onFocus = function (field) {
			field.isFocused = true;
		}
		var onBlur = function (field) {
			field.isFocused = false;
		}
		var checkBoxToggle = function (field) {
			if ($scope.contract.properties.startDate && ($scope.contract.properties.endDate || $scope.contract.properties.duration))
				$scope.contract.calculateTime(null, true);
		}

		$scope.$watch(function () { return $scope.contract.form.fieldsList }, function (n, o) {
			// console.log('watch 0', n, o, n != o);
			if ($scope.contract.form.fieldsList.duration && !$scope.contract.form.fieldsList.duration.onChange) {
				$scope.contract.form.fieldsList.duration.onChange = onChange;
			}
			if ($scope.contract.form.fieldsList.endDate && !$scope.contract.form.fieldsList.endDate.onFocus) {
				$scope.contract.form.fieldsList.endDate.onFocus = onFocus;
				//$scope.contract.form.fieldsList.endDate.onBlur = onBlur;
			}
			if ($scope.contract.form.fieldsList.startDate && !$scope.contract.form.fieldsList.startDate.onFocus) {
				$scope.contract.form.fieldsList.startDate.onFocus = onFocus;
				//$scope.contract.form.fieldsList.startDate.onBlur = onBlur;
			}
			if ($scope.contract.form.fieldsList.scheduleId && !$scope.contract.form.fieldsList.scheduleId.onClose) {
				$scope.contract.form.fieldsList.scheduleId.onClose = onClose_schedule;
			}
			if ($scope.contract.form.fieldsList.isWorkDay && !$scope.contract.form.fieldsList.isWorkDay.onChange) {
				$scope.contract.form.fieldsList.isWorkDay.onChange = checkBoxToggle;
			}
			
		});


		$scope.$watch(function () { return $scope.contract.properties.endDate }, function (n, o) {
			if (n && $scope.contract.properties.startDate && $scope.contract.form.fieldsList.endDate.isFocused) {
				if (o && n) {
					if (n.format('YYYY-MM-DD') != o.format('YYYY-MM-DD')) $scope.contract.calculateTime($scope.contract.form.fieldsList.endDate);
				} else $scope.contract.calculateTime($scope.contract.form.fieldsList.endDate);
			}
		});

		$scope.$watch(function () { return $scope.contract.properties.startDate }, function (n, o) {
			if (n) $scope.contract.form.fieldsList.startDate._clearErrors();
			if (n && ($scope.contract.properties.endDate || $scope.contract.properties.duration) && $scope.contract.form.fieldsList.startDate.isFocused) {
				if (o && n) {
					if (n.format('YYYY-MM-DD') != o.format('YYYY-MM-DD')) $scope.contract.calculateTime($scope.contract.form.fieldsList.startDate);
				} else $scope.contract.calculateTime($scope.contract.form.fieldsList.startDate);
					
			}
		});

		$scope.close = function (isWarning) {
			if ((!$scope.contract.form.dirty && !$scope.contract.overviewForm.dirty) || isWarning) {
				if ($scope.isModified) $mdDialog.hide();
				else $mdDialog.cancel();
			}
			else {
				$scope.hightlight = true;
				$scope.tabWarning = true;
				//$scope.contract.form.dirty = false;
				$scope.closingModal = true;
			}
		}

		$scope.addingRoleForm = false;

		$scope.addRole = function () {
			$scope.disabledBtn = true;
			$scope.currentRole = new UserRoleContract({ contractId: $scope.contract.properties.id }, 3, null, $scope.contract.dynRolesList, $scope.contract.usersList, true);
			$scope.addingRoleForm = true;
		}

		$scope.editRole = function (role) {
			$scope.disabledBtn = true;
			role.editMode = true;
		}

		$scope.cancelAddRole = function () {
			$scope.addingRoleForm = false;
			$scope.disabledBtn = false;
		}

		$scope.cancelEditRole = function (role) {
			role.editMode = false;
			role.deleteConfirm = false;
			$scope.disabledBtn = false;
		}

		$scope.reloadInfo = function () {
			$scope.contract.set_users();
			//$scope.contract.get_dynRoles();
			//$scope.user.get_contracts();
		}

		$scope.assignRole = function (role) {
			var currentRole = role || $scope.currentRole;
			currentRole.savingRole = true;
			currentRole.sync()
				.then(function () {
					$scope.reloadInfo();
					$scope.cancelAddRole();
				})
				.catch(function (e) {
					console.error(e);
				})
				.finally(function () {
					currentRole.savingRole = false;
				})
		}
		$scope.unassignRoleDialog = function (role) {
			role.deleteConfirm = true;
		}
		$scope.unassignRole = function (role) {
			role.deletingMsg = "Unassigning role...";
			role.deleting = true;
			role.remove()
				.then(function () {
					$scope.reloadInfo();
				})
				.catch(function (e) {
					console.error(e);
				})
				.finally(function () {
					role.deletingMsg = "";
				})
		}

		// prevent tab change if unsaved changes
		$scope.$on('tab-change', function (event, tabEvent) {
			
			if (!$scope.contract.form.dirty && !$scope.contract.overviewForm.dirty) {
				tabEvent.accept();
			} else {
				//capture the index of the tab clicked
				$scope.navToIndex = tabEvent.index;
				//// set the warning params
				$scope.hightlight = true;
				$scope.tabWarning = true;
				//$scope.disabledBtn = true;
				//prevent tab change
				tabEvent.reject();
			}
		});

		$scope.revertTabInfo = function () {
			if (!$scope.closingModal) {
				$scope.contract.form.restore_Data();
				
				$scope.tabWarning = false;
				$scope.hightlight = false;
				$scope.disabledBtn = false;
				$scope.selectedIndex = $scope.navToIndex;
			}
			else {
				$scope.close(true);
			}
		}

		$scope.disabledBtn = false;

		$scope.updateTabInfo = function () {
			$scope.hightlight = false;
			$scope.actionBtnDisabled = true;
			$scope.updateContract()
				.then(function (r) {
					$scope.tabWarning = false;
					if (!$scope.closingModal && !$scope.changingStatus) {
						$scope.selectedIndex = $scope.navToIndex;
					//} else if ($scope.changingStatus) {
					//	$scope.changeStatus();
					}
					else {
						$scope.close(true);
					}
				})
				.catch(function (e) {
					console.error(e);
				})
				.finally(function () {
					$scope.actionBtnDisabled = false;
				})
		}

		$scope.updateContract = function () {
			var p = $q.defer();
			
			$scope.message = 'Updating contract...';
			$scope.disabledBtn = true;
			$scope.contract.save()
				.then(function (r) {
					Message.info('Contract updated successfully');
					p.resolve();
				})
				.catch(function (e) {
					console.error(e);
					Message.dberror(e);
					p.reject(e);
				})
				.finally(function () {
					$scope.disabledBtn = false;
					$scope.message = "";
				})
			return p.promise;
		}

		$scope.cancelAction = function () {
			$scope.tabWarning = false;
			$scope.hightlight = false;
			$scope.disabledBtn = false;
			if ($scope.changingStatus) {
				$scope.changingStatus = false;
			}
			if ($scope.closingModal) {
				$scope.closingModal = false;
			}
		}
	});
