import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';

@Injectable()
export class LegacyRoutes {
    definition: LegacyRouteDefinition[] = [
        { code: "main", api: false, name: "main", route: "/tenantlist", folder: "system", properties: { system: true } },
        { code: "e404", api: false, name: "e404", folder: "system", error: true },
        { code: "login", api: false, name: "login", route: "/login?r", folder: "system", properties: { system: true } },
        { code: "signup", api: false, name: "signup", route: "/signup?id_token", folder: "system", properties: { system: true } },
        { code: "register", api: false, name: "register", route: "/register?i", folder: "system", properties: { system: true } },
        { code: "vinvite", api: false, name: "vinvite", route: "/vinvite?c", folder: "system", properties: { system: true } },
        { code: "recover", api: false, name: "recover", route: "/recover?t", folder: "system", properties: { system: true } },
        { code: "postLogin", api: false, name: "postLogin", route: "/postLogin/:s", folder: "admin", properties: { system: true } },

        { code: "tenants", api: true, name: "stnt", folder: "super" },

        { code: "D", api: true, name: "dashboard", folder: "core" },
        { code: "SIGN", api: false, name: "user_sign", route: "/user_sign?s", folder: "core", properties: { system: true } },
        { code: "WLC", api: false, name: "welcome", folder: "core" },
        { code: "IDP", api: false, name: "idp", folder: "core" },
        { code: "SM", api: true, name: "smartmap", folder: "core" },
        { code: "TST", api: true, name: "tenantsettings", folder: "core" },
        { code: "PY", api: true, name: "payments", folder: "core" }, //OBSOLETE
        { code: "C", api: true, name: "emails", route: "/emails?f&e", folder: "core", properties: { reloadOnSearch: false } },

        { code: "FM", api: true, name: "filemanager", folder: "files" },
        { code: "FCM", api: true, name: "filecontractmanager", route: "/filecontractmanager?f", folder: "files" },
        { code: "MCD", api: true, name: "mycipodrive", folder: "files" },
        { code: "MSOFFICE", api: true, name: "office", folder: "files" },

        { code: "CP", api: true, name: "conf", folder: "admin" },
        { code: "SCH", api: true, name: "schedules", folder: "admin" },
        { code: "FSCH", api: true, name: "financials", folder: "admin" },
        { code: "HLD", api: true, name: "holidays", folder: "admin" },
        { code: "PBI", api: true, name: "reports", folder: "admin" },
        { code: "PDFM", api: true, name: "pdfMarkup", route: "/pdfMarkup?d&f&n", folder: "admin" },
        { code: "LSTM", api: true, name: "listsmanagement", folder: "admin" },

        { code: "M", api: true, name: "modules", folder: "admin" },
        { code: "E", api: true, name: "entities", folder: "admin" },
        { code: "U", api: true, name: "ausers", route: "/ausers?sid", folder: "admin" },
        { code: "CT", api: true, name: "contracts", folder: "admin" },
        { code: "CTA", api: true, name: "externalcontacts", folder: "admin" },
        { code: "R", api: true, name: "aroles", folder: "admin" },
        { code: "O", api: true, name: "aorg", route: "/aorg?sid", folder: "admin" },
        { code: "MN", api: true, name: "amenu", folder: "admin" },
        { code: "MAPS", api: true, name: "maps", folder: "admin" },

        { code: "DYN-PP", api: true, name: "progresspayment", folder: "core", route: '/progresspayment/:contractId/:periodId/:entityInstanceId' },
        { code: "DYN-SOV", api: true, name: "sov", folder: "core", route: '/sov/:contractId/:entityInstanceId' }, 
        { code: "DYN-MOD", api: true, name: "dm", folder: 'dynamics', route: '/dm/:contractId/:id' },
        { code: "URL-MOD", api: true, name: "url", folder: 'dynamics', route: '/url/:id' },
    ];

    list: LegacyRouteDefinition[] = [];
    systemList: LegacyRouteDefinition[] = [];
    appList: LegacyRouteDefinition[] = [];
    byCode: Map<string, LegacyRouteDefinition> = new Map<string, LegacyRouteDefinition>();

    constructor() {
        this.init();
    }

    init() {
        if (!environment.production) {
            this.addDevRoutes();
        }

        for (let i = 0; i < this.definition.length; i++) {
            var r = this.definition[i];
            if (r.name) {
                r.route = r.route || ('/' + r.name);
                r.controller = r.controller || r.name;
                r.view = r.view || r.name;
                r.folder = r.folder || r.name;

                if (!r.properties) {
                    r.properties = {};
                }

                this.list.push(r);
                if (r.properties.system) {
                    this.systemList.push(r);
                }
                else {
                    this.appList.push(r);
                }
            }
        }

        for (let j = 0; j < this.list.length; j++) {
            if (this.list[j].code) {
                this.byCode.set(this.list[j].code, this.list[j]);
            }
        }
    }

    private addDevRoutes() {
        this.definition.push({ code: "DEMO", api: false, name: "demo", route: "/demo?m?&s?", folder: "admin", properties: { system: true } });
    }
}

export interface LegacyRouteDefinition {
    code: string;
    api: boolean;
    name: string;
    folder: string;
    route?: string;
    controller?: string;
    view?: string;
    error?: boolean;
    properties?: LegacyRouteDefinitionProperties;
}

export interface LegacyRouteDefinitionProperties {
    system?: boolean;
    code?: string;
    error?: boolean;
    reloadOnSearch?: boolean;
}
