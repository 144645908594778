<mat-sidenav-container autosize>
    <mat-sidenav-content>
        <div fxLayoutAlign="center center" class="cipo-bg--white p-b">
            <div fxFlex="grow">
                <ng-content></ng-content>
            </div>
            <div fxLayoutGap="16px" fxLayoutAlign="center center" (click)="$event.stopPropagation()">
                @if (!hideReload) {
                    <button
                        mat-icon-button
                        (click)="emitApplyFilters()"
                        [title]="'dataListCommand.refresh' | translate">
                        <mat-icon>refresh</mat-icon>
                    </button>
                }
                <mat-form-field class="cipo-input hide-subscript p-0">
                    <mat-label>{{ "dataListCommand.search" | translate }}</mat-label>
                    <input matInput [formControl]="searchControl" />
                    @if (searchControl.value) {
                        <button
                            matSuffix
                            [title]="'dataListCommand.clear' | translate"
                            mat-icon-button
                            (click)="clearInput()">
                            <mat-icon>close</mat-icon>
                        </button>
                    } @else {
                        <button matSuffix [title]="'dataListCommand.search' | translate" mat-icon-button>
                            <mat-icon>search</mat-icon>
                        </button>
                    }
                </mat-form-field>
                @if (filterIsClear === false) {
                    <button mat-icon-button color="warn" (click)="resetFilter()">
                        <mat-icon>filter_alt_off</mat-icon>
                    </button>
                }
                @if (!hidefilter) {
                    <button mat-icon-button (click)="sidenav.toggle()">
                        <mat-icon>filter_alt</mat-icon>
                    </button>
                }
            </div>
        </div>
        <ng-content select=".data-list"></ng-content>
    </mat-sidenav-content>

    <mat-sidenav #sidenav fixedInViewport position="end" class="sidenav" mode="side">
        <div class="full-height p-y-md" [class]="sidenavclass">
            <app-data-list-filters
                [filters]="filters"
                [resetFilter]="filterIsClear"
                (closeFilters)="sidenav.toggle()"
                (applyfilters)="applyFilters($event)"></app-data-list-filters>
        </div>
    </mat-sidenav>
</mat-sidenav-container>
