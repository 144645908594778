import { createReducer } from '@ngrx/store';
import { ContextEffects } from './context';
import { contextStateInitial } from './context/context.model';
import { contextReducers } from './context/context.reducers';
import { CoreState } from './interfaces';
import { menuReducers, menuStateInitial } from './menu';
import { userInitialState, userReducers } from './user';

export const coreStateInitial: CoreState = {
  context: contextStateInitial,
  user: userInitialState,
  menu: menuStateInitial,
};

export const CoreReducers = {
  core: createReducer(coreStateInitial, ...contextReducers, ...menuReducers, ...userReducers),
};

export const CoreEffects = [ContextEffects];
