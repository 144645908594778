<div mat-dialog-title fxLayout fxLayoutAlign="space-between center">
    <p class="m-b-0">{{ tenantSetting.name }}</p>
    <button mat-icon-button [mat-dialog-close]="undefined">
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <p class="setting-description">{{ tenantSetting.description }}</p>
    @switch (tenantSetting.type) {
        @case ("boolean") {
            <mat-slide-toggle [formControl]="valueControl">
                {{ "general.boolValue." + valueControl.value | translate }}
            </mat-slide-toggle>
        }
        @case ("select") {
            <mat-form-field class="cipo-input">
                <mat-label>{{ "general.table.value" | translate }}</mat-label>
                <mat-select [formControl]="valueControl">
                    @for (option of selectOptions; track $index) {
                        <mat-option [value]="option.key">{{ option.value }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        }
        @case ("rangePercent") {
            <mat-form-field class="cipo-input">
                <mat-label>{{ "general.table.value" | translate }}</mat-label>
                <input matInput type="number" [formControl]="valueControl" />
                <mat-hint>{{ "tenantSetting.percentHint" | translate }}</mat-hint>
            </mat-form-field>
        }
        @default {
            <mat-form-field class="cipo-input">
                <mat-label>{{ "general.table.value" | translate }}</mat-label>
                <input matInput [formControl]="valueControl" />
            </mat-form-field>
        }
    }
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="undefined">{{ "general.cancel" | translate }}</button>
    <button
        mat-button
        mat-raised-button
        color="primary"
        [disabled]="!valueControl.valid || !valueControl.dirty"
        (click)="onSave()">
        {{ "general.save" | translate }}
    </button>
</mat-dialog-actions>
