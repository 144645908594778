import { DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import {
  FormattingType,
  FormattingValueType,
  KeyValueFormattingModel,
  KeyValueRestrictionModel,
  RestrictionType,
} from '../../models/module/fields/enums';

@Pipe({ name: 'itvNumberWithFormattings' })
export class ItvNumberWithFormattingsPipe implements PipeTransform {
  constructor(private itvNumberPipe: ItvNumberPipe) {}

  transform(
    value: number | string | null | undefined,
    formattings: KeyValueFormattingModel[] = [],
    restrictions: KeyValueRestrictionModel[] = [],
    currencySymbol: string = '$',
    percentageSymbol: string = '%',
  ): string {
    const numberFormatting = (formattings || []).find(f => f.key == FormattingType.Number);
    const decimalFormatting = (formattings || []).find(f => f.key == FormattingType.Decimals);
    const showComma = !!(restrictions || []).find(f => f.key == RestrictionType.ShowThousandSeparator);
    const showCurrency = numberFormatting && numberFormatting.value == FormattingValueType.Money;
    const showPercentage = numberFormatting && numberFormatting.value == FormattingValueType.Percentage;

    let decimals = decimalFormatting ? decimalFormatting.value : 0;

    return this.itvNumberPipe.transform(
      value,
      decimals,
      showComma,
      showCurrency,
      showPercentage,
      currencySymbol,
      percentageSymbol,
    );
  }
}

@Pipe({ name: 'itvNumber' })
export class ItvNumberPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private decimalPipe: DecimalPipe,
  ) {}

  transform(
    value: number | string | null | undefined,
    decimals: number = 0,
    showComma: boolean = true,
    showCurrency: boolean = false,
    showPercentage: boolean = false,
    currencySymbol: string = '$',
    percentageSymbol: string = '%',
  ): string {
    let ret = '';

    const currencyText = showCurrency ? currencySymbol : '';
    const percentageText = showPercentage ? percentageSymbol : '';
    const commaSeparator = this.getPartValue(this.locale, 'group');
    let numberValue: number;

    if (typeof value == 'string') {
      let newValue = value.toString();

      if (newValue.includes(currencySymbol)) {
        newValue = newValue.replaceAll(currencySymbol, '');
      }
      if (newValue.includes(percentageSymbol)) {
        newValue = newValue.replaceAll(percentageSymbol, '');
      }
      if (newValue.includes(commaSeparator)) {
        newValue = newValue.replaceAll(commaSeparator, '');
      }

      // parse the number
      numberValue = parseFloat(newValue);

      // error parsing the number
      if (isNaN(numberValue)) {
        // return original number
        return value;
      }
    } else {
      numberValue = value;
    }

    if (numberValue === undefined || numberValue === null) {
      return '';
    }

    if (!showComma && !decimals) {
      ret = numberValue.toFixed(0);
    } else if (showComma && !decimals) {
      ret = this.decimalPipe.transform(numberValue, '1.0-0');
    } else if (!showComma && decimals) {
      ret = numberValue.toFixed(decimals);
    } else if (showComma && decimals) {
      ret = this.decimalPipe.transform(numberValue, `1.${decimals}-${decimals}`);
    }

    return currencyText + ret + percentageText;
  }

  private getPartValue(locale: string, separatorType: Intl.NumberFormatPartTypes): string {
    const numberWithGroupAndDecimalSeparator = 10000.1;
    return Intl.NumberFormat(locale)
      .formatToParts(numberWithGroupAndDecimalSeparator)
      .find(part => part.type === separatorType).value;
  }
}
