import { AsyncValidatorFn, FormControl, FormControlOptions, ValidatorFn } from '@angular/forms';
import { KeyValueType } from '../../../../models/common';
import { AttachmentModel, MimeTypesEnum } from '../../../../models/module/fields/main';

type CipoFieldBaseConfig = {
  type?: CipoFieldTypes;
  label?: string;
  placeholder?: string;
  hint?: string;
  fieldDescription?: string;
  readonly?: boolean;
  required?: boolean;
  prefix?: string;
  prefixIcon?: string;
  suffix?: string;
  suffixIcon?: string;
  preventClearInput?: boolean;
};

export type CipoTextConfig = CipoFieldBaseConfig & {
  maxLength?: number;
  multiple?: boolean;
  multiline?: boolean;
};

export type CipoNumberConfig = CipoFieldBaseConfig & {
  showThousandSeparator?: boolean;
  min?: number;
  max?: number;
  decimals?: number;
  multiple?: boolean;
  isCurrency?: boolean;
};

export type CipoListConfig = CipoFieldBaseConfig & {
  options?: KeyValueType[];
  columns?: number;
  multiple?: boolean;
};

export type CipoCheckboxConfig = CipoFieldBaseConfig;

export type CipoDateTimeConfig = CipoFieldBaseConfig & {
  minDate?: string;
  maxDate?: string;
  maxRange?: number;
  hour12Timer?: boolean;
  selectMode?: 'single' | 'range';
  pickerType?: 'both' | 'calendar' | 'timer';
};

export type CipoAttachmentConfig = CipoFieldBaseConfig & {
  maxFiles?: number;
  maxFileSize?: number;
  allowedMimeTypes?: MimeTypesEnum[];
  showMultipleSave?: boolean;
};

/** Union of all possible configs */
export type CipoFieldConfig = CipoTextConfig &
  CipoNumberConfig &
  CipoDateTimeConfig &
  CipoListConfig &
  CipoCheckboxConfig;

export enum CipoFieldTypes {
  Text = 1,
  Number = 2,
  DateTime = 3,
  Select = 4,
  Listbox = 5,
  Checkbox = 6,
  Attachment = 7,
}

export type CipoFieldValue = string | number | boolean | string[] | number[] | AttachmentModel[];

export class CipoControl<TValue = CipoFieldValue, TField = CipoFieldConfig> extends FormControl<TValue> {
  fieldData?: TField;

  constructor(
    formState: TValue,
    fieldData?: TField,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | FormControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    super(formState, validatorOrOpts, asyncValidator);
    this.fieldData = fieldData;
  }
}

export class CipoTextControl extends CipoControl<string | string[], CipoTextConfig> {}

export class CipoNumberControl extends CipoControl<number | number[], CipoNumberConfig> {}

export class CipoDateTimeControl extends CipoControl<string | string[], CipoDateTimeConfig> {}

export class CipoListControl extends CipoControl<string | string[], CipoListConfig> {}

export class CipoCheckboxControl extends CipoControl<boolean, CipoCheckboxConfig> {}

export class CipoAttachmentControl extends CipoControl<AttachmentModel[], CipoAttachmentConfig> {}
