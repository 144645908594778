import { cipo } from 'cipo';

cipo.directive("setlayout", function (userService) {
    return {
        restrict: "E",
        scope: {
            "data": "=",
            "values": "=?",
            "configmode": "=?",
            "maxrows": "="
        },
        templateUrl: "/ng/views/directives/setlayout.html",
        controller: function ($scope) {
            $scope.processedData = [];
            var compare = function (a, b) {
                if (a.x < b.x)
                    return -1;
                if (a.x > b.x)
                    return 1;
                return 0;
            };

            var formatMoney = function (amount) {
                if (amount) {
                    var sign = amount > 0 ? $scope.currencySymbol : "-" + $scope.currencySymbol;
                    var noString = Math.abs(amount).toFixed(2).toString();
                    var decimal = noString.slice(-3);
                    noString = noString.slice(0, -3);
                    noString = noString.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                    return sign + noString + decimal;

                }
                else return $scope.currencySymbol + "0.00";
            }

            var processData = function () {
                var processedData = [];

                if ($scope.data && $scope.data.length) {
                    var data = [];
                    for (var i = 0; i < $scope.maxrows; i++) {
                        var line = [];
                        for (var j = 0; j < $scope.data.length; j++) {
                            if ($scope.data[j].y == i) {
                                var clone = angular.copy($scope.data[j]);
                                if (clone.isMoney) {
                                    if (clone.value < 0) clone.isDanger = true;
                                    clone.value = formatMoney(clone.value);
                                }
                                line.push(clone);
                            }
                        }
                        
                        if (line.length && i != $scope.maxrows) {
                            line.sort(compare);
                            data.push(line);
                        }
                    }


                    var array = [];
                    for (var k = 0; k < data.length; k++) {

                        line = [];
                        var x = 0;
                        // array.push([]);
                        for (var l = 0; l < data[k].length; l++) {
                            if (data[k][l].x != x) {
                                line.push({ x: x, cols: data[k][l].x - x });
                            }
                            line.push(data[k][l]);
                            x = data[k][l].x + data[k][l].cols;

                        }
                        array.push(line);
                        // processedData.push(line);
                    }

                }
                return array;
            }
            $scope.currencySymbol = userService.getCurrency();

            $scope.$watch(function () {
                return $scope.data;
            },
                function (n,o) {
                    
                    $scope.processedData = processData();
                    //  console.error('processedData', processData(), n, o);
                }, true);

            
            

        }

    }
});
