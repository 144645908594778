import { Component, inject, OnInit, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { filter, switchMap } from 'rxjs';

import { CipoAttachmentComponent } from '../../../shared/components/fields/cipo-attachment/cipo-attachment.component';
import { CipoFormModule } from '../../../shared/modules/forms.module';
import { SharedComponentsModule } from '../../../shared/modules/shared-components.module';
import { AttachmentModel, FileItemModel } from '../../../models/module/fields/main';
import { AppColors, FILE_SIZE_CONVERT, PAGE_SIZE_OPTIONS } from '../../../shared/consts';
import {
  DriveTableActionEvent,
  MyDriveService,
  DriveTableActionsEnum,
  DriveTableOptions,
  DriveTableRow,
  DriveAddNewModel,
  DriveAddNewOutput,
} from '../my-drive/common';
import { FiltersToApply } from '../../../shared/components/data-list/data-list-command';
import { AddNewComponent } from './add-new/add-new.component';
import { UserService } from '../../../shared/services/user.service';
import { NotificationService } from '../../../shared/services/notification.service';

const ROOT_FOLDER_ID = -1;

@Component({
  selector: 'app-attachments-dialog',
  standalone: true,
  imports: [MatDialogModule, CipoFormModule, TranslateModule, SharedComponentsModule, CipoAttachmentComponent],
  templateUrl: './attachments-dialog.component.html',
})
export class AttachmentsDialogComponent implements OnInit {
  rootFolderId = ROOT_FOLDER_ID;
  data = inject<AttachmentModel[]>(MAT_DIALOG_DATA);
  dialog = inject(MatDialog);
  dialogRef = inject(MatDialogRef<AttachmentsDialogComponent>);
  driveService = inject(MyDriveService);
  translate = inject(TranslateService);
  notificationService = inject(NotificationService);
  userService = inject(UserService);

  attachments = signal(this.data || []);
  canSave = signal(false);
  currentFolderId = signal(ROOT_FOLDER_ID);
  parentFolderId = signal<number>(null);
  searchCriteria = signal('');

  isLoading = signal(true);
  tableColumns = this.driveService.getTableColumns();
  tableData = signal<DriveTableRow[]>(undefined);
  tableOptions: DriveTableOptions = {
    clickableRows: true,
    pagination: {
      pageSize: PAGE_SIZE_OPTIONS.default[1],
      pageSizeOptions: PAGE_SIZE_OPTIONS.default,
    },
    actions: () => this.driveService.getTableActions(),
  };

  ngOnInit() {
    this.openFolder(ROOT_FOLDER_ID);
  }

  onSave() {
    this.dialogRef.close(this.attachments());
  }

  openFolder(id: number) {
    this.isLoading.set(true);
    const selectedFiles = this.attachments().map(file => file.id);
    this.parentFolderId.set(this.currentFolderId());
    const criteria = this.searchCriteria();
    const searchParams = { id, ...(criteria ? { criteria } : {}) };
    this.driveService.searchFiles(searchParams).subscribe(files => {
      this.tableData.set(
        files.map(file => ({
          ...file,
          image: { type: file.isFolder ? 'icon' : 'image', iconKey: 'folder', class: 'cipo-text--primary-900' },
          length: Number((file.length / FILE_SIZE_CONVERT.MB).toFixed(2)),
          backgroundColor: selectedFiles.includes(file.id) ? AppColors.accent_50 : undefined,
        })),
      );
      this.currentFolderId.set(id);
      this.isLoading.set(false);
    });
  }

  addNewFolder() {
    this.dialog
      .open<AddNewComponent, DriveAddNewModel, DriveAddNewOutput>(AddNewComponent, {
        panelClass: ['cipo-dialog', 'classic'],
        data: { modalType: 'newFolder', parentId: this.currentFolderId() },
        ...this.userService.getResponsiveDialogSize().sm,
      })
      .afterClosed()
      .pipe(
        filter(output => !!output?.name),
        switchMap(({ name }) => this.driveService.createFolder({ name, parentId: this.currentFolderId() })),
      )
      .subscribe(() => {
        this.notificationService.success(this.translate.instant('fileExplorer.folderCreated'));
        this.openFolder(this.currentFolderId());
      });
  }

  uploadFile() {
    this.dialog
      .open<AddNewComponent, DriveAddNewModel, DriveAddNewOutput>(AddNewComponent, {
        panelClass: ['cipo-dialog', 'classic'],
        data: { modalType: 'newFile', parentId: this.currentFolderId() },
        ...this.userService.getResponsiveDialogSize().sm,
      })
      .afterClosed()
      .pipe(filter(output => !!output?.files))
      .subscribe(({ files }) => {
        this.attachments.update(items => [...items, ...files]);
        this.openFolder(this.currentFolderId());
      });
  }

  goBack() {
    const parents = this.tableData()[0]?.parents;
    const parentId = parents?.length >= 2 ? parents[parents.length - 2] : this.parentFolderId() || ROOT_FOLDER_ID;
    this.openFolder(parentId);
  }

  rename(file: FileItemModel) {
    const fileExtension = file.isFolder ? '' : file.name.substring(file.name.lastIndexOf('.'));
    this.dialog
      .open<AddNewComponent, DriveAddNewModel, DriveAddNewOutput>(AddNewComponent, {
        panelClass: ['cipo-dialog', 'classic'],
        data: {
          modalType: 'rename',
          parentId: file.parentId,
          name: file.isFolder ? file.name : file.name.substring(0, file.name.lastIndexOf('.')),
          mimeType: fileExtension,
        },
        ...this.userService.getResponsiveDialogSize().sm,
      })
      .afterClosed()
      .pipe(
        filter(output => !!output?.name),
        switchMap(({ name }) => {
          const params = { ...file, name: name + fileExtension };
          return file.isFolder ? this.driveService.editFolder(params) : this.driveService.renameFile(params);
        }),
      )
      .subscribe(() => {
        this.notificationService.success(
          this.translate.instant('fileExplorer.' + (file.isFolder ? 'folderRenamed' : 'fileRenamed')),
        );
        this.openFolder(this.currentFolderId());
      });
  }

  selectRow(file: DriveTableRow) {
    this.canSave.set(true);
    if (file.isFolder) {
      this.openFolder(file.id);
    } else {
      const fileIndex = this.attachments().findIndex(f => f.id === file.id);
      if (fileIndex === -1) {
        this.attachments.update(files => [...files, file]);
        this.tableData.update(rows => {
          rows.find(r => r.id === file.id).backgroundColor = AppColors.accent_50;
          return rows;
        });
      } else {
        this.attachments.update(files => files.filter(f => f.id !== file.id));
        this.tableData.update(rows => {
          rows.find(r => r.id === file.id).backgroundColor = undefined;
          return rows;
        });
      }
    }
  }

  chipClick(item: AttachmentModel) {
    if (!item.parentId || item.parentId === this.currentFolderId()) {
      return;
    }
    this.openFolder(item.parentId);
  }

  removeChip(item: AttachmentModel) {
    this.attachments.update(files => files.filter(f => f.id !== item.id));
    this.openFolder(this.currentFolderId());
  }

  searchFiles({ criteria }: FiltersToApply) {
    this.searchCriteria.set(criteria);
    this.openFolder(this.currentFolderId());
  }

  onAction(event: DriveTableActionEvent) {
    switch (event.actionId) {
      case DriveTableActionsEnum.VIEW:
        this.openFolder(event.row.id);
        break;
      case DriveTableActionsEnum.RENAME:
        this.rename(event.row);
        break;
    }
  }
}
