import { cipo } from 'cipo';

cipo.factory("PresetMod", function ($q, Model, URI, Message, Form, ModuleDictionaries, Dictionaries, WorkflowScreen) {
    var PresetMod = Model.extend(function (obj) {

            var self = this;

            self.properties = {
                id: null,
                name: "",
                description: "",
                screenId: null,
                type: null,
                moduleDocumentNumber: null,
                documentNumber: null,
                documentNoMinor: null,
                documentKey: null,
                entityId: null,
                contractIds: [],
                values: []
            }

            if (obj) {
                if (obj.documentNumber) {
                    obj.documentKey = obj.documentNumber;
                    obj.documentKey = obj.documentKey + '-' + (obj.documentNoMinor || 0);
                } else obj.documentKey = 0;

                for (var key in obj) {
                    if (obj.hasOwnProperty(key)) {
                        self.properties[key] = obj[key];
                    }
                }
            }

            if (!self.properties.id)
                self.createForm();
        });

    PresetMod.prototype.get_Data = function () {
        var self = this;
        var p = $q.defer();
        var dataURL = URI.MODULE_PRESETS.GET;

            self[dataURL.method](dataURL, { url: { id: self.properties.id }, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
                .then(function (r) {
                    for (var key in r) {
                        if (r.hasOwnProperty(key)) {
                            self.properties[key] = r[key];
                        }
                    }
                    if (!r.documentNumber) self.properties.documentKey = 0;
                    if (self.form) {
                        self.form.set_Data(self.properties);
                        self.form.store_Data(self.properties);
                    }

                    self.isValueLoaded = true;

                    p.resolve();
                })
                .catch(function (e) {
                    Message.dberror(e);
                    p.reject(e);
                })

            return p.promise;
        }

        PresetMod.prototype.createForm = function () {
            var self = this;
            self.form = new Form(self.properties);
            self.form.initializing = true;

            var calls = [ModuleDictionaries.getDicts(["moduleEntities", "contractTypes", "availableDocNumbers", "startScreens"])];

            if (self.properties.id) 
                calls.push(self.get_Data());

            var info = $q.all(calls);

            info
            .then(function (r) {

                // if (r[2]) var numbers = angular.copy(r[2]); else numbers = [];
                var numbers = angular.copy(ModuleDictionaries.dataSources.availableDocNumbers.data);

                if(!self.properties.entityId) {
                    self.properties.entityId = ModuleDictionaries.dataSources.moduleEntities.data[0].key;
                }

                if (self.properties.moduleDocumentNumber) {
                    var selfNumber = {
                        number: self.properties.documentNumber,
                        minor: self.properties.documentNoMinor,
                        key: self.properties.documentKey,
                        value: self.properties.moduleDocumentNumber
                    };
                    numbers.unshift(selfNumber);

                    ModuleDictionaries.dataSources.availableDocNumbers.lookup[self.properties.documentKey] = selfNumber;
                }

                self.form.set_Description({
                    name: { label: 'Name', type: 'text', validation: { required: true } },
                    description: { label: 'Description', type: 'textarea' },
                    screenId: { label: 'Screen', type: 'select', options: ModuleDictionaries.dataSources.startScreens.data, validation: { required: true } },
                    type: { label: 'Contract Type', type: 'select', options: ModuleDictionaries.dataSources.contractTypes.data },
                    documentKey: { label: 'Document Number', type: 'select', options: numbers, validation: { required: true } },
                });

                self.form.setTemplate('grid', [
                    { name: 50, screenId: 50 },
                    { description: 100 },
                    { type: 50, documentKey: 50 },
                ]);

                self.form.fieldsList.type._currentValue = self.properties.type;

                    self.form.initializing = false;
                    self.form.store_Data();
                })
        }

        PresetMod.prototype.save = function (isUpdate) {
            var self = this;
            var p = $q.defer();
            if (!isUpdate) {
                self.form.loading = true;
                self.form.validate();
            } else {
                self.screen.form.loading = true;
            }

            if ((self.form || {}).isValid || isUpdate) {

                var urlData = self.properties.id ? URI.MODULE_PRESETS.UPDATE : URI.MODULE_PRESETS.CREATE;

            var toSend = angular.copy(self.properties);

                if (!isUpdate && toSend.documentKey) {
                    toSend.documentNumber = ModuleDictionaries.dataSources.availableDocNumbers.lookup[self.properties.documentKey].number || null;
                    toSend.documentNoMinor = ModuleDictionaries.dataSources.availableDocNumbers.lookup[self.properties.documentKey].minor || null;
                }

                self[urlData.method](urlData, { body: toSend }, { headers: { moduleId: ModuleDictionaries.moduleInfo.moduleId } })
                    .then(function (r) {
                        Message.info('Preset saved successfully');
                        if (r) self.properties.id = r;
                        if (ModuleDictionaries.dataSources.availableDocNumbers.lookup[self.properties.documentKey]) self.properties.moduleDocumentNumber = ModuleDictionaries.dataSources.availableDocNumbers.lookup[self.properties.documentKey].value;
                        if (isUpdate) {
                            self.get_Data();
                            self.screen.form.loading = false;
                            // self.screen.form.set_Data(self.screen.properties);
                            self.screen.form.store_Data(self.screen.properties);
                        } else {
                            // self.form.set_Data(self.properties);
                            self.form.store_Data(self.properties);
                        }
                        ModuleDictionaries.get_dataSource("availableDocNumbers");

                        p.resolve();
                    })
                    .catch(function (e) {
                        console.error(e);
                        if (!isUpdate) {
                            self.form.loading = false;
                            self.form.catch(e);
                        } else {
                            self.screen.form.loading = false;
                            self.screen.form.catch(e);
                        }

                        p.reject(e);
                    })
            }
            else {
                self.form.loading = false;
                p.reject();
            }

        return p.promise;
    }

    PresetMod.prototype.update = function () {
        var self = this;
        var p = $q.defer();
        self.properties.values = angular.copy(self.screen.valuesDictionary);
        self.save(true)
            .then(function () { p.resolve(); })
            .catch(function () { p.reject(); })

            return p.promise;
        }

    PresetMod.prototype.delete = function () {
        var self = this;
        var p = $q.defer();
        self.deletingMsg = "Deleting preset...";
        self.deleting = true;
        var urlData = URI.MODULE_PRESETS.DELETE;
        self[urlData.method](urlData, { url: { id: self.properties.id }, urltype: 'obj' }, { headers: { moduleId: self.moduleId } })
            .then(function () {
                Message.info('Preset deleted successfully');
                ModuleDictionaries.get_dataSource("availableDocNumbers");
                p.resolve();
            })
            .catch(function (e) {
                Message.dberror(e);
                self.deleting = false;
                self.deletingMsg = "Failed to delete preset. Try again?";
                p.reject(e);
            })

        return p.promise;
    }

    PresetMod.prototype.init = function () {
        var self = this;
        self.screen = new WorkflowScreen();
        self.screen.isPresetConfig = true;

        var init = function () {

                ModuleDictionaries.getPresetsFields(self.properties.screenId, 0)
                    .then(function () {
                        var fields = angular.copy(ModuleDictionaries.presetsFields.fields);
                        if (self.properties.values.length) {
                            for (var i = 0; i < fields.length; i++) {
                                fields[i].value = typeof self.properties.valuesLookup[fields[i].id] != 'undefined' ? self.properties.valuesLookup[fields[i].id] : fields[i].value;
                                // dataSources
                                if (self.properties.valuesLookup[fields[i].id + '_' + 'dataSource']) fields[i].dataSources = self.properties.valuesLookup[fields[i].id + '_' + 'dataSource'];
                            }
                        }

                        self.screen.data = { fields: fields };

                        self.screen.setupForm(true);

                        self.screen.form.store_Data(self.screen.properties);
                    })
            }

            if (!self.isValueLoaded)
                self.get_Data()
                    .then(function () {
                        init();
                    })
            else init();
        }

        PresetMod.prototype.checkDirty = function () {
            var self = this;

        return self.screen.form.dirty;
    }


    return PresetMod;
});
