import { cipo } from 'cipo';
import { FROALA_TOOLBAR_SMALL } from 'src/app/shared/components/froala/common/consts'

cipo.directive("formdetail", function (MODAL, FIELDS_CONFIG) {
    return {
        restrict: "E",
        replace: true,
        scope: {
            "form": "=",
            "operations": "=",
            "hasactions": "=",
            "module": "="
        },
        templateUrl: "/ng/views/directives/tabdetails/formdetail.html",
        controller: function ($scope, ACTIONS, Message) {
            $scope.fieldsConfig = angular.copy(FIELDS_CONFIG());
            for (var i = 0; i < $scope.fieldsConfig.fieldsList.length; i++) {
                $scope.fieldsConfig.fieldsList[i].details.fieldOrigin = $scope.form.properties.isStartScreen ? 1 : 3;
                if (!$scope.form.properties.isStartScreen) $scope.fieldsConfig.fieldsList[i].details.screenId = $scope.form.properties.id;
            }

            $scope.modalInfo = MODAL;
            $scope.openMenu = function ($mdMenu, ev) {
                $mdMenu.open(ev);
            };

            // froala stuff
            var froalaOptions = {
                placeholderText: "Enter annotation content",
                ...FROALA_TOOLBAR_SMALL
            };

            $scope.froalaOptions = froalaOptions;

            var annotationToEdit;

            $scope.editAnnotation = function (item) {
                if (item) {
                    annotationToEdit = item;
                    $scope.currentAnnotation = angular.copy(item);
                } else $scope.currentAnnotation = {
                    class: "annotation",
                    type: "annotation",
                    cols: 12,
                    rows: 1,
                    minItemRows: 1,
                    minItemCols: 3,
                    maxItemRows: 1,
                    annotation: "",
                    typeId: 50,
                    displayTypeId: 1,
                    name: "annotation",
                    label: "annotation"
                };
                $('#annotationContainer').removeClass('hide');
                $('#annotationContainer').appendTo('body');
            }

            $scope.updateAnnotation = function (value) {
                $scope.currentAnnotation.annotation = value;
                if ($scope.$root && $scope.$root.$$phase != '$apply' && $scope.$root.$$phase != '$digest') {
                    $scope.$apply();
                }
            }

            $scope.cancelEditAnnotation = function () {
                $scope.currentAnnotation = null;
                $('#annotationContainer').addClass('hide');
                $('#annotationContainer').appendTo('#annotationBase');
                annotationToEdit = null;
            }

            $scope.saveAnnotation = function () {
                if (annotationToEdit) updateAnnotation();
                else addAnnotationToForm();

                $scope.cancelEditAnnotation();
            }

            var updateAnnotation = function () {
                annotationToEdit.annotation = $scope.currentAnnotation.annotation;
                annotationToEdit.iffy = true;
            }

            var addAnnotationToForm = function () {
                $scope.currentAnnotation.iffy = true;
                $scope.form.dashboard.push($scope.currentAnnotation);
            }

            var modulePerContract = false;
            try { modulePerContract = $scope.module.form.data.perContract } catch (err) { }

            $scope.entityFieldsOptions = {
                loadingFlag: 'isEntityFieldsLoaded',
                listKey: "entityFields",
                // listItemId: "fieldId",
                listItemUsed: "inUse",
                listItemUsedText: "on form",
                maxHeight: "500",
                hasSearch: true,
                iffyMessage: "Please save the form first",
                showContractFieldsCheckbox: modulePerContract,
                refreshWithContractFields: $scope.form.showContractFields,
                rowClass: function (item) {
                    //return "propField";
                    // console.log('item', item);
                    var origin = "";
                    if (item.properties.fieldOrigin == 2) origin = "propField isRelation";
                    if (item.properties.isContractSystemField === true) origin = "propField isSystemContract";
                    if (item.properties.fieldOrigin == 1) origin = "propField";

                    return origin;
                },
                actionsBeforeDefault: [
                    {
                        setProperties: ACTIONS.ADD_FIELD,
                        name: "Add field to form",
                        disabled: function (item, parent) {
                            return item.properties.isBusy ? true : false;
                        },
                        isPrimary: true,
                        condition: function (item) {
                            return !item.properties.inUse;
                        },
                        click: function (item) {
                            $scope.form.addFieldToDashboard(item, true);
                        }
                    },
                    {
                        setProperties: ACTIONS.REMOVE_FIELD,
                        nameDynamic: function (item) {
                            return item.properties.canRemove ? 'Remove field from form' : 'In use on Conditional Transitions';
                        },
                        disabled: function (item, parent) {
                            return item.properties.isBusy || !item.properties.canRemove;
                        },
                        // isSecondary: function (item) { return true; },
                        condition: function (item) {
                            return item.properties.inUse && !item.properties.isRequired;
                        },
                        click: function (item) {
                            $scope.form.removeItem(item.properties);
                        }
                    }
                ],
                editAction: {
                    setProperties: ACTIONS.DETAILS,
                    name: "Edit Details",
                    click: function (item) {
                        $scope.form.editField(item);
                    },
                    disabled: function (item, parent) {
                        return item.properties.isContractSystemField ? true : item.properties.isBusy || item.properties.iffy ? true : false;
                    },
                    condition: function (item) {
                        return true;
                        // item.properties.fieldTypeId == 20 || item.properties.fieldTypeId == 21 ? false : true;
                    }
                },
                disableDeleteCondition: function (item) { if (item.properties.iffy || item.properties.isBusy || item.properties.inUse || item.properties.fieldSystemName || item.properties.isContractSystemField) return true; else return false; },
                disableEditCondition: function (item) { if (item.properties.iffy || item.properties.isBusy || item.properties.fieldSystemName || item.properties.isContractSystemField) return true; else return false; },
                showDelete: function (item) {
                    // if (item.properties.fieldTypeId == 20 || item.properties.fieldTypeId == 21) return false; else return true;

                    if (item.properties.isContractSystemField)
                        return false;

                    return true;
                },
                title: "Fields",
                parent: $scope.form,
                // deleteCondition: function (item) { if (item.properties.anyFieldInUse || item.properties.default) return false; else return true; },
                subtitle: {
                    text: "", content: function (item) {
                        var ret = "";

                        ret += "<span style='text-transform:initial !important; font-size:10pt;'>" + item.properties.label + '</span><br /><br />';
                        ret += "<span>" + $scope.fieldsConfig.fieldTypes[item.properties.typeId] + ' ';

                        if ($scope.fieldsConfig.displayTypeDict[item.properties.displayTypeId] && $scope.fieldsConfig.displayTypeDict[item.properties.displayTypeId].name != "Text") {
                            ret += "[" + $scope.fieldsConfig.displayTypeDict[item.properties.displayTypeId].name + "] ";
                        }

                        if (item.properties.formattings.length) {
                            for (var i = 0; i < item.properties.formattings.length; i++) {
                                if (item.properties.formattings[i].value && $scope.fieldsConfig.formattingsMapping[item.properties.formattings[i].key].elements[item.properties.formattings[i].value])
                                    ret += '<span class="noteBlock fieldFormattingWrapper">'
                                        + $scope.fieldsConfig.formattingsMapping[item.properties.formattings[i].key].elements[item.properties.formattings[i].value]
                                        + '</span>';
                            }
                        }
                        if (item.properties.restrictions.length) {
                            for (var i = 0; i < item.properties.restrictions.length; i++) {
                                if (item.properties.restrictions[i].value && $scope.fieldsConfig.restrictionsMapping[item.properties.restrictions[i].key].elements[item.properties.restrictions[i].value])
                                    ret += '<span class="noteBlock fieldRestrictionWrapper">'
                                        + $scope.fieldsConfig.restrictionsMapping[item.properties.restrictions[i].key].elements[item.properties.restrictions[i].value]
                                        + '</span>';
                            }
                        }
                        ret += '</span>';

                        item.subtitle = ret;

                        return ret;
                    },
                    finalSubtitle: function (item) {
                        if (item.properties.isRequired) {
                            return "<span class='mandatoryField'>Mandatory</span>";
                        }

                        return "";
                    }
                },
                addButton: {
                    condition: function (item) {
                        return false;
                    }
                },
                save_or_delete: "entityField_Action",
                show_contract_fields: "getEntityFields",
                //detailsIcon: 'mdi mdi-table-edit',
                //hasDetails: true,
                //type: "screen",
                operations: $scope.operations,
                keepHeader: true

            }

            // table
            $scope.showFieldConfig = function () {
                $('#fieldCreateInner').appendTo('body');
            };

            $scope.$watch(function () { return $scope.form.currentField; }, function (n, o) {
                if (n) {
                    $('#fieldCreateWrapper').appendTo('body');
                    $('#fieldCreateWrapper').removeClass('hide');

                } else {
                    $('#fieldCreateWrapper').appendTo('#fieldCreateBase');
                    $('#fieldCreateWrapper').addClass('hide');
                }
            });

            $scope.$watch(function () {
                return $scope.form.showContractFields;
            }, function (newValue, oldValue) {
                $scope.entityFieldsOptions.refreshWithContractFields = newValue;
            });
        }
    }
});
