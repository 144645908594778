import { Component, effect, EventEmitter, Input, OnInit, Output, signal, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { menuActions, menuSelectors } from '../../store/menu';
import { ContextInfo, ContextTree } from '../context/context.model';
import { MenuComponent, MenuItem } from '../menu/menu.component';

export const MENU_SHOW_KEY: string = 'ls.menu.show';
export const MENU_SHOW_KEY_VISIBLE: string = 'true';
export const MENU_SHOW_KEY_HIDDEN: string = 'false';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  @Input('menuitems') menuItems: MenuItem[];
  @Input('activemenuitemid') activeMenuItemId: number;
  @Input('menufolderautocollapse') menuFolderAutoCollapse: boolean;
  @Input() context: ContextInfo;
  @Input('userid') userId: number;
  @Input('useonlycontract') useOnlyContract: boolean;
  @Input('refresh') refresh: boolean;

  @Output('onmenuitemclick') onMenuItemClick = new EventEmitter<MenuItem>();
  @Output('onlogoclick') onLogoClick = new EventEmitter();
  @Output('menuopened') menuOpened = new EventEmitter<boolean>();
  @Output('contextchange') contextChange = new EventEmitter<boolean>();
  @Output('contextdata') contextData = new EventEmitter<ContextTree>();

  @ViewChild('appmenu', { static: true }) appMenu: MenuComponent;

  isMenuOpened = signal(false);

  constructor(private store: Store<AppState>) {
    effect(
      () => {
        if (this.isMenuOpened()) {
          this.store.dispatch(menuActions.open());
        } else {
          this.store.dispatch(menuActions.close());
        }
        this.menuOpened.emit(this.isMenuOpened());
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  ngOnInit(): void {
    this.store.pipe(select(menuSelectors.get)).subscribe(menu => {
      if (menu) {
        this.isMenuOpened.set(menu.isOpen);
      } else {
        const storedValue = sessionStorage.getItem(MENU_SHOW_KEY);
        this.isMenuOpened.set(storedValue && storedValue == MENU_SHOW_KEY_VISIBLE);
      }
    });
  }

  toggleMenu(): void {
    this.isMenuOpened.set(!this.isMenuOpened());
    sessionStorage.setItem(MENU_SHOW_KEY, this.isMenuOpened ? MENU_SHOW_KEY_VISIBLE : MENU_SHOW_KEY_HIDDEN);
  }

  menuItemClick(menuItem: MenuItem): void {
    this.onMenuItemClick.emit(menuItem);
  }

  logoClick() {
    this.onLogoClick.emit();
  }
}
