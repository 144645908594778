import { Component, Input, inject, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, switchMap, tap } from 'rxjs';

import { TenantSettingsService } from './tenant-settings.service';
import { TenantSettingModalComponent } from './tenant-setting-modal/tenant-setting-modal.component';
import { CipoTableOptions, TableColumn } from '../../../shared/components/data-list/table.interface';
import { PAGE_SIZE_OPTIONS } from '../../../shared/consts';
import { TenantSettingRow, TenantSettingsEnum } from './tenant-settings.model';
import { NotificationService } from '../../../shared/services/notification.service';

@Component({
  selector: 'app-tenant-settings',
  templateUrl: './tenant-settings.component.html',
})
export class TenantSettingsComponent {
  translate = inject(TranslateService);
  dialog = inject(MatDialog);
  notificate = inject(NotificationService);
  tenantSettingsService = inject(TenantSettingsService);
  loading = true;
  criteria = '';

  tableOptions: CipoTableOptions = {
    clickableRows: true,
    pagination: {
      pageSize: PAGE_SIZE_OPTIONS.long[2],
      pageSizeOptions: PAGE_SIZE_OPTIONS.long,
    },
    sort: {
      active: 'name',
      direction: 'asc',
    },
  };
  tableColumns: TableColumn[];
  tableData: TenantSettingRow[] = [];
  initialTableData: TenantSettingRow[] = [];
  canUpdate = signal(false);

  @Input('canupdate')
  set _canUpdate(value: boolean) {
    this.canUpdate.set(value);
  }

  constructor() {
    this.getMappedData();
    this.tenantSettingsService.getColumns().subscribe(columns => (this.tableColumns = columns));
  }

  getMappedData() {
    this.loading = true;
    this.tenantSettingsService
      .getTenantSettings()
      .pipe(
        map(settings =>
          settings.map(({ value, type, ...args }) => {
            let val = value;
            if (type === 'boolean') {
              val = this.translate.instant('general.boolValue.' + value);
            }
            if (args.id === TenantSettingsEnum.load_more) {
              val = this.translate.instant('tenantSetting.loadMore.' + value);
            }
            return {
              ...args,
              type,
              rawValue: value,
              value: val,
            };
          }),
        ),
        tap(settings => {
          this.initialTableData = settings;
          this.search(this.criteria);
        }),
      )
      .subscribe(() => (this.loading = false));
  }

  search(criteria: string) {
    this.criteria = criteria;
    if (!criteria) {
      this.tableData = this.initialTableData;
      return;
    }
    this.tableData = this.initialTableData.filter(row => {
      return Object.keys(row).some(key => {
        return row[key].toString().toLowerCase().includes(criteria.toLowerCase());
      });
    });
  }

  openSettingModal(tenantSetting: TenantSettingRow) {
    if (!this.canUpdate()) {
      return;
    }
    this.dialog
      .open(TenantSettingModalComponent, {
        panelClass: ['cipo-dialog', 'classic'],
        width: '500px',
        data: tenantSetting,
      })
      .afterClosed()
      .pipe(
        filter(value => value !== undefined),
        switchMap(value => this.tenantSettingsService.updateSetting({ ...tenantSetting, value })),
      )
      .subscribe(value => {
        this.tableData = this.tableData.map(row => {
          if (row.id === tenantSetting.id) {
            row.rawValue = value;
            if (tenantSetting.id === TenantSettingsEnum.load_more) {
              row.value = this.translate.instant('tenantSetting.loadMore.' + value);
            } else if (tenantSetting.type === 'boolean') {
              row.value = this.translate.instant('general.boolValue.' + value);
            } else {
              row.value = value;
            }
          }
          return row;
        });
        this.notificate.success(this.translate.instant('tenantSetting.settingUpdated'));
      });
  }
}
