import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { filter, finalize, map } from 'rxjs';

import { OrderType, SearchViewModel } from '../../../models/module/grid';
import {
  DataListCommandFilterType,
  FiltersToApply,
} from './../../../shared/components/data-list/data-list-command/data-list-command.interface';
import { CipoTableOptions, TableRow } from './../../../shared/components/data-list/table.interface';
import { PAGE_SIZE_OPTIONS, PRESET_BG_COLOR, PRESET_COLOR } from './../../../shared/consts';
import { OpenDocumentsTableService } from './open-documents-table.service';

@Component({
  selector: 'app-open-documents-table',
  templateUrl: './open-documents-table.component.html',
})
export class OpenDocumentsTableComponent {
  instanceId: number;
  searchOptions: SearchViewModel = {
    page: 1,
    pagesize: PAGE_SIZE_OPTIONS.long[2],
  };
  filters: DataListCommandFilterType[] = [];
  tableColumns = this.documentsService.getTableColumns();
  tableData: TableRow[];
  isLoading = true;
  tableOptions: CipoTableOptions = {
    clickableRows: true,
    serverPaginationSort: true,
    pagination: {
      pageIndex: 0,
      pageSize: PAGE_SIZE_OPTIONS.long[2],
      pageSizeOptions: PAGE_SIZE_OPTIONS.long,
    },
  };

  @Input('entityinstance')
  set _entityInstance(instance: any) {
    this.instanceId = instance.entityInstanceId;
    this.populateTableData();
    this.documentsService
      .getDocumentsModules(this.instanceId)
      .subscribe(modules => (this.filters = this.documentsService.initFilters(modules)));
  }
  @Output('openpopupevent') openPopupEvent: EventEmitter<any> = new EventEmitter<any>();

  openPopup(element: any) {
    this.openPopupEvent.emit(element);
  }

  constructor(
    private documentsService: OpenDocumentsTableService,
    private translate: TranslateService,
  ) {}

  populateTableData() {
    this.documentsService
      .getOpenDocuments(this.searchOptions, this.instanceId)
      .pipe(
        filter(data => !!data),
        map(data => {
          this.tableOptions.pagination.length = data.records;
          this.tableOptions = {
            ...this.tableOptions,
          };
          return data.data.map(row => {
            const tableRow: TableRow = {
              ...row,
              state: {
                bgColor: row.isInApproval ? PRESET_BG_COLOR : row.stateColor,
                color: row.isInApproval ? PRESET_COLOR : row.stateTextColor,
                label: row.isInApproval ? this.translate.instant('defaultStatus.InApproval') : row.state,
              },
            };
            return tableRow;
          });
        }),
        finalize(() => (this.isLoading = false)),
      )
      .subscribe(data => (this.tableData = data));
  }

  search({ filters, criteria }: FiltersToApply) {
    this.isLoading = true;
    this.searchOptions.criteria = criteria;
    this.searchOptions.filter = filters;
    this.searchOptions.page = 1;
    this.tableOptions = {
      ...this.tableOptions,
      pagination: {
        ...this.tableOptions.pagination,
        pageIndex: 0,
      },
    };

    this.populateTableData();
  }

  handleSort(event: Sort) {
    this.searchOptions.sort = [
      {
        field: event.active,
        order: event.direction === 'asc' ? OrderType.Ascending : OrderType.Descending,
      },
    ];

    this.populateTableData();
  }

  handlePagination({ pageIndex, pageSize }: PageEvent) {
    this.searchOptions = {
      ...this.searchOptions,
      page: pageIndex + 1,
      pagesize: pageSize,
    };

    this.tableOptions.pagination = {
      ...this.tableOptions.pagination,
      pageIndex,
      pageSize,
    };

    this.populateTableData();
  }
}
