import { GridSearchEntityInstance } from "../../../models/module/grid";

export enum CalendarViewMode {
  Day = 1,
  Week = 2,
  Month = 3,
  Year = 4,
}

export interface CalendarDate {
  date?: Date;
  year: number;
  month: number;
  week?: number;
  day?: number;
}

export interface CalendarTileDocRef extends GridSearchEntityInstance {
  date: Date;
  year: number;
  month: number;
  day: number;
}

export interface CalendarTileDoc {
  stateColor: string;
  desc: string;
  time?: string;
  ref?: CalendarTileDocRef;
}

export interface CalendarTile extends CalendarDate {
  viewMode: CalendarViewMode;
  text: string;
  isToday?: boolean;
  docs: CalendarTileDoc[];
}

export interface CalendarReferenceField {
  id: number;
  name: string;
  label: string;
}
