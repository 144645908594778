@if (formControl()) {
    <mat-form-field class="full-width attachments-field" appearance="outline">
        <mat-label>{{ fieldData().label ?? "" }}</mat-label>
        <mat-chip-grid #chipGrid [formControl]="formControl()">
            @for (item of formControl().value; track $index) {
                <mat-chip-row
                    (removed)="removeChip($index)"
                    (click)="openAttachmentDialog($index)"
                    [value]="item"
                    [title]="item.name">
                    <img matChipAvatar [src]="item | imageSource" />
                    {{ item.name }}

                    <mat-icon
                        [matMenuTriggerFor]="menu"
                        (click)="$event.stopPropagation()"
                        matChipTrailingIcon
                        class="cursor--pointer"
                        [matMenuTriggerData]="{ item: item }">
                        more_vert
                    </mat-icon>
                    @if (isActiveField()) {
                        <button matChipRemove>
                            <mat-icon class="material-icons-outlined">delete</mat-icon>
                        </button>
                    }
                </mat-chip-row>
            }
            <div fxLayout fxLayoutAlign="end center" fxFlex fxLayoutGap="12px">
                @if (isActiveField()) {
                    <button mat-icon-button (click)="addAttachments()" [title]="'cipoFields.attachFiles' | translate">
                        <mat-icon>attachment</mat-icon>
                    </button>
                } @else if (fieldData().showMultipleSave) {
                    <button
                        mat-icon-button
                        (click)="downloadAll()"
                        [disabled]="!formControl()?.value?.length"
                        [title]="'cipoFields.downloadAll' | translate">
                        <mat-icon>collections_bookmark</mat-icon>
                    </button>
                }
                @if (fieldData().fieldDescription) {
                    <mat-icon class="material-icons-outlined cursor--help" [title]="fieldData().fieldDescription">
                        info
                    </mat-icon>
                }
            </div>
            <input #chipInput matInput [formControl]="formControl()" [matChipInputFor]="chipGrid" />
        </mat-chip-grid>
        <mat-error>{{ "fieldErrors.required" | translate }}</mat-error>
        <mat-hint>{{ fieldData().hint }}</mat-hint>
    </mat-form-field>
} @else if (attachments()) {
    <mat-chip-set class="attachments-chip-set">
        @for (item of attachments(); track $index) {
            <mat-chip (removed)="removeChip($index)" (click)="openAttachmentDialog($index)" [title]="item.name">
                <img matChipAvatar [src]="item | imageSource" />
                {{ item.name }}
                <mat-icon
                    [matMenuTriggerFor]="menu"
                    (click)="$event.stopPropagation()"
                    matChipTrailingIcon
                    class="cursor--pointer"
                    [matMenuTriggerData]="{ item: item }">
                    more_vert
                </mat-icon>
                @if (!fieldData().readonly) {
                    <button matChipRemove>
                        <mat-icon class="material-icons-outlined">delete</mat-icon>
                    </button>
                }
            </mat-chip>
        }
        @if (!fieldData().readonly) {
            <button
                mat-icon-button
                class="m-l-md"
                (click)="addAttachmentChip()"
                [title]="'cipoFields.attachFiles' | translate">
                <mat-icon>attachment</mat-icon>
            </button>
        } @else if (fieldData().showMultipleSave) {
            <button
                mat-icon-button
                class="m-l-md"
                (click)="downloadAll()"
                [disabled]="!attachments()?.length"
                [title]="'cipoFields.downloadAll' | translate">
                <mat-icon>collections_bookmark</mat-icon>
            </button>
        }
    </mat-chip-set>
}
<mat-menu #menu="matMenu">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item (click)="openNewTab(item)">{{ "cipoFields.openNewTab" | translate }}</button>
        <button mat-menu-item (click)="download(item)">{{ "cipoFields.download" | translate }}</button>
        <button mat-menu-item [matMenuTriggerFor]="saveTo" [matMenuTriggerData]="{ item: item }">
            {{ "cipoFields.saveTo" | translate }}
        </button>
    </ng-template>
</mat-menu>

<mat-menu #saveTo="matMenu">
    <ng-template matMenuContent let-item="item">
        <button mat-menu-item (click)="saveToDrive(item)">{{ driveName() }}</button>
        <button mat-menu-item (click)="saveToProjectFiles(item)">{{ projectFilesName() }}</button>
    </ng-template>
</mat-menu>
