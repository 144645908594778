<div *ngIf="isLabelValueSplit"
     class="part"
     fxLayout="row">
     <div *ngIf="showLabel"
          class="partLabelSplit ellipsis"
          [fxFlex]="100 - valueSize"
          [fxLayoutAlign]="getVerticalHorizontalAlign(part.field.labelFormatting)"
          [style.color]="getFontColor(part.field.labelFormatting)"
          [style.fontSize]="getFontSize(part.field.labelFormatting)"
          [style.fontWeight]="getFontWeight(part.field.labelFormatting)"
          [style.fontStyle]="getFontStyle(part.field.labelFormatting)"
          [style.textDecoration]="getTextDecoration(part.field.labelFormatting)"
          [title]="part.field.label">
          {{part.field.label}}{{part.field.labelFormatting.showColon ? ':' : ''}}
     </div>
     <div [fxFlex]="valueSize"
          class="partValueSplit"
          [fxLayoutAlign]="getVerticalHorizontalAlign(part.field.valueFormatting)"
          [style.color]="getFontColor(part.field.valueFormatting)"
          [style.fontSize]="getFontSize(part.field.valueFormatting)"
          [style.fontWeight]="getFontWeight(part.field.valueFormatting)"
          [style.fontStyle]="getFontStyle(part.field.valueFormatting)"
          [style.textDecoration]="getTextDecoration(part.field.valueFormatting)">
          <ng-container [ngTemplateOutlet]="valueFormatTpl"></ng-container>
     </div>
</div>

<div *ngIf="isLabelValueJoin"
     class="part"
     fxLayout="row">
     <div fxFlex
          [fxLayoutAlign]="getVerticalHorizontalAlign(part.field.valueFormatting)">
          <span class="partLabelJoin"
                [style.color]="getFontColor(part.field.labelFormatting)"
                [style.fontSize]="getFontSize(part.field.labelFormatting)"
                [style.fontWeight]="getFontWeight(part.field.labelFormatting)"
                [style.fontStyle]="getFontStyle(part.field.labelFormatting)"
                [style.textDecoration]="getTextDecoration(part.field.labelFormatting)"
                [title]="part.field.label">
               {{part.field.label}}{{part.field.labelFormatting.showColon ? ':' : ''}}
          </span>
          &nbsp;
          <span class="partValueJoin"
                [style.color]="getFontColor(part.field.valueFormatting)"
                [style.fontSize]="getFontSize(part.field.valueFormatting)"
                [style.fontWeight]="getFontWeight(part.field.valueFormatting)"
                [style.fontStyle]="getFontStyle(part.field.valueFormatting)"
                [style.textDecoration]="getTextDecoration(part.field.valueFormatting)">
               <ng-container [ngTemplateOutlet]="valueFormatTpl"></ng-container>
          </span>
     </div>
</div>

<div *ngIf="isLabelValueVertical"
     class="part"
     fxLayout="column"
     [fxLayoutAlign]="getVerticalHorizontalAlign(part.field.valueFormatting)">
     <div class="partVertical">
          <div class="partLabelVertical"
               [style.color]="getFontColor(part.field.labelFormatting)"
               [style.fontSize]="getFontSize(part.field.labelFormatting)"
               [style.fontWeight]="getFontWeight(part.field.labelFormatting)"
               [style.fontStyle]="getFontStyle(part.field.labelFormatting)"
               [style.textDecoration]="getTextDecoration(part.field.labelFormatting)"
               [title]="part.field.label">
               {{part.field.label}}{{part.field.labelFormatting.showColon ? ':' : ''}}
          </div>
          <div class="partValueVertical"
               [style.color]="getFontColor(part.field.valueFormatting)"
               [style.fontSize]="getFontSize(part.field.valueFormatting)"
               [style.fontWeight]="getFontWeight(part.field.valueFormatting)"
               [style.fontStyle]="getFontStyle(part.field.valueFormatting)"
               [style.textDecoration]="getTextDecoration(part.field.valueFormatting)">
               <ng-container [ngTemplateOutlet]="valueFormatTpl"></ng-container>
          </div>
     </div>
</div>

<ng-template #valueFormatTpl>
     <ng-container *ngIf="part.isNumber && part.valueNumber">
          <ng-container *ngIf="part.isNumberIndicator"
                        [ngTemplateOutlet]="numberIndicatorTpl">
          </ng-container>
          <ng-container *ngIf="part.isNumberProgress"
                        [ngTemplateOutlet]="numberProgressTpl">
          </ng-container>
          <ng-container *ngIf="!part.isNumberIndicator && !part.isNumberProgress"
                        [ngTemplateOutlet]="numberTpl">
          </ng-container>
     </ng-container>
     <ng-container *ngIf="part.isDate && part.valueString">
          <span [title]="part.valueString | itvDateTimeWithRestrictions:part.field.restrictions">
               {{part.valueString | itvDateTimeWithRestrictions:part.field.restrictions}}
          </span>
     </ng-container>
     <ng-container *ngIf="part.isText">
          <span [title]="part.valueString"
                [innerHTML]="part.valueString">
          </span>
     </ng-container>
     <ng-container *ngIf="part.isYesNo"
                   [ngTemplateOutlet]="yesNoTpl">
     </ng-container>
     <ng-container *ngIf="part.isState && part.valueState"
                   [ngTemplateOutlet]="stateTpl">
     </ng-container>
     <ng-container *ngIf="part.isLine"
                   [ngTemplateOutlet]="lineTpl">
     </ng-container>
</ng-template>

<ng-template #numberTpl>
     <span [title]="part.valueNumber | itvNumberWithFormattings:part.field.formattings:part.field.restrictions">
          {{part.valueNumber | itvNumberWithFormattings:part.field.formattings:part.field.restrictions}}
     </span>
</ng-template>

<ng-template #numberIndicatorTpl>
     <mat-icon class="indicator-icon">
          {{ part.isNumberIndicatorDown ? 'arrow_drop_down' : 'arrow_drop_up'}}
     </mat-icon>
     <ng-container *ngTemplateOutlet="numberTpl"></ng-container>
</ng-template>

<ng-template #numberProgressTpl>
     <itv-number-progress-bar [value]="numberProgressValue"
                              [formattings]="part.field.formattings"
                              [restrictions]="part.field.restrictions"
                              [isDuration]="part.isNumberDurationProgress">
     </itv-number-progress-bar>
</ng-template>

<ng-template #yesNoTpl>
     {{part.valueString ? part.valueString : (part.valueBoolean ? 'Yes' : 'No')}}
</ng-template>

<ng-template #stateTpl>
     <span class="stateBlock ellipsis"
           [style.color]="part.valueState.fontColor"
           [style.backgroundColor]="part.valueState.bgColor"
           [style.fontWeight]="getFontWeight(part.field.valueFormatting)"
           [style.fontStyle]="getFontStyle(part.field.valueFormatting)"
           [style.textDecoration]="getTextDecoration(part.field.valueFormatting)">
          {{part.valueState.name}}
     </span>
</ng-template>

<ng-template #lineTpl>
     <hr class="lineBlock"
         [style.borderTopColor]="getFontColor(part.field.valueFormatting)" />
</ng-template>