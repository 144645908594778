import { on } from '@ngrx/store';
import { produce } from 'immer';
import { CoreState, StoreReducer } from '../interfaces';
import { menuActions } from './menu.actions';

export const menuReducers: StoreReducer<CoreState>[] = [
  on(menuActions.toggle, (state) =>
    produce(state, draft => {
      draft.menu.isOpen = !draft.menu.isOpen;
    }),
  ),
  on(menuActions.open, (state) =>
    produce(state, draft => {
      draft.menu.isOpen = true;
    }),
  ),
  on(menuActions.close, (state) =>
    produce(state, draft => {
      draft.menu.isOpen = false;
    }),
  ),
];
