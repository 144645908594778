import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { FilterTextOperators, GridViewModel, SearchViewModel } from '../../../models/module/grid';
import { HttpService } from '../../../shared/services/http.service';
import { OpenDocumentsModel } from 'src/app/models/module/openDocuments';
import { DefaultStatesEnum, KeyValueType } from 'src/app/models/common';
import { BaseRestrictions } from 'src/app/models/module/fields/enums';
import { TableColumn, DisplayFormats } from 'src/app/shared/components/data-list/table.interface';
import { DataListCommandFilterType } from 'src/app/shared/components/data-list/data-list-command';

@Injectable()
export class OpenDocumentsTableService {
  constructor(
    private httpService: HttpService,
    private translate: TranslateService,
  ) {}

  getOpenDocuments(searchView: SearchViewModel, entityInstanceId: number) {
    const params = this.httpService.buildHttpParams({ entityInstanceId });
    return this.httpService.post<GridViewModel<OpenDocumentsModel[]>>(`_api/Contracts/SearchDocuments`, searchView, {
      useLoader: false,
      httpOptions: { params },
    });
  }

  getDocumentsModules(entityInstanceId: number) {
    const params = this.httpService.buildHttpParams({ entityInstanceId });
    return this.httpService.get<KeyValueType<number>[]>(`_api/Contracts/GetDocumentsModules`, {
      useLoader: false,
      httpOptions: { params },
    });
  }

  getTableColumns(): TableColumn[] {
    return [
      {
        id: 0,
        name: 'moduleName',
        displayName: this.translate.instant('OPEN_DOCUMENTS.DOC_TABLE.MODULE'),
        sortable: true,
        width: 2,
      },
      {
        id: 1,
        name: 'fullDocNum',
        displayName: this.translate.instant('OPEN_DOCUMENTS.DOC_TABLE.FULLDOCNUM'),
        sortable: true,
      },
      {
        id: 2,
        name: 'ballInCourtOrgName',
        displayName: this.translate.instant('OPEN_DOCUMENTS.DOC_TABLE.BALLINCOURTORGNAME'),
        sortable: true,
      },
      {
        id: 3,
        name: 'subject',
        displayName: this.translate.instant('OPEN_DOCUMENTS.DOC_TABLE.SUBJECT'),
        sortable: true,
        width: 3,
      },
      {
        id: 4,
        name: 'state',
        displayName: this.translate.instant('OPEN_DOCUMENTS.DOC_TABLE.STATE'),
        sortable: true,
        displayFormatId: DisplayFormats.Status,
        align: 'start',
        width: 1,
      },
      {
        id: 5,
        name: 'submittedOn',
        displayName: this.translate.instant('OPEN_DOCUMENTS.DOC_TABLE.SUBMITTEDON'),
        sortable: true,
        displayFormatId: DisplayFormats.Date,
        restrictions: BaseRestrictions.DateTime,
        width: 2,
      },
    ];
  }

  initFilters(modules: KeyValueType<number>[]): DataListCommandFilterType[] {
    return [
      {
        fieldId: 'moduleId',
        name: this.translate.instant('OPEN_DOCUMENTS.DOC_TABLE.MODULE'),
        operators: ['Equal', 'NotEqual'],
        selectedOperator: 'Equal',
        multipleSelection: true,
        type: 'text',
        values: modules,
      },
      {
        fieldId: 'stateType',
        name: this.translate.instant('OPEN_DOCUMENTS.DOC_TABLE.STATE_TYPE'),
        operators: ['Equal', 'NotEqual'],
        selectedOperator: 'Equal',
        type: 'text',
        multipleSelection: true,
        values: [
          {
            key: `${DefaultStatesEnum.Approved}`,
            value: DefaultStatesEnum[DefaultStatesEnum.Approved],
          },
          {
            key: `${DefaultStatesEnum.Draft}`,
            value: 'Start',
          },
          {
            key: `${DefaultStatesEnum.Open}`,
            value: DefaultStatesEnum[DefaultStatesEnum.Open],
          },
          {
            key: `${DefaultStatesEnum.Rejected}`,
            value: DefaultStatesEnum[DefaultStatesEnum.Rejected],
          },
        ],
      },
      {
        fieldId: 'state',
        name: this.translate.instant('OPEN_DOCUMENTS.DOC_TABLE.STATE'),
        operators: FilterTextOperators,
        type: 'text',
      },
    ];
  }
}
