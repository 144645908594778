import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

/**
 * A confirmation dialog.
 *
 * #### Usage
 *
 * ```ts
 * const dialogRef = this.dialog.open(ConfirmationDialog, {
 *    data: {
 *      title: 'MODULE.DIALOG_DELETE.TITLE',
 *      messages: ['MODULE.DIALOG_DELETE.MESSAGE1', 'MODULE.DIALOG_DELETE.MESSAGE2'],
 *    } as ConfirmationDialogData,
 *  });
 * dialogRef.afterClosed().subscribe(result => {
 *   if (!!result) {
 *     // deletion was confirmed
 *     // deletion code here
 *   }
 * });
 * ```
 */
@Component({
  selector: 'app-confirmation-dialog',
  template: `
    <h2 mat-dialog-title>{{ data.title | translate }}</h2>
    <mat-dialog-content>
      @for (message of data.messages; track message) {
        <p>{{ message | translate }}</p>
      }
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button [mat-dialog-close]="false">{{'general.no' | translate}}</button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{'general.yes' | translate}}</button>
    </mat-dialog-actions>
  `,
  standalone: true,
  imports: [MatButtonModule, MatDialogModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialog {
  readonly data = inject<ConfirmationDialogData>(MAT_DIALOG_DATA);
}

export interface ConfirmationDialogData {
  title: string;
  messages: string[];
}
