@if (fieldData().readonly) {
    <cipo-text-view [config]="formControl().fieldData" [value]="formControl().value" [type]="fieldData().type" />
} @else {
    @if (!fieldData()?.multiple) {
        <mat-form-field class="full-width" appearance="outline">
            <mat-label *ngIf="fieldData().label">{{ fieldData().label }}</mat-label>

            <input
                matInput
                [thousandSeparator]="fieldData().showThousandSeparator"
                [decimals]="fieldData().decimals"
                [formControl]="formControl()"
                [placeholder]="fieldData().placeholder ?? ''" />

            <mat-icon matPrefix *ngIf="fieldData().prefixIcon">{{ fieldData().prefixIcon }}</mat-icon>
            <span matTextPrefix *ngIf="fieldData().prefix" class="text--pre">{{ fieldData().prefix }}</span>

            <span matTextSuffix *ngIf="fieldData().suffix" class="text--pre">{{ fieldData().suffix }}</span>
            <mat-icon matSuffix *ngIf="fieldData().suffixIcon">{{ fieldData().suffixIcon }}</mat-icon>
            @if (canClearInput() && formControl().value) {
                <button matSuffix mat-icon-button (click)="formControl().setValue(null)">
                    <mat-icon>close</mat-icon>
                </button>
            } @else if (fieldData().fieldDescription) {
                <mat-icon matSuffix class="material-icons-outlined cursor--help" [title]="fieldData().fieldDescription">
                    info
                </mat-icon>
            }
            <mat-error>{{ "fieldErrors.required" | translate }}</mat-error>
            <mat-hint *ngIf="fieldData().hint">{{ fieldData().hint }}</mat-hint>
        </mat-form-field>
    } @else {
        <mat-form-field class="full-width" appearance="outline">
            <mat-label *ngIf="fieldData().label">{{ fieldData().label }}</mat-label>
            <mat-chip-grid #chipGrid [formControl]="chipsField">
                @for (value of $any(formControl().value); track $index) {
                    <mat-chip-row (removed)="remove($index)" (click)="edit($index)">
                        <span>
                            @if (fieldData().prefixIcon) {
                                <mat-icon inline>{{ fieldData().prefixIcon }}</mat-icon>
                            }
                            @if (fieldData().prefix) {
                                {{ fieldData().prefix }}
                            }
                            {{ value | number: formatting() }}
                            @if (fieldData().suffix) {
                                {{ fieldData().suffix }}
                            }
                            @if (fieldData().suffixIcon) {
                                <mat-icon inline>{{ fieldData().suffixIcon }}</mat-icon>
                            }
                        </span>
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip-row>
                }
                @if (fieldData().prefixIcon) {
                    <mat-icon class="m-l-sm" fxFlexAlign="center">{{ fieldData().prefixIcon }}</mat-icon>
                }
                @if (fieldData().prefix) {
                    <span class="text--pre m-l-sm" fxFlexAlign="center">{{ fieldData().prefix }}</span>
                }
                <input
                    #chipInput
                    [ngClass]="fieldData().prefix || fieldData().prefixIcon ? 'prefixed-chip-input' : ''"
                    [thousandSeparator]="fieldData().showThousandSeparator"
                    [decimals]="fieldData().decimals"
                    [matChipInputFor]="chipGrid"
                    [matChipInputAddOnBlur]="true"
                    autocomplete="off"
                    [placeholder]="fieldData().placeholder ?? ''"
                    (matChipInputTokenEnd)="add($event)" />
                @if (fieldData().suffix) {
                    <span class="text--pre m-l-sm" fxFlexAlign="center">{{ fieldData().suffix }}</span>
                }
                @if (fieldData().suffixIcon) {
                    <mat-icon class="m-l-sm" fxFlexAlign="center">{{ fieldData().suffixIcon }}</mat-icon>
                }
            </mat-chip-grid>
            <mat-error>{{ "fieldErrors.required" | translate }}</mat-error>
            <mat-hint *ngIf="fieldData().hint">{{ fieldData().hint }}</mat-hint>
        </mat-form-field>
    }
}
