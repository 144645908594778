import { NgModule } from '@angular/core';

import { CipoListboxComponent } from '../components/fields/cipo-listbox/cipo-listbox.component';
import { CipoNumberComponent } from '../components/fields/cipo-number/cipo-number.component';
import { CipoSelectComponent } from '../components/fields/cipo-select/cipo-select.component';
import { CipoTextComponent } from '../components/fields/cipo-text/cipo-text.component';
import { CipoCheckboxComponent } from '../components/fields/cipo-checkbox/cipo-checkbox.component';
import { CipoAttachmentComponent } from '../components/fields/cipo-attachment/cipo-attachment.component';

@NgModule({
  imports: [
    CipoListboxComponent,
    CipoNumberComponent,
    CipoSelectComponent,
    CipoTextComponent,
    CipoCheckboxComponent,
    CipoAttachmentComponent,
  ],
  exports: [
    CipoListboxComponent,
    CipoNumberComponent,
    CipoSelectComponent,
    CipoTextComponent,
    CipoCheckboxComponent,
    CipoAttachmentComponent,
  ],
})
export class CipoFieldsModule {}
