import { Pipe, PipeTransform } from '@angular/core';
import { MeasurementSystem } from 'src/app/models/module/fields/enums/measurementSystem';

@Pipe({
  name: 'inch'
})
export class InchPipe implements PipeTransform {

  transform(value: string, ...args: MeasurementSystem[]): string {
    return value + ' in';
  }
}
