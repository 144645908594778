import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class DateService {
    private dateChanged = new BehaviorSubject<any>('');
    private date = new Date(0);
    public dateChanged$: Observable<Payload> = this.dateChanged.asObservable();

    dateUpdated(payload: Payload):void {
        const incomingDate = new Date(payload.value);
        if(!this.datesAreEqual(this.date, incomingDate)) {
            this.date=incomingDate;
            this.dateChanged.next(payload);
        }
    }

    datesAreEqual(date1: Date, date2: Date): boolean {
        return date1.getFullYear() == date2.getFullYear() && 
            date1.getMonth() == date2.getMonth() &&
            date1.getDate() == date2.getDate();
    }
}

interface Payload {
    value: Date;
    fieldId: number;
    contractId: number
}

