import { Component, computed, Input, OnInit, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { filter, switchMap, tap } from 'rxjs';

import { SearchViewModel } from '../../../models/module/grid';
import { DataListCommandFilterType, FiltersToApply } from '../../../shared/components/data-list/data-list-command';
import { DEFAULT_PAGE_SIZE } from '../../../shared/consts';
import { NotificationService } from './../../../shared/services/notification.service';
import {
  ScheduledEmail,
  ScheduledEmailActionEvent,
  ScheduledEmailActionsEnum,
  ScheduledEmailRow,
  ScheduledEmailTableOptions,
  ScheduleFrequencyEnum,
} from './interfaces';
import { NewScheduleDialogComponent } from './new-schedule-dialog/new-schedule-dialog.component';
import { ScheduledEmailService } from './scheduled-emails.service';
import { ConfirmationDialog, ConfirmationDialogData } from '../../../shared/dialogs/confirmation-dialog.component';
import { Permission } from '../../../models/permission';

@Component({
  selector: 'app-scheduled-emails',
  templateUrl: './scheduled-emails.component.html',
})
export class ScheduledEmailsComponent implements OnInit {
  permissions = signal<Permission[]>([]);
  canCreate = computed(() => this.permissions().includes(Permission.Create));
  
  @Input('permissions')
  set _permissions(permissions: Permission[]) {
    this.permissions.set(permissions);
  }

  tableColumns = this.emailService.getTableColumns();
  tableData: ScheduledEmailRow[];
  options: ScheduledEmailTableOptions = {
    actions: (row: ScheduledEmailRow) => this.emailService.getTableActions(row, this.permissions()),
    serverPaginationSort: true,
    clickableRows: true,
    pagination: {},
  };

  isLoading = true;
  filters: DataListCommandFilterType[] = [];
  requestFilters: SearchViewModel = {
    page: 1,
    pagesize: DEFAULT_PAGE_SIZE,
  };

  constructor(
    private emailService: ScheduledEmailService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.loadScheduledEmails();
    this.filters = this.emailService.getFilters();
  }

  loadScheduledEmails() {
    this.isLoading = true;
    this.emailService.getScheduledEmails(this.requestFilters).subscribe(result => {
      this.tableData = this.getTableData(result.data);
      this.options.pagination.length = result.records;
      this.options = { ...this.options };
      this.isLoading = false;
    });
  }

  getTableData(data: ScheduledEmail[]): ScheduledEmailRow[] {
    return data.map(dataRow => {
      const { scheduleFrequency } = dataRow;
      return {
        frequency: this.translate.instant(
          `SCHEDULED_EMAILS.formField.frequency.${ScheduleFrequencyEnum[scheduleFrequency]}`,
        ),
        ...dataRow,
      };
    });
  }

  filterTable(changes: FiltersToApply) {
    this.requestFilters.criteria = changes.criteria;
    this.requestFilters.filter = changes.filters;
    this.loadScheduledEmails();
  }

  updatePagination(event: PageEvent) {
    this.requestFilters.page = event.pageIndex + 1;
    this.requestFilters.pagesize = event.pageSize;
    this.loadScheduledEmails();
  }

  addEditEntry(scheduledEmail?: ScheduledEmailRow) {
    this.dialog
      .open<NewScheduleDialogComponent, ScheduledEmailRow, ScheduledEmail>(NewScheduleDialogComponent, {
        panelClass: ['cipo-dialog', 'classic'],
        width: '800px',
        data: scheduledEmail,
      })
      .afterClosed()
      .pipe(
        filter(data => !!data),
        tap(() => {
          if (scheduledEmail) {
            this.tableData = this.tableData.map(r => (r.id === scheduledEmail.id ? { ...r, empty: true } : r));
          } else {
            this.isLoading = true;
          }
        }),
        switchMap(data =>
          data.id ? this.emailService.updateScheduledEmail(data) : this.emailService.createScheduledEmail(data),
        ),
      )
      .subscribe({
        next: data => {
          let notification: string;
          if (scheduledEmail) {
            notification = this.translate.instant('SCHEDULED_EMAILS.notifications.updated');
            this.isLoading = false;
            this.tableData = this.tableData.map(r => (r.id === data.id ? this.getTableData([data])[0] : r));
          } else {
            notification = this.translate.instant('SCHEDULED_EMAILS.notifications.created');
            this.loadScheduledEmails();
          }
          this.notificationService.success(notification);
        },
        error: () => {
          this.isLoading = false;
          this.tableData.forEach(r => (r.empty = false)); 
        }
      });
  }

  performAction({ actionId, row }: ScheduledEmailActionEvent) {
    switch (actionId) {
      case ScheduledEmailActionsEnum.SEND_NOW:
        // Send now
        break;
      case ScheduledEmailActionsEnum.EDIT:
        this.addEditEntry(row);
        break;
      case ScheduledEmailActionsEnum.TOGGLE:
        this.emailService.toggleScheduled(row.id, !row.isEnabled).subscribe(updatedRow => {
          const index = this.tableData.findIndex(r => r.id === row.id);
          this.tableData[index] = {
            ...updatedRow,
            frequency: ScheduleFrequencyEnum[updatedRow.scheduleFrequency],
          };
          this.tableData = this.tableData.map(r => r);
          this.notificationService.success(this.translate.instant('SCHEDULED_EMAILS.notifications.stateChanged'));
        });
        break;
      case ScheduledEmailActionsEnum.DELETE:
        this.delete(row.id);
        break;
    }
  }

  delete(id: number): void {
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {
        title: 'SCHEDULED_EMAILS.deleteDialog.title',
        messages: ['SCHEDULED_EMAILS.deleteDialog.message'],
      } as ConfirmationDialogData,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (!!result) {
        this.emailService.deleteScheduledEmail(id).subscribe(() => {
          this.tableData = this.tableData.filter(r => r.id !== id);
          this.notificationService.success(this.translate.instant('SCHEDULED_EMAILS.notifications.deleted'));
        });
      }
    });
  }
}
