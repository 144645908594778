<mat-toolbar>
    <div class="context-info" style="display: flex">
        <button
            mat-raised-button
            class="today"
            data-testid="today-button"
            [attr.aria-label]="'MODULE_INSTANCE_CALENDAR.BUTTON_TODAY' | translate"
            [disabled]="isViewLoading"
            (click)="setToday()">
            {{ "MODULE_INSTANCE_CALENDAR.BUTTON_TODAY" | translate }}
        </button>
        <div class="prev-next">
            <button
                mat-icon-button
                data-testid="prev-period-button"
                [attr.aria-label]="prevLabel"
                [title]="prevLabel"
                [disabled]="isViewLoading"
                (click)="incrementDecrementDate(true)">
                <mat-icon>chevron_left</mat-icon>
            </button>
            <button
                mat-icon-button
                data-testid="next-period-button"
                [attr.aria-label]="nextLabel"
                [title]="nextLabel"
                [disabled]="isViewLoading"
                (click)="incrementDecrementDate(false)">
                <mat-icon>chevron_right</mat-icon>
            </button>
        </div>
        <span class="date" data-testid="date-text">
            <span *ngIf="calendarViewMode == calendarViewModeType.Day" class="day" data-testid="date-text-day">
                {{ calendarDate.day }}
            </span>
            <span>
                <ng-container
                    *ngIf="calendarViewMode != calendarViewModeType.Year"
                    [ngTemplateOutlet]="monthSelection"></ng-container>
                <ng-container [ngTemplateOutlet]="yearSelection"></ng-container>
            </span>
            <span *ngIf="calendarViewMode == calendarViewModeType.Week" class="week" data-testid="date-text-week">
                {{ "MODULE_INSTANCE_CALENDAR.WEEK_NR" | translate }}
                {{ calendarDate.week }}
            </span>
        </span>
    </div>
    <div class="context-actions" style="display: flex">
        <span class="spacer"></span>
        <ng-container [ngTemplateOutlet]="dateFieldSelection"></ng-container>
        <ng-container [ngTemplateOutlet]="calendarOptions"></ng-container>
    </div>
</mat-toolbar>
<ng-container [ngSwitch]="calendarViewMode">
    <app-calendar-month-view
        *ngSwitchCase="calendarViewModeType.Month"
        data-testid="calendar-view-mode-month"
        [date]="calendarDate"
        [dateField]="calendarDateField"
        [descField]="calendarDescField"
        [actions]="actions"
        [search]="search"
        [contractId]="contractId"
        [screenFields]="screenFields"
        [showWeekends]="showWeekends"
        [showWeekNumber]="showWeekNumber"
        (onLoading)="viewIsLoading($event)"></app-calendar-month-view>
    <app-calendar-year-view
        *ngSwitchCase="calendarViewModeType.Year"
        data-testid="calendar-view-mode-year"
        [date]="calendarDate"
        [dateField]="calendarDateField"
        [descField]="calendarDescField"
        [actions]="actions"
        [search]="search"
        [contractId]="contractId"
        [screenFields]="screenFields"
        (onLoading)="viewIsLoading($event)"
        (onMonthClick)="gotoMonthView($event)"></app-calendar-year-view>
</ng-container>

<ng-template #monthSelection>
    <button
        mat-button
        class="button-month-selection"
        data-testid="button-month-selection"
        [attr.aria-label]="calendarDate.month"
        [disabled]="isViewLoading"
        [matMenuTriggerFor]="menuYearSelection">
        {{ monthName }}
    </button>
    <mat-menu class="menu-month-selection" #menuYearSelection>
        <button
            mat-menu-item
            *ngFor="let month of monthsSelection"
            class="menu-item-month-selection"
            data-testid="menu-item-month-selection"
            [class.cipo-text--primary-900]="calendarDate.month == month"
            [attr.aria-label]="monthNames[month]"
            (click)="setMonth(month)">
            {{ monthNames[month] }}
        </button>
    </mat-menu>
</ng-template>

<ng-template #yearSelection>
    <button
        mat-button
        class="button-year-selection"
        data-testid="button-year-selection"
        [attr.aria-label]="calendarDate.year"
        [disabled]="isViewLoading"
        [matMenuTriggerFor]="menuYearSelection">
        {{ calendarDate.year }}
    </button>
    <mat-menu class="menu-year-selection" #menuYearSelection>
        <button
            mat-menu-item
            *ngFor="let year of yearsSelection"
            class="menu-item-year-selection"
            data-testid="menu-item-year-selection"
            [class.cipo-text--primary-900]="calendarDate.year == year"
            [attr.aria-label]="year"
            (click)="setYear(year)">
            {{ year }}
        </button>
    </mat-menu>
</ng-template>

<ng-template #dateFieldSelection>
    <button
        mat-raised-button
        class="button-date-field-selection"
        data-testid="button-date-field-selection"
        *ngIf="dateFields && dateFields.length"
        [attr.aria-label]="calendarDateField?.label || calendarDateField?.name"
        [disabled]="isViewLoading"
        [matMenuTriggerFor]="menuDateFieldSelection">
        {{ calendarDateField?.label || calendarDateField?.name }}
        <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
    </button>
    <mat-menu class="menu-date-field-selection" #menuDateFieldSelection>
        <button
            mat-menu-item
            *ngFor="let dateField of dateFields"
            class="mat-button menu-item-date-field-selection"
            data-testid="menu-item-date-field-selection"
            [class.mat-primary]="matchDateField(dateField)"
            [attr.aria-label]="dateField.label || dateField.name"
            (click)="setDateField(dateField)">
            {{ dateField.label || dateField.name }}
        </button>
    </mat-menu>
</ng-template>

<ng-template #calendarOptions>
    <button
        mat-raised-button
        class="menu-button"
        data-testid="menu-button"
        [attr.aria-label]="'MODULE_INSTANCE_CALENDAR.VIEW_MODE.' + calendarViewModeType[calendarViewMode] | translate"
        [disabled]="isViewLoading"
        [matMenuTriggerFor]="menu">
        {{ "MODULE_INSTANCE_CALENDAR.VIEW_MODE." + calendarViewModeType[calendarViewMode] | translate }}
        <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
    </button>
    <mat-menu class="menu" #menu>
        <button
            mat-menu-item
            data-testid="menu-item-month"
            class="mat-button"
            [class.mat-primary]="calendarViewMode == calendarViewModeType.Month"
            [attr.aria-label]="'MODULE_INSTANCE_CALENDAR.VIEW_MODE.Month' | translate"
            (click)="calendarViewMode = calendarViewModeType.Month">
            {{ "MODULE_INSTANCE_CALENDAR.VIEW_MODE.Month" | translate }}
        </button>
        <button
            mat-menu-item
            data-testid="menu-item-year"
            class="mat-button"
            [class.mat-primary]="calendarViewMode == calendarViewModeType.Year"
            [attr.aria-label]="'MODULE_INSTANCE_CALENDAR.VIEW_MODE.Year' | translate"
            (click)="calendarViewMode = calendarViewModeType.Year">
            {{ "MODULE_INSTANCE_CALENDAR.VIEW_MODE.Year" | translate }}
        </button>
        <mat-divider></mat-divider>
        <button
            mat-menu-item
            data-testid="menu-item-show-weekends"
            [attr.aria-label]="'MODULE_INSTANCE_CALENDAR.OPTION_SHOW_WEEKENDS' | translate"
            [disabled]="calendarViewMode != calendarViewModeType.Month"
            (click)="showWeekends = !showWeekends">
            <mat-icon data-testid="menu-item-show-weekends-enabled">
                {{ showWeekends ? "check_circle_outline" : "radio_button_unchecked" }}
            </mat-icon>
            <span>{{ "MODULE_INSTANCE_CALENDAR.OPTION_SHOW_WEEKENDS" | translate }}</span>
        </button>
        <button
            mat-menu-item
            data-testid="menu-item-show-week-number"
            [attr.aria-label]="'MODULE_INSTANCE_CALENDAR.OPTION_SHOW_WEEK_NUMBER' | translate"
            [disabled]="calendarViewMode != calendarViewModeType.Month"
            (click)="showWeekNumber = !showWeekNumber">
            <mat-icon data-testid="menu-item-show-week-number-enabled">
                {{ showWeekNumber ? "check_circle_outline" : "radio_button_unchecked" }}
            </mat-icon>
            <span>{{ "MODULE_INSTANCE_CALENDAR.OPTION_SHOW_WEEK_NUMBER" | translate }}</span>
        </button>
    </mat-menu>
</ng-template>
