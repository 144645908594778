import { FileItemModel } from '../../../../../models/module/fields/main';
import {
  ActionEventType,
  ActionType,
  CipoTableOptions,
  TableRow,
} from '../../../../../shared/components/data-list/table.interface';

export type DriveTableRow = TableRow & FileItemModel;

export enum DriveTableActionsEnum {
  VIEW = 1,
  RENAME = 2,
  OPEN_TAB = 3,
}

export type DriveTableAction = ActionType<DriveTableActionsEnum, DriveTableRow>;
export type DriveTableActionEvent = ActionEventType<DriveTableActionsEnum, DriveTableRow>;
export type DriveTableOptions = CipoTableOptions<DriveTableAction>;
