import { Component, effect, input, signal } from '@angular/core';
import { debounceTime } from 'rxjs';
import { FormControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { KeyValueType } from '../../../../models/common';
import { CipoFormModule } from '../../../modules/forms.module';
import { DEBOUNCE_TIMES } from '../../../consts';
import { CipoFieldConfig, CipoListControl } from '../common';
import { GenericObjectType } from '../../../../models/helpers';

@Component({
  selector: 'cipo-select',
  standalone: true,
  imports: [CipoFormModule, TranslateModule],
  templateUrl: './cipo-select.component.html',
})
export class CipoSelectComponent {
  fieldData = signal<CipoFieldConfig>({});
  allOptionsSelected = signal<boolean>(false);
  filteredOptions = signal<KeyValueType[]>([]);
  selectedOptionValues = signal<string>(null);
  optionsDict = signal<GenericObjectType>({});

  formControl = input<CipoListControl, CipoListControl>(null, {
    transform: control => {
      this.onFormControlChange(control);
      return control;
    },
    alias: 'control',
  });

  searchControl = new FormControl('');

  constructor() {
    this.searchControl.valueChanges.pipe(debounceTime(DEBOUNCE_TIMES.short)).subscribe(value => {
      const selectedOptions = this.formControl().value;
      this.filteredOptions.set(
        this.fieldData().options.filter(
          option => option.value.toLowerCase().includes(value.toLowerCase()) || selectedOptions.includes(option.key),
        ),
      );
    });

    effect(() => {
      this.formControl()?.valueChanges.subscribe(value => {
        this.setSelectedValues(value);
        this.allOptionsSelected.set(value?.length === this.fieldData().options.length);
      });
    });
  }

  onFormControlChange(control: CipoListControl) {
    this.fieldData.set(control.fieldData ?? {});
    this.filteredOptions.set(control.fieldData?.options ?? []);
    this.optionsDict.set(
      control.fieldData?.options.reduce((acc, option) => {
        acc[option.key] = option.value;
        return acc;
      }, {}),
    );
    this.setSelectedValues(control.value ?? '');
  }

  setSelectedValues(value: string[] | string) {
    if (this.fieldData().multiple) {
      this.selectedOptionValues.set((value as string[]).map(val => this.optionsDict()[val]).join(', '));
    } else {
      this.selectedOptionValues.set(value as string);
    }
  }

  toggleSelectAllOptions(checked: boolean) {
    this.allOptionsSelected.set(checked);
    this.formControl().setValue(checked ? this.fieldData().options.map(option => option.key) : []);
  }
}
