import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAccordion } from '@angular/material/expansion';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { take } from 'rxjs';

import { DataListCommandFilterType, DataListFilterFormGroup, FiltersToApply } from '../data-list-command.interface';
import { MaterialModule } from '../../../../modules/material.module';
import {
  FilterOperatorKey,
  FilterOperators,
  FilterDateOperators,
  FilterNumberOperators,
  FilterTextOperators,
  FilterLogic,
  FilterModel,
  FilterOperator,
} from 'src/app/models/module/grid/filterModel';
import { DataListFilterPipe } from 'src/app/shared/pipes/data-list-filters.pipe';

type RawFilter = {
  filter: DataListCommandFilterType;
  operator: FilterOperator;
  value: string | string[];
};

@Component({
  selector: 'app-data-list-filters',
  standalone: true,
  imports: [MaterialModule, FlexLayoutModule, CommonModule, OwlDateTimeModule, TranslateModule, DataListFilterPipe],
  templateUrl: './data-list-filters.component.html',
  styleUrl: './data-list-filters.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataListFiltersComponent {
  filters: DataListCommandFilterType[] = [];
  filterLogic: FilterLogic = FilterLogic.AND;
  form = this.fb.group<{ [key: string]: FormGroup<DataListFilterFormGroup> }>({});
  accordionClosed = true;
  RANGE_FILTER_KEYS: FilterOperatorKey[] = ['Between', 'Last2Weeks', 'Last30Days'];

  @ViewChild(MatAccordion) accordion: MatAccordion;

  @Input('filters')
  set _filters(filters: DataListCommandFilterType[]) {
    this.filters = filters.map(filter => {
      if (filter.operators?.length) {
        return filter;
      } else {
        const filterOperators =
          filter.type === 'date'
            ? FilterDateOperators
            : filter.type === 'number'
              ? FilterNumberOperators
              : FilterTextOperators;
        filter.operators = filterOperators;
        return filter;
      }
    });
    this.initForm(filters);
  }
  @Input('resetFilter')
  set _clearFilter(clear: boolean) {
    if (clear) {
      this.resetFilter();
    }
  }

  @Output() applyfilters = new EventEmitter<FiltersToApply>();
  @Output() closeFilters = new EventEmitter();

  constructor(private fb: FormBuilder) {}

  getFormattedDate(dates: string | string[]) {
    if (dates[0]) {
      return `${moment(dates[0]).format('ll')} - ${moment(dates[1]).format('ll')}`;
    }
    return moment(dates).format('ll');
  }

  initForm(filters: DataListCommandFilterType[]) {
    this.form = this.fb.group({});
    filters.forEach(filter => {
      this.form.addControl(
        filter.fieldId.toString(),
        this.fb.group({
          operator: filter.selectedOperator,
          value: [{ value: '', disabled: !filter.selectedOperator }],
        }),
      );
    });
  }

  selectOperator(filter: DataListCommandFilterType) {
    const filterFormGroup = this.form.controls[filter.fieldId].controls;
    filterFormGroup.value.enable();

    if (filterFormGroup.operator.value === 'Last30Days') {
      /* The following type assertion is needed to save the value inside formControl.
        This format is used for ranges, and it's formatted within getFormattedDate() method above.
    */
      filterFormGroup.value.setValue([
        moment().subtract(30, 'days').toISOString(),
        moment().toISOString(),
      ] as unknown as string);
    } else if (filterFormGroup.operator.value === 'Last2Weeks') {
      filterFormGroup.value.setValue([
        moment().subtract(14, 'days').toISOString(),
        moment().toISOString(),
      ] as unknown as string);
    } else {
      if (filter.getValues) {
        filter.getValues
          .pipe(take(1))
          .subscribe(v => (this.filters.find(({ fieldId }) => fieldId === filter.fieldId).values = v));
      }
    }
  }

  closeFilter() {
    this.closeFilters.emit();
  }

  resetFilter(controlName?: number | string) {
    if (controlName) {
      this.form.controls[controlName].reset();
      this.form.controls[controlName].controls.value.disable();
    } else {
      Object.values(this.form.controls).forEach(control => {
        control.reset();
        control.controls.value.disable();
      });
    }
  }

  toggleAll() {
    if (this.accordionClosed) {
      this.accordion.openAll();
    } else {
      this.accordion.closeAll();
    }

    this.accordionClosed = !this.accordionClosed;
  }

  toggleFilterLogic({ value }: MatButtonToggleChange) {
    this.filterLogic = value;
  }

  applyFilters() {
    const formData = this.form.value;
    const formKeys = Object.keys(this.form.controls).filter(key => {
      const val = formData[key].value;
      return typeof val === 'string' ? !!val : !!(val as unknown as string[])?.length;
    });

    if (formKeys.length === 0) {
      this.applyfilters.emit(null);
      return;
    }

    const rawFilters: RawFilter[] = formKeys.map(key => ({
      filter: this.filters.find(f => f.fieldId == key),
      operator: FilterOperators[formData[key].operator],
      value: formData[key].value,
    }));

    const filtersToApply: FiltersToApply = {
      logic: this.filterLogic,
      filters: this.mapRawFilters(rawFilters),
    };
    
    this.applyfilters.emit(filtersToApply);
  }

  mapRawFilters(rawFilters: RawFilter[]): FilterModel {
    if (rawFilters.length === 1 && typeof rawFilters[0].value === 'string') {
      const { filter, operator, value } = rawFilters[0];
      return {
        field: filter.fieldId.toString(),
        operator,
        value,
      };
    }

    return {
      filters: rawFilters.map(({ filter, operator, value }) => ({
        field: filter.fieldId.toString(),
        operator,
        value: typeof value === 'string' ? value : value.length === 1 ? value[0] : null,
        ...(typeof value !== 'string' &&
          value.length > 1 && {
            logic: operator === FilterOperator.NotEqual ? FilterLogic.AND : FilterLogic.OR,
            filters: value.map(val => {
              return {
                field: filter.fieldId.toString(),
                operator,
                value: val,
              } as FilterModel;
            }),
          }),
      })),
      logic: this.filterLogic,
    };
  }
}
