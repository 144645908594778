import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../shared/modules/material.module';
import { SharedComponentsModule } from '../../shared/modules/shared-components.module';
import { DataCardSettingsModule } from './data-card/data-card-settings.module';
import { DateService } from './weather-table/date.service';
import { WeatherTableComponent } from './weather-table/weather-table.component';
import { WeatherTableService } from './weather-table/weather-table.service';

@NgModule({
  declarations: [WeatherTableComponent],
  exports: [WeatherTableComponent],
  providers: [WeatherTableService, DateService],
  imports: [DataCardSettingsModule, SharedComponentsModule, MaterialModule, TranslateModule],
})
export class ModuleDefinitionModule {
  ngDoBootstrap() {
    // Required module bootstrap method
  }
}
